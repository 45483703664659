import React, { Component } from "react";
import styles from "./Sidebar.module.css";
import Scene from "./Scene";
import Project from "./Project";

export default function Sidebar({
  app,
  setState,
  state,
  selectScene,
  selectProjectView,
  selectItem,
  selectedItem,
  refresh,
}) {
  return (
    <div className={styles.Sidebar}>
      <div className={styles.Header}>
        <div
          className={`${styles.Project} ${
            state.sidebarView === 0 ? styles.Active : ""
          }`}
          onClick={() => {
            app.sceneView = false;
            setState({ ...state, sidebarView: 0 });
          }}
        >
          Project
        </div>
        <div
          className={`${styles.Scene} ${
            state.sidebarView === 1 ? styles.Active : ""
          }`}
          onClick={() => {
            setState({ ...state, sidebarView: 1 });
            app.sceneView = true;
          }}
        >
          Template
        </div>
      </div>
      {state.sidebarView === 0 && (
        <Project
          app={app}
          selectScene={selectScene}
          selectProjectView={selectProjectView}
        />
      )}
      {state.sidebarView === 1 && (
        <Scene
          app={app}
          scene={app.player.selectedScene}
          selectItem={selectItem}
          selectedItem={selectedItem}
          refresh={refresh}
        />
      )}
    </div>
  );
}
