import React, { useState } from "react";
import styled from "styled-components";
import { setProperty as SET_PROPERTY } from "../../Scripts/PropertyUtils";

const Input = styled.input`
  background-color: rgba(0, 0, 0, 0);
  border: none;
  height: 20px;
  color: #8a8c8e;
  outline: none;
  font-size: 15px;
  border: 1px solid #232531;
  padding-left: 0.3em;
  font-size: 0.9em;
  background-color: #12161d;
  border-radius: 3px;
`;
export default function ControlEditor({ scene }) {
  return (
    <div style={{ overflowY: "auto", maxHeight: "800px", maxWidth: "450px" }}>
      <div style={{ display: "flex" }}>
        <Input disabled style={{ width: "80px" }} value="#" />
        <Input disabled value="Node Name" />
        <Input disabled value="Control Name" />
        <Input disabled value="Node Value" />
      </div>
      {scene.scene.children.map((i) => {
        return <Control item={i} />;
      })}
    </div>
  );
}

function Control({ item }) {
  const [update, setUpdate] = useState(false);
  return (
    <>
      {item.control && item.control.id !== 0 && (
        <div style={{ display: "flex" }}>
          <Input
            style={{ width: "80px" }}
            value={item.control.id}
            onChange={(e) => {
              item.control.id = e.currentTarget.value;
              setUpdate(!update);
            }}
          />
          <Input
            value={item.name}
            onChange={(e) => {
              item.name = e.currentTarget.value;
              setUpdate(!update);
            }}
          />
          <Input
            value={item.control.name}
            onChange={(e) => {
              item.control.name = e.currentTarget.value;
              setUpdate(!update);
            }}
          />

          <Input
            value={item.text}
            onChange={(e) => {
              item.text = e.currentTarget.value;
              SET_PROPERTY(item, "text", item.text);
              setUpdate(!update);
            }}
          />
        </div>
      )}
      {item.children && item.children.map((i) => <Control item={i} />)}
    </>
  );
}
