import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  background-color: #1f252f;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
`;

const Title = styled.div`
  font-size: 1.1em;
  background-color: #282f3c;
  padding: 0.3em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #161a21;
`;

const Row = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
`;

const Input = styled.input`
  border: 1px solid #232531;
  padding: 0.3em;
  font-size: 14px;
  background-color: #12161d;
  border-radius: 3px;
  color: #fff;
  width: 100%;
`;

const VariablesTable = styled.div`
  margin-top: 10px;
`;
const Column = styled.div`
  display: flex;
  width: 50%;
`;

const Select = styled.select`
  background-color: #181d25;
  color: #fff;
  padding: 4px;
  border: none;
  margin: 4px;
  border-radius: 4px;
`;

const TWITTER = [
  { name: "Profile Image" },
  { name: "Username" },
  { name: "Full Name" },
  { name: "Followers" },
  { name: "Message" },
  { name: "Hashtags" },
  { name: "Platform" },
  { name: "Attachment_1" },
];

const TEMPLATES = [{ name: "NEVERNO_TWITTER", variables: TWITTER }];
export default function NeverNoData({ scene, app }) {
  const [server, setServer] = useState(scene?.neverno?.server || "");
  const [nodes, setNodes] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(
    TEMPLATES.find((g) => g.name === "NEVERNO_TWITTER")
  );

  useEffect(() => {
    if (scene) {
      setServer(scene?.neverno?.server || "");
    }
  }, [scene]);

  useEffect(() => {
    setNodes(
      getControls({ children: scene.scene.children, control_array: [] })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene]);

  function getControls({ children, control_array }) {
    let newChildren = children.filter(
      (c) => c.control && c.control.name !== ""
    );
    control_array = [...control_array, ...newChildren];
    let childs = children.filter((c) => c.children && c.children.length > 0);
    for (let i = 0; i < childs.length; i++) {
      control_array = getControls({
        children: childs[i].children,
        control_array,
      });
    }

    return control_array;
  }

  return (
    <div>
      <Group>
        <Title>Never.No</Title>
        <Row>
          <Input
            type="text"
            value={server}
            onChange={(e) => {
              scene.neverno = {
                ...scene.neverno,
                server: e.currentTarget.value,
              };
              setServer(e.currentTarget.value);
            }}
            placeholder="Never.No Server Address"
          />
        </Row>
        <VariablesTable>
          {nodes?.map((node) => {
            return (
              <Row>
                <Column>{node?.name}</Column>
                <Column>
                  <Select
                    onChange={(ev) => {
                      let item = scene.getItemByUUID(
                        node.uuid,
                        scene.scene.children
                      );

                      if (!item.control?.data?.variables) {
                        item.control.data = { variables: [] };
                      }

                      item.control.data.variables =
                        item.control.data.variables?.filter(
                          (v) => v.template !== selectedTemplate.name
                        );
                      item.control.data.variables.push({
                        template: selectedTemplate.name,
                        field: ev.currentTarget.value,
                      });
                      console.log(scene);
                    }}
                    value={
                      node?.control?.data?.variables?.find(
                        (v) => v.template === selectedTemplate.name
                      )?.field
                    }
                  >
                    <option value="">---</option>
                    {selectedTemplate?.variables?.map((variable, index) => {
                      return (
                        <option value={variable.name}>{variable.name}</option>
                      );
                    })}
                  </Select>
                </Column>
              </Row>
            );
          })}
        </VariablesTable>
      </Group>
    </div>
  );
}
