import React, { useEffect } from "react";
import styled from "styled-components";
import TriangleDown from "../../SVG/TriangleDown";
import TriangleRight from "../../SVG/TriangleRight";
import { useState } from "react";
import {
  setProperty as SET_PROPERTY,
  redrawRectangle,
} from "../../Scripts/PropertyUtils";
import DragInput from "../DragInput/DragInput";
const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  background-color: #1f252f;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
  padding: 5px;
`;

const GroupTitle = styled.div`
  cursor: pointer;
`;

const Open = styled.div`
  svg {
    width: 10px;
    float: left;
    margin-left: 10px;
    margin-top: 6px;
  }
`;

const Title = styled.div`
  font-size: 1.1em;
  background-color: #282f3c;
  padding: 0.3em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #161a21;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid white;
  margin-bottom: 5px;
`;
const Child = styled.div`
  display:flex;
  width; 100%;
  padding:5px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#db0741" : "")};
  margin-bottom: 1px;
  :hover {
    background-color:#db0741;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
`;

const Inputs = styled.div`
  display: flex;
  width: 25%;
  align-items: center;
`;

const Property = styled.input`
  border: 1px solid #232531 !important;
  padding-left: 0.3em !important;
  font-size: 0.9em !important;
  background-color: #12161d !important;
  border-radius: 3px !important;
  cursor: default !important;
`;

const PropertyTitle = styled.div`
  width: 30%;
`;

function SizeToFit({ item, scene }) {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(
    parseInt(item.size_to_fit_padding.height) || 0
  );
  const [width, setWidth] = useState(
    parseInt(item.size_to_fit_padding.width) || 0
  );

  function updateProperty(property, value) {
    // if (isNaN(value) === false) {
    // value = parseFloat(value);
    SET_PROPERTY(item, property, value);

    if (item.updateAnchor) {
      item.updateAnchor();
    }
  }

  return (
    <Group>
      <GroupTitle onClick={() => setOpen(!open)}>
        <Open>
          {open && <TriangleDown />}
          {!open && <TriangleRight />}
        </Open>
        <Title>Size to fit</Title>
      </GroupTitle>
      {open && (
        <>
          <List>
            {scene.children.map((child) => {
              return <RenderChild original={item} child={child} />;
            })}
          </List>

          <Row>
            <PropertyTitle>Padding</PropertyTitle>
            <Inputs>
              <label
              // draggable={true}
              // onDragStart={(event) =>
              //   this.drag(
              //     event,
              //     "size_to_fit_padding.height",
              //     item.size_to_fit_padding.height
              //   )
              // }
              >
                Height
              </label>
              <DragInput
                value={height}
                onChange={(value) => {
                  setHeight(parseInt(value, 0));
                  updateProperty(
                    "size_to_fit_padding.height",
                    parseInt(value, 0)
                  );
                }}
              />
            </Inputs>
            <Inputs>
              <label
              // draggable={true}
              // onDragStart={(event) =>
              //   this.drag(
              //     event,
              //     "size_to_fit_padding.width",
              //     item.size_to_fit_padding.width
              //   )
              // }
              >
                Width
              </label>
              <DragInput
                value={width}
                onChange={(value) => {
                  setWidth(parseInt(value, 0));
                  updateProperty(
                    "size_to_fit_padding.width",
                    parseInt(value, 0)
                  );
                }}
              />
            </Inputs>
          </Row>
        </>
      )}
    </Group>
  );
}

function RenderChild({ original, child }) {
  return (
    <>
      {original.uuid !== child.uuid && (
        <>
          <Child
            selected={
              original.size_to_fit?.findIndex((i) => i?.uuid === child?.uuid) >
              -1
            }
            onClick={() => {
              if (!original.size_to_fit) {
                original.size_to_fit = [];
              }
              let index = original.size_to_fit.findIndex(
                (i) => i?.uuid === child?.uuid
              );
              if (index === -1) {
                original.size_to_fit.push(child);
              } else {
                original.size_to_fit = original.size_to_fit.filter(
                  (i) => i?.uuid !== child?.uuid
                );
              }
            }}
          >
            {child?.name}
          </Child>
          {child?.children?.map((c) => {
            return <RenderChild child={c} original={original} />;
          })}
        </>
      )}
    </>
  );
}

export default SizeToFit;
