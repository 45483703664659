import * as Stats from "stats-js";
import * as THREE from "three";

const pc = window.pc;
const PIXI  = window.PIXI;


class PlayCanvasPlayer {
    constructor() {
        // this.app = app;
        // this.project = null;
        // this.stats = new Stats();
        // this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom



        const app = new PIXI.Application({
            view: window.canvas, 
            width: 1920, height: 1080, backgroundColor: 0x1099bb, resolution: window.devicePixelRatio || 1,
        });
        // document.body.appendChild(app.view);
        
        const container = new PIXI.Container();
        
         app.stage.addChild(container);
        
        const graphics = new PIXI.Graphics();

// Rectangle
graphics.beginFill(0xDE3249);
graphics.drawRect(50, 50, 100, 100);
graphics.endFill();

graphics.blendMode = PIXI.BLEND_MODES.NONE;
app.stage.addChild(graphics);

        // Create a new texture
        const texture = PIXI.Texture.from('http://localhost:6060/assets/images/EFL/Ipswich Town.png');
        
        // Create a 5x5 grid of bunnies
        for (let i = 0; i < 25; i++) {
            const bunny = new PIXI.Sprite(texture);
            bunny.anchor.set(0.5);
            bunny.x = (i % 5) * 40;
            bunny.y = Math.floor(i / 5) * 40;
            // bunny.blendMode = PIXI.BLEND_MODES.NONE;
            container.addChild(bunny);
        }
        
        // Move container to the center
        container.x = app.screen.width / 2;
         container.y = app.screen.height / 2;
        
        // Center bunny sprite in local container coordinates
         container.pivot.x = container.width / 2;
        //  container.pivot.y = container.height / 2;
        
        // Listen for animate update
        app.ticker.add((delta) => {
            // rotate the container!
            // use delta to create frame-independent transform
             container.rotation -= 0.01 * delta;
             window.gl.readPixels(0, 0, 1920, 1080, window.gl.RGBA, window.gl.UNSIGNED_BYTE, window.pixels)
          
               window.ndi.send({
                      video: window.pixels
                     });
            window.doc.swap();
        });
        


return





// create a PlayCanvas application
// window.canvas = document.createElement('canvas');
window.canvas.width = 1920;
 window.canvas.height = 1080;
var pcApp = new pc.Application(window.canvas, {graphicsDeviceOptions: {width: 960, height: 540, alpha: true} });
pcApp.autoRender = false;
this.pcApp = pcApp;
pcApp.start();
window.gl = pcApp.context.graphicsDevice.gl;


var camera = new pc.Entity();
camera.addComponent("camera", {
    clearColor: new pc.Color(0.8, 0.8, 0.8)
});

this.pcApp.root.addChild(camera);
camera.setPosition(0, 0, 7);

this.box = new pc.Entity();
this.box.addComponent("model", { type: "box" });
this.pcApp.root.addChild(this.box);
this.box.rotate(10, 15, 0);

var light = new pc.Entity();
light.addComponent('light');
this.pcApp.root.addChild(light);
light.rotate(45, 0, 0)

this.pcApp.scene.ambientLight = new pc.Color(0.2, 0.2, 0.2); 

this.boxMaterial = new pc.StandardMaterial();
this.boxMaterial.diffuse.set(0, 0.58, 0.86);
this.boxMaterial.update();
this.box.model.model.meshInstances[0].material = this.boxMaterial;

// // ensure canvas is resized when window changes size
// window.addEventListener('resize', function() {
//     app.resizeCanvas();
// });

// create camera entity
// var camera = new pc.Entity('camera');
// camera.addComponent('camera', {
//     clearColor: new pc.Color(1, 0, 0, 1),
//     farClip: 10000
// });

// camera.rotateLocal(0,0,0)

// // create directional light entity
// var light = new pc.Entity('light');
// light.addComponent('light');

// // // add to hierarchy
// // app.root.addChild(this.cube);
// pcApp.root.addChild(camera);
// pcApp.root.addChild(light);
// var Opacity = pc.createScript("opacity");

//         Opacity.prototype.update = function (dt) {
//             if (this.entity.alphaDirty) {
//             this.entity.children.forEach(child => {
//               if (child.element) {
//                 child.element.opacity = child.parent.alpha;
//               }
//                if (child.type === 'GROUP' && child.alpha) {
//                 child.alpha = child.parent.alpha;
//                 child.alphaDirty = true;
//               }
        
//             });
//             this.entity.alphaDirty = false;
//         }
//         };
// this.screen = new pc.Entity();
//         this.screen.addComponent("screen", {
//             referenceResolution: new pc.Vec2(1920, 1080),
//             scaleBlend: 0.5,
//             scaleMode: pc.SCALEMODE_BLEND,
//             screenSpace: true
//         });
     
//         this.pcApp.root.addChild(this.screen);
//         this.scene = new pc.Entity();
        
//         this.screen.addChild(this.scene)
this.sprite = new pc.Entity();
// this.sprite.setLocalScale(0, 0, 0)
this.pcApp.root.addChild(this.sprite)

// set up initial positions and orientations

// light.setEulerAngles(45, 0, 0);
this.pcApp.loader.load("http://localhost:6060/assets/images/EFL/Ipswich Town.png", "texture",  (err, texture) => {
    var material =   this.boxMaterial;
    
    material.diffuseMap = texture;
    
    material.blendType = pc.BLEND_NONE;
    // this.boxMaterial.diffuse.set(1, 0, 0);
    // this.boxMaterial.diffuseMapTint = new pc.Color(1,0,0);
    
    material.update();

    // this.sprite.addComponent("element", {
    //     anchor: [ 0.5, 0.5, 0.5, 0.5 ],
    //     height: texture.height,
    //     pivot: [ 0.5, 0.5 ],
    //     type: pc.ELEMENTTYPE_IMAGE,
    //     width: texture.width,
    //     texture: texture
    //  });

//     // use texture here
});
// const size = 64;
//         const elSize = 32;
//         window.cf = new pc.CanvasFont(this.pcApp, {
//             color: new pc.Color(1, 1, 1), // white
//             fontName: "EFLBold",
//             fontSize: size,
//             width: 256,
//             height: 256
//         });
//         window.cf.createTextures("ABCDEFGHIJKLMNOPQRXYZ1234567890abcdefghijklmnopqyrstuvwxyz");
//         var fontAsset = new pc.Asset("CanvasFont", "font", {});
//         this.pcApp.assets.add(fontAsset);

//         fontAsset.resource = window.cf;
//         fontAsset.loaded = true;
// window.fontAsset = fontAsset;

this.tick();
// register a global update event
// this.getScrubber();
// this.getFrameDisplay();
    }

    
  getScrubber() {
    this.scrubber = document.getElementById("Scrubber");
    if (!this.scrubber) {
      setTimeout(() => this.getScrubber(), 1000);
    }
  }

  getFrameDisplay() {
    this.frameDisplay = document.getElementById("Frame");
    if (!this.frameDisplay) {
      setTimeout(() => this.getFrameDisplay(), 1000);
    }
  }


    triggerLogic(){

    }

    tick() {
        if (!this.clock) {
            this.clock = new THREE.Clock();
          }
      
          let deltaTime = this.clock.getDelta();

          this.box.rotate(deltaTime*10, deltaTime*20, deltaTime*30);

          if (this.project !== null) {
   
            for (let i = 0; i < this.project.scenes.length; i++) {
              if (window.editor) {
                if (this.app.sceneView) {
                  if (this.project.scenes[i] !== this.selectedScene) {
                    // this.project.scenes[i].scene.visible = false;
                    this.project.scenes[i].scene.renderable = false;
                  }
                } else {
                  // this.project.scenes[i].scene.visible = true;
                  this.project.scenes[i].scene.renderable = true;
                }
              }
              
              this.project.scenes[i].render(deltaTime);
              if (window.editor) {
                if (this.selectedScene === this.project.scenes[i]) {
                  let frames = this.project.scenes[i].activeTimeline.frameNumber + 1;
      
                  if (this.scrubber) {
                    this.scrubber.style.left = frames * 13 - 14 + "px";
                  }
                  if (this.frameDisplay) {
                    this.frameDisplay.value = "Frame: " + frames;
                  }
              
                  if (frames % 10 === 0 ) {
                    // window.gl.clearColor(1, 1, 0, 1);
                    //  window.gl.clear(window.gl.COLOR_BUFFER_BIT);
                    //  debugger
                    
                  } else {
                    // window.gl.clearColor(1, 0, 1, 1);
                    // window.gl.clear(window.gl.COLOR_BUFFER_BIT);
                  }
                 
                }
              }
            }
        }

        //  this.cube.rotate(10 * deltaTime, 20 * deltaTime, 30 * deltaTime);
        // window.gl.clearColor(1, 0, 1, 1);
        // window.gl.clear(window.gl.COLOR_BUFFER_BIT);
           this.pcApp.render();
        //   window.gl.readPixels(0, 0, 1920, 1080, window.gl.RGBA, window.gl.UNSIGNED_BYTE, window.pixels)
          
        //   window.ndi.send({
        //          video: window.pixels
        //         });
        
      

        // window.gl.finish();
         window.doc.swap();
        requestAnimationFrame(() => this.tick())
    }
}

export default PlayCanvasPlayer;
