import React, { Component } from "react";
import propertiesStyle from "./Properties.module.css";
import styles from "./Layout.module.css";
import TriangleDown from "../../SVG/TriangleDown";
import TriangleRight from "../../SVG/TriangleRight";
import { setProperty as SET_PROPERTY } from "../../Scripts/PropertyUtils";
import DragInput from "../DragInput/DragInput";

export default class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorClicked: false,
      open: false,
      spacing: 0,
      spacingX: 0,
      spacingY: 0,
    };
  }

  updateProperty(property, value) {
    SET_PROPERTY(this.props.item, property, value);
    this.setState({ update: true });
  }

  colorClicked(e) {
    this.setState({
      colorClicked: !this.state.colorClicked,
    });
  }

  handleMouseDown(e, property, startValue) {
    this.property = property;
    this.mouseDown = true;
    var rect = document.querySelector("body").getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    this.startX = x;

    this.startValue = startValue;
    document.addEventListener("mousemove", this.handleMouseMove.bind(this));
    document.addEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  handleMouseMove(e) {
    if (this.mouseDown) {
      var rect = document.querySelector("body").getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      x = x - this.startX;
      let y = this.props.item.children[0].y;
      this.setState({
        spacing: parseInt(this.startValue) + x,
      });
      for (let i = 1; i < this.props.item.children.length; i++) {
        this.props.item.children[i].y = y + this.state.spacing;
        y = this.props.item.children[i].y;
      }
    }
  }

  handleMouseUp(e) {
    this.mouseDown = false;
    document.removeEventListener("mousemove", this.handleMouseMove.bind(this));
    document.removeEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  handleMouseDownX(e, property, startValue) {
    this.property = property;
    this.mouseDown = true;
    var rect = document.querySelector("body").getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    this.startX = x;

    this.startValue = startValue;
    document.addEventListener("mousemove", this.handleMouseMoveX.bind(this));
    document.addEventListener("mouseup", this.handleMouseUpX.bind(this));
  }

  handleMouseMoveX(e) {
    if (this.mouseDown) {
      var rect = document.querySelector("body").getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      x = x - this.startX;
      let y = parseInt(this.props.item.children[0].x);

      this.setState({
        spacingX: parseInt(this.startValue) + x,
      });
      for (let i = 1; i < this.props.item.children.length; i++) {
        this.props.item.children[i].x = y + parseInt(this.state.spacingX);
        y = parseInt(this.props.item.children[i].x);
      }
    }
  }

  handleMouseUpX(e) {
    this.mouseDown = false;
    document.removeEventListener("mousemove", this.handleMouseMoveX.bind(this));
    document.removeEventListener("mouseup", this.handleMouseUpX.bind(this));
  }

  handleMouseDownY(e, property, startValue) {
    this.property = property;
    this.mouseDown = true;
    var rect = document.querySelector("body").getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    this.startX = x;

    this.startValue = startValue;
    document.addEventListener("mousemove", this.handleMouseMoveY.bind(this));
    document.addEventListener("mouseup", this.handleMouseUpY.bind(this));
  }

  handleMouseMoveY(e) {
    if (this.mouseDown) {
      var rect = document.querySelector("body").getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      x = x - this.startX;
      let y = parseInt(this.props.item.children[0].y);

      this.setState({
        spacingY: parseInt(this.startValue) + x,
      });
      for (let i = 1; i < this.props.item.children.length; i++) {
        this.props.item.children[i].y = y + parseInt(this.state.spacingY);
        y = parseInt(this.props.item.children[i].y);
      }
    }
  }

  handleMouseUpY(e) {
    this.mouseDown = false;
    document.removeEventListener("mousemove", this.handleMouseMoveY.bind(this));
    document.removeEventListener("mouseup", this.handleMouseUpY.bind(this));
  }

  applyAutoFollow(item) {
    if (!this.props.item.autoFollowPadding) {
      this.props.item.autoFollowPadding = 50;
    }
    this.props.item.autoFollow = item;
  }

  render() {
    return (
      <div className={propertiesStyle.Group}>
        <div
          className={propertiesStyle.GroupTitle}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <div className={propertiesStyle.Open}>
            {this.state.open && <TriangleDown />}
            {!this.state.open && <TriangleRight />}
          </div>
          <div className={propertiesStyle.Title}>Layout</div>
        </div>
        {this.state.open && (
          <div className={styles.Group}>
            <div className={styles.Property}>
              <label className={styles.Y}>Spacing Y</label>
              <input
                value={this.state.spacingY}
                onMouseDown={(e) => {
                  this.handleMouseDownY(e, "spacing-y", this.state.spacingY);
                }}
              />
            </div>
            <div className={styles.Property}>
              <label className={styles.Y}>Spacing x</label>
              <input
                value={this.state.spacingX}
                onMouseDown={(e) => {
                  this.handleMouseDownX(e, "spacing-x", this.state.spacingX);
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
