import React, { Component } from "react";
import styles from "./Hardware.module.css";

export default class Hardware extends Component {
  render() {
    if (window.macadam !== undefined) {
      let hardware = window.macadam.getDeviceInfo();
    }

    return (
      <div>
        {window.macadam === undefined && <h1>No Hardware Detected</h1>}
        {window.macadam !== undefined && (
          <img src="Images/ultrastudio-hd-mini.png" />
        )}
        <div className={styles.TestBars}>
          <div className={styles.Bar1} />
          <div className={styles.Bar2} />
          <div className={styles.Bar3} />
          <div className={styles.Bar4} />
        </div>
      </div>
    );
  }
}
