import React, { Component } from "react";
import styles from "./styles.module.css";
import XMLDataSource from "../../Scripts/DataSources/xml";
import xmlToJson from "../../Scripts/DataSources/xmlToJson";
import CodeMirror from "react-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/cobalt.css";
import "codemirror/mode/javascript/javascript";
import SourceItem from "./SourceItem";
import Source from "./Source";

export default class DataView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDataSource: null
    };
  }

  newXMLDataSource() {
    let ds = new XMLDataSource({
      url: "http://localhost:6060/assets/images/PLTV/srml-8-2019-standings.xml"
    });
    this.props.project.dataSources.push(ds);
    ds.onData.then(data => {
      this.setState({
        selectedDataSource: ds,
        data: data
      });
    });
  }

  drag(e, property, value) {
    e.dataTransfer.setData(
      "source",
      "http://localhost:6060/assets/images/PLTV/srml-8-2019-standings.xml"
    );
    e.dataTransfer.setData("property", property);
  }

  updateName(value) {
    this.state.selectedDataSource.name = value;
    this.forceUpdate();
  }

  updateUrl(value) {
    this.state.selectedDataSource.url = value;
    this.forceUpdate();
  }

  render() {
    let defaultCode = `
function update() {
  
}`;
    return (
      <div className={styles.DataView}>
        <div className={styles.Sources}>
          {this.props.project.dataSources.map(ds => {
            return (
              <SourceItem
                selected={this.state.selectedDataSource === ds}
                item={ds}
                selectSource={item => {
                  this.setState({
                    selectedDataSource: item
                  });
                }}
              />
            );
          })}
          <div
            className={styles.SourceItem}
            onClick={() => {
              this.newXMLDataSource();
            }}
          >
            +
          </div>
        </div>
        {this.state.selectedDataSource && (
          <Source
            item={this.state.selectedDataSource}
            updateName={value => this.updateName(value)}
            updateUrl={value => this.updateUrl(value)}
          />
        )}
      </div>
    );
  }
}
