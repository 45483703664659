import React, { useState } from "react";

export default function TagEditor({ scene, app }) {
  const [update, setUpdate] = useState();
  return (
    <div style={{ overflowY: "auto", height: "800px" }}>
      <input
        value={scene.folder}
        onChange={(e) => {
          scene.folder = e.currentTarget.value;
          setUpdate(!update);
          app.setState(app.state);
        }}
      ></input>
    </div>
  );
}
