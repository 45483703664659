import React from "react";
import styles from "./styles.module.css";

export default function SourceItem(props) {
  return (
    <div
      className={`${styles.Item} ${props.selected ? styles.Active : ""}`}
      onClick={() => {
        props.selectSource(props.item);
      }}
    >
      {props.item.name}
    </div>
  );
}
