import React from "react";
import styled from "styled-components";

import TopBar from "../TopBar/TopBar";

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function PlayerManCity() {
  window.document.title = "HYPER Player";

  return (
    <Main>
      <TopBar />
      MAN CITY
    </Main>
  );
}
