import React, { Component } from "react";
import styles from "./Scrubber.module.css";
import Marker from "../../SVG/Marker";
export default class Srubber extends Component {
  constructor(props) {
    super(props);
  }

  async handleMouseClick(e) {
    if (this.mouseDown) {
      var rect = document
        .getElementById("AnimationHeader")
        .getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      var y = e.clientY - rect.top; //y position within the element.
      let frames = Math.floor(x / 13);
      // x = (frames / 25) * 1000;
      if (frames >= 0) {
        this.props.app.player.selectedScene.scene.visible = true;
        this.props.app.player.selectedScene.scene.renderable = true;
        await this.props.app.player.selectedScene.activeTimeline.seek(frames);
      }
    }
  }

  handleMouseUp(e) {
    this.mouseDown = false;
    document.removeEventListener("mouseup", this.handleMouseUp.bind(this));
    document.removeEventListener("mousemove", this.handleMouseClick.bind(this));
  }

  handleMouseDown(e) {
    this.mouseDown = true;
    document.addEventListener("mouseup", this.handleMouseUp.bind(this));
    document.addEventListener("mousemove", this.handleMouseClick.bind(this));
  }

  render() {
    return (
      <div className={styles.Scrubber} id="Scrubber">
        <div
          className={styles.Head}
          onClick={(e) => this.handleMouseClick(e)}
          onMouseDown={(e) => this.handleMouseDown(e)}
        >
          <Marker />
        </div>
        <div className={styles.Guide} />
      </div>
    );
  }
}
