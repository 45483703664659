import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Select = styled.select`
  background-color: #181d25;
  color: #fff;
  padding: 4px;
  border: none;
  margin: 4px;
  border-radius: 4px;
  max-width: 100px;
`;
const Main = styled.div`
  padding: 0.5em;
  width: 440px;
  height: calc(100vh - 85px);
  background-color: #181d25;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  background-color: #1f252f;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
`;

const Title = styled.div`
  font-size: 1.1em;
  background-color: #282f3c;
  padding: 0.3em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #161a21;
`;

const Row = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
`;

const VariablesTable = styled.div`
  margin-top: 10px;
`;
const Column = styled.div`
  display: flex;
  width: 25%;
`;
const Input = styled.input`
  border: 1px solid #232531;
  padding: 0.3em;
  font-size: 14px;
  background-color: #12161d;
  border-radius: 3px;
  color: #fff;
  width: 100%;
`;
const RUGBY_CLOCK = [
  { name: "Game Time" },
  { name: "Game Clock" },
  { name: "Shot Clock" },
];
const CLIMBING_CLOCK = [
  { name: "Type" },
  { name: "Boulder Time" },
  { name: "Left Time" },
  { name: "Left Reaction" },
  { name: "Left State" },
  { name: "Right Time" },
  { name: "Right Reaction" },
  { name: "Right State" },
];
const TEMPLATES = [
  { name: "Rugby Clock", variables: RUGBY_CLOCK },
  { name: "Climbing Clock", variables: CLIMBING_CLOCK },
];
export default function ClockData({ scene, app }) {
  const [update, setUpdate] = useState();
  const [server, setServer] = useState();
  const [nodes, setNodes] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();

  useEffect(() => {
    if (scene) {
      setServer(scene?.stadium_clock?.server || "");
      let t = TEMPLATES.find(
        (g) => g.name === (scene?.stadium_clock?.template ?? "Rugby Clock")
      );

      setSelectedTemplate(t);
    }
  }, [scene]);

  useEffect(() => {
    setNodes(
      getControls({ children: scene.scene.children, control_array: [] })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene]);

  function getControls({ children, control_array }) {
    let newChildren = children.filter(
      (c) => c.control && c.control.name !== ""
    );
    control_array = [...control_array, ...newChildren];
    let childs = children.filter((c) => c.children && c.children.length > 0);
    for (let i = 0; i < childs.length; i++) {
      control_array = getControls({
        children: childs[i].children,
        control_array,
      });
    }

    return control_array;
  }

  return (
    <Main>
      <Group>
        <Title>Stadium Clock</Title>
        <Row>
          <Input
            type="text"
            value={server}
            onChange={(e) => {
              scene.stadium_clock = {
                ...scene.stadium_clock,
                server: e.currentTarget.value,
              };
              setServer(e.currentTarget.value);
            }}
            placeholder="Clock Server Address"
          />
        </Row>
        <Select
          style={{ maxWidth: "200px" }}
          onChange={(event) => {
            if (!scene.clock) {
              scene.clock = {};
            }

            scene.stadium_clock = {
              ...scene.stadium_clock,
              template: event.currentTarget.value,
            };
            app.setState(app.state);

            setSelectedTemplate(
              TEMPLATES.find(
                (graphic) => graphic.name === event.currentTarget.value
              )
            );
            setUpdate(Date.now());
          }}
          value={selectedTemplate?.name}
        >
          {TEMPLATES.map((graphic, index) => {
            return <option value={graphic.name}>{graphic.name}</option>;
          })}
        </Select>

        <VariablesTable>
          {nodes?.map((node) => {
            return (
              <Row>
                <Column>{node?.control?.name}</Column>
                <Column>
                  <Select
                    onChange={(ev) => {
                      let item = scene.getItemByUUID(
                        node.uuid,
                        scene.scene.children
                      );

                      if (!item.control?.data) {
                        item.control.data = { variables: [] };
                      }
                      if (!item.control?.data?.clock?.variables) {
                        item.control.data.clock = { variables: [] };
                      }

                      item.control.data.clock.variables =
                        item.control.data.clock.variables?.filter(
                          (v) => v.template !== selectedTemplate.name
                        );
                      item.control.data.clock.variables.push({
                        template: selectedTemplate.name,
                        field: ev.currentTarget.value,
                      });
                      console.log(scene);
                    }}
                    value={
                      node?.control?.data?.clock?.variables?.find(
                        (v) => v.template === selectedTemplate.name
                      )?.field
                    }
                  >
                    <option value="">---</option>
                    {selectedTemplate?.variables?.map((variable, index) => {
                      return (
                        <option value={variable.name}>{variable.name}</option>
                      );
                    })}
                  </Select>
                </Column>
              </Row>
            );
          })}
        </VariablesTable>
      </Group>
    </Main>
  );
}
