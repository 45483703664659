import React, { Component } from "react";
import styles from "./Objects.module.css";
import Cog from "../../SVG/Cog";
import Cube from "../../SVG/Cube";
import Circle from "../../SVG/Circle";
import Movie from "../../SVG/Movie";
import LayerGroup from "../../SVG/LayerGroup";
import Image from "../../SVG/Image";
import { HiClock } from "react-icons/hi";
const PIXI = window.PIXI;
export default class Objects extends Component {
  render() {
    return (
      <div className={styles.Objects}>
        <div
          className={`${styles.Plane} ${styles.Item}`}
          onClick={() => {
            let item = this.props.player.selectedScene.addRectangle("Plane 1");

            this.props.added(item);
          }}
        />
        <div
          className={`${styles.Text} ${styles.Item}`}
          onClick={() => {
            let item = this.props.player.selectedScene.addText("Text");
            this.props.added(item);
          }}
        >
          A
        </div>
        <div
          className={`${styles.Circle} ${styles.Item}`}
          onClick={() => {
            let item = this.props.player.selectedScene.addCircle("Circle");
            this.props.added(item);
          }}
        >
          <Circle />
        </div>

        {/* <div
          className={`${styles.Cube} ${styles.Item}`}
          onClick={() => {
            let item = this.props.player.selectedScene.addText("Text");
            this.props.added(item);
          }}
        >
          <Cube />
        </div>
        <div
          className={`${styles.Circle} ${styles.Item}`}
          onClick={() => {
            let item = this.props.player.selectedScene.addCircle("Circle");
            this.props.added(item);
          }}
        >
          <Circle />
        </div>
        */}
        <div
          className={`${styles.Movie} ${styles.Item}`}
          onClick={() => {
            let item = this.props.player.selectedScene.addVideo("Video");
            this.props.added(item, (video) => {
              let videoTexture = new PIXI.resources.VideoResource(video, {
                autoLoad: true,
                autoPlay: false,
                loop: false,
              });

              let baseTexture = new PIXI.BaseTexture(videoTexture);
              var texture = new PIXI.Texture(baseTexture);
              item.src = video;
              item.video_src = video;
              item.texture = texture;
            });
          }}
        >
          <Movie />
        </div>
        <div
          className={`${styles.Movie} ${styles.Item}`}
          onClick={() => {
            let item = this.props.player.selectedScene.addImage("Image");
            this.props.added(item, (image) => {
              item._texture = PIXI.Texture.from(image);
              item.src = image;
            });
          }}
        >
          <Image />
        </div>

        <div
          className={`${styles.Movie} ${styles.Item}`}
          onClick={() => {
            let item = this.props.player.selectedScene.addGroup("Group");
            this.props.added(item);
          }}
        >
          <LayerGroup />
        </div>
        <div
          className={`${styles.Circle} ${styles.Item}`}
          onClick={() => {
            let item = this.props.player.selectedScene.addClock("Clock");
            this.props.added(item);
          }}
        >
          <HiClock style={{ width: "26px", height: "26px" }} />
        </div>
        <div
          className={`${styles.Circle} ${styles.Item}`}
          onClick={() => {
            let item = this.props.player.selectedScene.addInput("Clock");
            this.props.added(item);
          }}
        >
          INPUT
        </div>
        <div
          className={`${styles.Settings} ${styles.Item}`}
          onClick={() => this.props.selectConfigView()}
        >
          <Cog />
        </div>
      </div>
    );
  }
}
