import React, { Component } from "react";
import PenSquare from "../../SVG/PenSquare";
import styles from "./ViewBar.module.css";
export default class ViewBar extends Component {
  render() {
    return (
      <div className={styles.ViewBar}>
        <div
          className={styles.Edit}
          onClick={() => this.props.selectEditView()}
        >
          <PenSquare />
        </div>
      </div>
    );
  }
}
