import { getApolloContext, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import Popup from "../Dialog/Popup";
import TopBar from "../TopBar/TopBar";

const GET_ENGINES = gql`
  query GetEngines {
    enginesForUser {
      name
      _id
      key
    }
  }
`;

const PROJECTS = gql`
  query Projects {
    projectsForUser {
      name
      colour
      _id
      user {
        _id
      }
    }
  }
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  background-color: ${({ selected }) => (selected ? "red" : "")};
  justify-content: center;
`;
const Button = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  margin: 0.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ selected }) =>
    selected ? "rgb(210, 10, 62)" : "#2a2c39"};
`;

const EngineButton = styled.div`
  display: flex;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  margin: 0.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: ${({ red, green }) => {
    if (red) {
      return "#fff";
    } else if (green) {
      return "#000";
    }
    return "#fff";
  }};
  background-color: ${({ red, green }) => {
    if (red) {
      return "rgb(193, 25, 56)";
    } else if (green) {
      return "rgb(48, 193, 126)";
    }
    return "#2a2c39";
  }};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  background-color: #1f252f;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
  margin: 10px;
`;
const Title = styled.div`
  font-size: 1.1em;
  background-color: #282f3c;
  padding: 0.3em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #161a21;
`;
let liveWindow;
let previewWindow;
export default function Player() {
  const { data } = useQuery(PROJECTS);
  const { data: engines } = useQuery(GET_ENGINES);
  const [selectedProject, setSelectedProject] = useState();
  const [selectedEngine, setSelectedEngine] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [launched, setLaunched] = useState(false);
  const [startup, setStartup] = useState(true);
  window.document.title = "HYPER Player";

  useEffect(() => {
    if (data) {
      if (data?.projectsForUser?.length === 1) {
        setSelectedProject(data.projectsForUser[0]?._id);
      } else {
        setStartup(false);
      }
    }
    if (engines) {
      if (engines?.enginesForUser?.length === 1) {
        setSelectedEngine(engines.enginesForUser[0]?.key);
      } else {
        setStartup(false);
      }
    }
  }, [data, engines]);

  const launch = useCallback(() => {
    setLaunched(true);

    liveWindow = window.open(
      `http://localhost:3001/live/${selectedEngine}/${selectedProject}`
    );

    previewWindow = window.open(
      `http://localhost:3001/preview/${selectedEngine}/${selectedProject}`
    );
  }, [selectedEngine, selectedProject]);

  useEffect(() => {
    if (startup && selectedProject && selectedEngine) {
      launch();
    }
  }, [selectedProject, selectedEngine, launch, startup]);

  return (
    <Main>
      <TopBar />
      {!launched && (
        <>
          <Group>
            <Title>Select Project...</Title>
            <Row>
              {data?.projectsForUser?.map((project) => {
                return (
                  <Button
                    selected={selectedProject === project._id}
                    onClick={() => {
                      setSelectedProject(project._id);
                    }}
                  >
                    {project.name}
                  </Button>
                );
              })}
            </Row>
          </Group>
          <Group>
            <Title>Select Engine...</Title>
            <Row>
              {engines?.enginesForUser?.map((engine) => {
                return (
                  <Button
                    selected={selectedEngine === engine.key}
                    onClick={() => {
                      setSelectedEngine(engine.key);
                    }}
                  >
                    {engine.name}
                  </Button>
                );
              })}
            </Row>
          </Group>
          {selectedProject && selectedEngine && (
            <Row>
              <EngineButton
                green
                onClick={() => {
                  launch();
                }}
              >
                Launch Engine
              </EngineButton>
            </Row>
          )}
        </>
      )}
      {launched && (
        <Group>
          <Title>
            {
              engines?.enginesForUser?.find((e) => e.key === selectedEngine)
                ?.name
            }
          </Title>
          <Row>
            <EngineButton
              red
              onClick={() => {
                setShowConfirm(true);
              }}
            >
              Stop Engine
            </EngineButton>
          </Row>
        </Group>
      )}
      {showConfirm && (
        <Popup
          title="Stop engine"
          message="Are you sure you want to shut down the engine output?"
          onSave={() => {
            setShowConfirm(false);
            setLaunched(false);
            liveWindow.close();
            previewWindow.close();
          }}
          onCancel={() => {
            setShowConfirm(false);
          }}
        />
      )}
    </Main>
  );
}
