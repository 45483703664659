import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../Theme/Hyper";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";

const Main = styled.div`
  padding: 0.5em;
  width: 440px;
  height: calc(100vh - 85px);
  background-color: #181d25;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const Group = styled.div`
  display: flex;
  padding: 0.5em;
  flex-direction: column;
  margin-bottom: 2em;
  background-color: #1f252f;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
`;

const Title = styled.div`
  font-size: 1.1em;
  background-color: #282f3c;
  padding: 0.3em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #161a21;
`;

const Input = styled.input`
  background-color: rgb(24 29 37);
  border: none;
  height: 20 px;
  color: #d7d7d7;
  outline: none;
  font-size: 15px;
  margin: 5px;
  padding: 5px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  display: flex;
  margin-right: 10px;
  min-width: 120px;
`;

const UPDATE_PROJECT = gql`
  mutation updateProject($id: String!, $engine_version: String) {
    updateProject(id: $id, engine_version: $engine_version) {
      name
    }
  }
`;

export default function ProjectProperties({ project }) {
  const [engineVersion, setEngineVersion] = useState();
  // const [canvasWidth, setCanvasWidth] = useState();
  // const [canvasHeight, setCanvasHeight] = useState();
  const [updateProject] = useMutation(UPDATE_PROJECT);

  useEffect(() => {
    if (project) {
      setEngineVersion(project.engine_version);
    }
  }, [project]);

  return (
    <Main>
      <Group>
        <Row>
          <Label>Engine version</Label>
          <Input
            disabled
            value={engineVersion}
            onChange={(e) => {
              setEngineVersion(e.currentTarget.value);
              project.engine_version = e.currentTarget.value;
            }}
          />
        </Row>
        {/* <Row>
          <Label>Canvas Width</Label>
          <Input
            disabled
            value={canvasWidth}
            onChange={(e) => {
              setCanvasWidth(e.currentTarget.value);
              project.canvas_width = e.currentTarget.value;
            }}
          />
        </Row>
        <Row>
          <Label>Canvas Height</Label>
          <Input
            disabled
            value={canvasHeight}
            onChange={(e) => {
              setCanvasHeight(e.currentTarget.value);
              project.canvas_height = e.currentTarget.value;
            }}
          />
        </Row> */}
        <Row>
          <Button
            onClick={() => {
              console.log(project._id);

              updateProject({
                variables: {
                  id: project._id,
                  engine_version: engineVersion,
                  // canvas_height: parseInt(canvasHeight),
                  // canvasWidth: parseInt(canvasWidth),
                },
              });
            }}
          >
            Save
          </Button>
        </Row>
      </Group>
    </Main>
  );
}
