import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../../Theme/Hyper";
const Select = styled.select`
  background-color: #181d25;
  color: #fff;
  padding: 4px;
  border: none;
  margin: 4px;
  border-radius: 4px;
  max-width: 100px;
`;
const Main = styled.div`
  padding: 0.5em;
  width: 440px;
  height: calc(100vh - 85px);
  background-color: #181d25;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  background-color: #1f252f;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
`;

const Title = styled.div`
  font-size: 1.1em;
  background-color: #282f3c;
  padding: 0.3em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #161a21;
`;

const Row = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
`;

const VariablesTable = styled.div`
  margin-top: 10px;
`;
const Column = styled.div`
  display: flex;
  width: 25%;
`;
const Input = styled.input`
  border: 1px solid #232531;
  padding: 4px;
  font-size: 0.9em;
  background-color: #12161d;
  border-radius: 3px;
  color: #fff;
  max-width: 100px;
`;
const home_team = [
  { name: "Home Team Code" },
  { name: "Home Team Name" },
  { name: "Home Team Badge" },
  { name: "Home Team Video Badge" },
  { name: "Home Team Kit BG Colour", type: "colour" },
  { name: "Home Team Kit Text Colour", type: "colour" },
];
const away_team = [
  { name: "Away Team Code" },
  { name: "Away Team Name" },
  { name: "Away Team Badge" },
  { name: "Away Team Video Badge" },
  { name: "Away Team Kit BG Colour", type: "colour" },
  { name: "Away Team Kit Text Colour", type: "colour" },
];
const competition = [
  { name: "Competition" },
  { name: "Competition Badge" },
  { name: "Sport Type" },
];
const match = [
  { name: "Date" },
  { name: "Stadium" },
  { name: "Kick-Off" },
  { name: "Home Score" },
  { name: "Home Goals" },
  { name: "Home Points" },
  { name: "Away Score" },
  { name: "Away Goals" },
  { name: "Away Points" },
  { name: "Period" },
  { name: "Game Clock" },
  { name: "Additional Time" },
  { name: "Home Red Cards" },
  { name: "Away Red Cards" },
  { name: "Home Black Cards" },
  { name: "Away Black Cards" },
];
const team = [
  { name: "Team Type" },
  { name: "Team Code" },
  { name: "Team Name" },
  { name: "Team Short Name" },
  { name: "Team Badge" },
  { name: "Team Secondary Badge" },
  { name: "Team Skyscraper Image" },
  { name: "Team Video Badge" },
  { name: "Team Kit BG Colour", type: "colour" },
  { name: "Team Kit Text Colour", type: "colour" },
];
const Manager = [
  { name: "Manager First Name" },
  { name: "Manager Last Name" },
  { name: "Manager First Name Uppercase" },
  { name: "Manager Last Name Uppercase" },
  { name: "Manager Full Name" },
  { name: "Manager Nationality" },
  { name: "Manager Title" },
];
const Selector = (index) => {
  return [
    { name: "Selector " + index + " First Name" },
    { name: "Selector " + index + " Last Name" },
    { name: "Selector " + index + " First Name Uppercase" },
    { name: "Selector " + index + " Last Name Uppercase" },
    { name: "Selector " + index + " Full Name" },
    { name: "Selector " + index + " Nationality" },
    { name: "Selector " + index + " Title" },
  ];
};
const player = (index) => {
  if (index) {
    return [
      { name: "First Name " + index },
      { name: "Last Name " + index },
      { name: "First Name Uppercase " + index },
      { name: "Last Name Uppercase " + index },
      { name: "Short Name " + index },
      { name: "Short Name Uppercase " + index },
      { name: "Full Name " + index },
      { name: "Nationality " + index },
      { name: "Position " + index },
      { name: "Shirt Number " + index },
      { name: "Captain " + index },
      { name: "X/Y " + index },
      { name: "Subbed " + index },
      { name: "Card " + index },
      { name: "Goals " + index },
    ];
  } else {
    return [
      { name: "Title" },
      { name: "First Name" },
      { name: "Last Name" },
      { name: "First Name Uppercase" },
      { name: "Last Name Uppercase" },
      { name: "Short Name" },
      { name: "Short Name Uppercase" },
      { name: "Full Name" },
      { name: "Nationality" },
      { name: "Position" },
      { name: "Shirt Number" },
      { name: "Captain " },
      { name: "Subbed" },
      { name: "Card" },
      { name: "Goals" },
    ];
  }
};
const team_stats = (team) => {
  return [
    { name: team + " Possession" },
    { name: team + " Total Shots" },
    { name: team + " On Target" },
    { name: team + " Clear Cut Chances" },
    { name: team + " Successful Crosses" },
    { name: team + " Successful Dribbles" },
    { name: team + " Corners" },
    { name: team + " Offsides" },
    { name: team + " Yellow Cards" },
  ];
};

const penalty = (team, index) => {
  return { name: team + " Penalty " + index };
};
const commentators = (index) => {
  return [
    { name: "Comm First Name " + index },
    { name: "Comm Last Name " + index },
    { name: "Comm Full Name " + index },
  ];
};
const graphics = [
  {
    name: "Versus",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      ...commentators(1),
      ...commentators(2),
      ...commentators(3),
    ],
  },
  {
    name: "MatchID",
    variables: [...home_team, ...away_team, ...competition, ...match],
  },
  {
    name: "Score Update",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Last Goal Player First Name Uppercase" },
      { name: "Last Goal Player Last Name Uppercase" },
      { name: "Last Goal Team" },
      { name: "Last Goal Penalty" },
      { name: "Last Goal OG" },
      { name: "Last Goal Time" },
      { name: "ET Home Score" },
      { name: "ET Away Score" },
    ],
  },
  {
    name: "HT Score",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Half Text" },
    ],
  },
  {
    name: "FT Score",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Half Text" },
    ],
  },
  {
    name: "ET HT Score",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Half Text" },
      { name: "ET Home Score" },
      { name: "ET Away Score" },
    ],
  },
  {
    name: "ET FT Score",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Half Text" },
      { name: "ET Home Score" },
      { name: "ET Away Score" },
    ],
  },
  {
    name: "Penalties Score",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Half Text" },
    ],
  },
  {
    name: "Lineup",
    variables: [
      ...team,
      ...Manager,
      ...Selector(1),
      ...Selector(2),
      ...Selector(3),
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      ...player(9),
      ...player(10),
      ...player(11),
      ...player(12),
      ...player(13),
      ...player(14),
      ...player(15),
      ...player(16),
      ...player(17),
      ...player(18),
      ...player(19),
      ...player(20),
      ...player(21),
      ...player(22),
      ...player(23),
      ...player(25),
      ...player(26),
      ...player(27),
      ...player(28),
      ...player(29),
      ...player(30),
      { name: "Title" },
      { name: "Home Team Badge" },
      { name: "Away Team Badge" },
    ],
  },
  {
    name: "Lineup Subs",
    variables: [
      ...team,
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      ...player(9),
      ...player(10),
      ...player(11),
      { name: "Title" },
    ],
  },
  {
    name: "Clock",

    variables: [
      { name: "Show Acc Score" },
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
    ],
  },
  {
    name: "Sponsor",
    variables: [],
  },
  {
    name: "Name",
    variables: [
      ...player(),
      ...team,
      { name: "Subtitle" },
      { name: "Goal Time" },
    ],
  },
  {
    name: "Player Of The Match",
    variables: [
      { name: "First Name" },
      { name: "Last Name" },
      { name: "First Name Uppercase" },
      { name: "Last Name Uppercase" },
      { name: "Full Name" },
      { name: "Shirt Number" },
      ...team,
      { name: "Subtitle" },
    ],
  },
  {
    name: "Yellow Card",
    variables: [...player(), ...team],
  },
  {
    name: "Double Yellow Card",
    variables: [...player(), ...team],
  },
  {
    name: "Red Card",
    variables: [...player(), ...team],
  },
  {
    name: "Black Card",
    variables: [...player(), ...team],
  },
  {
    name: "Player Scored",
    variables: [
      ...player(),
      ...team,
      { name: "Goal Time" },
      { name: "Subtitle" },
    ],
  },
  {
    name: "Substitution",
    variables: [...player(1), ...player(2), ...team],
  },
  {
    name: "Two Substitution",
    variables: [
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...team,
    ],
  },
  {
    name: "Three Substitution",
    variables: [
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...team,
    ],
  },
  {
    name: "Penalties",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      ...new Array(15).fill(1).map((_, index) => penalty("Home", index + 1)),
      ...new Array(15).fill(1).map((_, index) => penalty("Away", index + 1)),
    ],
  },
  {
    name: "Formation",
    variables: [
      ...team,
      ...Manager,
      ...Selector(1),
      ...Selector(2),
      ...Selector(3),
      ...competition,
      ...match,
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      ...player(9),
      ...player(10),
      ...player(11),
      ...player(12),
      ...player(13),
      ...player(14),
      ...player(15),
      ...player(16),
      ...player(17),
      ...player(18),
      ...player(19),
      ...player(20),
      ...player(21),
      ...player(22),
      ...player(23),
      ...player(24),
      ...player(25),
      ...player(26),
      ...player(27),
      ...player(28),
      ...player(29),
      ...player(30),
    ],
  },
  {
    name: "Team Ticker",
    variables: [
      ...team,
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      { name: "Title" },
    ],
  },
  {
    name: "FF Stats",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      ...team_stats("Home"),
      ...team_stats("Away"),
    ],
  },
  {
    name: "Team Stat",
    variables: [
      ...home_team,
      ...away_team,
      { name: "Category" },
      { name: "Home Team Value" },
      { name: "Away Team Value" },
    ],
  },
];

export default function GAAData({ scene, app }) {
  const [update, setUpdate] = useState();
  return (
    <Main>
      <Group>
        <Title>Graphic Template</Title>
        {new Array(scene.gaa?.templates?.length || 0)
          .fill(1)
          .map((_, index) => {
            let template = scene.gaa.templates?.[index] || graphics[0];

            return (
              <>
                <Button
                  style={{ fontSize: "15px" }}
                  onClick={() => {
                    scene.gaa.templates = scene.gaa.templates.filter((t) => {
                      return t.name !== template.name;
                    });
                    setUpdate(Date.now());
                  }}
                >
                  Remove Template
                </Button>
                <TemplateEditor
                  template={template}
                  scene={scene}
                  app={app}
                  template_index={index}
                  setUpdate={setUpdate}
                  update={update}
                />
              </>
            );
          })}
      </Group>
      <Group
        onClick={() => {
          if (!scene.gaa?.templates?.length) {
            scene.gaa.templates = [];
          }
          scene.gaa.templates.push({ name: graphics[0].name });
          setUpdate(Date.now());
        }}
      >
        <Title>+ Add Template</Title>
      </Group>
    </Main>
  );
}

function TemplateEditor({
  template,
  scene,
  app,
  template_index,
  setUpdate,
  update,
}) {
  const [selectedTemplate, setSelectedTemplate] = useState(
    graphics.find((g) => g.name === template?.name)
  );
  const [nodes, setNodes] = useState([]);
  useEffect(() => {
    setNodes(
      getControls({ children: scene.scene.children, control_array: [] })
    );

    setSelectedTemplate(graphics.find((g) => g.name === template.name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene, template]);

  function getControls({ children, control_array }) {
    let newChildren = children.filter(
      (c) => c.control && c.control.name !== ""
    );
    control_array = [...control_array, ...newChildren];
    let childs = children.filter((c) => c.children && c.children.length > 0);
    for (let i = 0; i < childs.length; i++) {
      control_array = getControls({
        children: childs[i].children,
        control_array,
      });
    }

    return control_array;
  }

  return (
    <>
      <Select
        style={{ maxWidth: "200px" }}
        onChange={(event) => {
          if (!scene.gaa) {
            scene.gaa = {};
          }

          template = event.currentTarget.value;
          scene.gaa.templates[template_index] = {
            ...scene.gaa.templates[template_index],
            name: template,
          };
          app.setState(app.state);

          setSelectedTemplate(
            graphics.find((graphic) => graphic.name === template)
          );
          setUpdate(Date.now());
        }}
        value={selectedTemplate.name}
      >
        {graphics.map((graphic, index) => {
          return <option value={graphic.name}>{graphic.name}</option>;
        })}
      </Select>
      {selectedTemplate && (
        <VariablesTable>
          <Row>
            <Column>Node</Column>
            <Column>Data variable</Column>
            <Column>Default value</Column>
            <Column></Column>
          </Row>
          {nodes?.map((node) => {
            return (
              <Row>
                <Column>{node?.control?.name}</Column>
                <Column>
                  <Select
                    onChange={(ev) => {
                      let item = scene.getItemByUUID(
                        node.uuid,
                        scene.scene.children
                      );

                      if (!item.control?.data?.variables) {
                        item.control.data = { variables: [] };
                      }

                      if (!item.control.data.gaa) {
                        item.control.data.gaa = { variables: [] };
                      }

                      item.control.data.gaa.variables =
                        item.control.data?.gaa?.variables.filter(
                          (v) => v.template !== selectedTemplate.name
                        );
                      item.control.data.gaa.variables.push({
                        template: selectedTemplate.name,
                        field: ev.currentTarget.value,
                      });

                      setUpdate(Date.now());
                    }}
                    value={
                      node?.control?.data?.gaa?.variables?.find(
                        (v) => v.template === selectedTemplate.name
                      )?.field
                    }
                  >
                    <option value="">---</option>
                    {selectedTemplate?.variables?.map((variable, index) => {
                      return (
                        <option value={variable.name}>{variable.name}</option>
                      );
                    })}
                  </Select>
                </Column>
                <Column>
                  <Input
                    value={
                      node?.control?.data?.gaa?.variables?.find(
                        (v) => v.template === selectedTemplate.name
                      )?.default
                    }
                    onChange={(e) => {
                      let item = scene.getItemByUUID(
                        node.uuid,
                        scene.scene.children
                      );

                      if (!item.control?.data?.variables) {
                        item.control.data = { variables: [] };
                      }
                      if (!item.control.data.gaa) {
                        item.control.data.gaa = { variables: [] };
                      }
                      let index = item.control.data.gaa.variables.findIndex(
                        (v) => v.template === selectedTemplate.name
                      );
                      if (index === -1) {
                        item.control.data.gaa.variables.push({
                          template: selectedTemplate.name,
                          default: e.currentTarget.value,
                        });
                      } else {
                        item.control.data.gaa.variables[index].default =
                          e.currentTarget.value;
                      }

                      setUpdate(Date.now());
                    }}
                  />
                </Column>
                <Column>
                  <Input
                    type="checkbox"
                    checked={
                      node?.control?.data?.gaa?.variables?.find(
                        (v) => v.template === selectedTemplate.name
                      )?.hide
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      let item = scene.getItemByUUID(
                        node.uuid,
                        scene.scene.children
                      );

                      if (!item.control?.data?.variables) {
                        item.control.data = { variables: [] };
                      }
                      if (!item.control.data.gaa) {
                        item.control.data.gaa = { variables: [] };
                      }
                      let index = item.control.data.gaa.variables.findIndex(
                        (v) => v.template === selectedTemplate.name
                      );
                      if (index === -1) {
                        item.control.data.gaa.variables.push({
                          template: selectedTemplate.name,
                          hide: e.currentTarget.checked,
                        });
                      } else {
                        item.control.data.gaa.variables[index].hide =
                          e.currentTarget.checked;
                      }

                      setUpdate(Date.now());
                    }}
                  />
                </Column>
              </Row>
            );
          })}
        </VariablesTable>
      )}
    </>
  );
}
