import React, { Component } from "react";
import propertiesStyle from "./Properties.module.css";
import styles from "./Material.module.css";
import "./ColorPicker.css";
import { SketchPicker } from "react-color";
import TriangleDown from "../../SVG/TriangleDown";
import TriangleRight from "../../SVG/TriangleRight";
import {
  setProperty as SET_PROPERTY,
  redrawCircle,
  redrawRectangle,
} from "../../Scripts/PropertyUtils";
import Play from "../../SVG/Play";
import styled from "styled-components";

const Select = styled.select`
  background-color: #181d25;
  color: #fff;
  padding: 4px;
  border: none;
  margin: 4px;
  border-radius: 4px;
`;

const PIXI = window.PIXI;

export default class Material extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorClicked: false,
      open: false,
      videoIndex: 0,
    };
  }

  drop(e, item) {
    if (e.dataTransfer.getData("source")) {
      if (!item.dataBinds) {
        item.dataBinds = [];
      }
      item.dataBinds.push({
        source: e.dataTransfer.getData("source"),
        property: e.dataTransfer.getData("property"),
        itemProperty: "image",
      });
    }
  }

  allowDrop(e) {
    e.preventDefault();
  }

  colorClicked(e) {
    this.setState({
      colorClicked: !this.state.colorClicked,
    });
  }

  updateProperty(property, value) {
    value = value;

    SET_PROPERTY(this.props.item, property, value);
  }

  drag(e, property, value) {
    e.dataTransfer.setData("property", property);
    e.dataTransfer.setData("value", value);
    console.log(e);
  }

  handleMouseDown(e, property, startValue) {
    this.property = property;
    this.mouseDown = true;
    var rect = document.querySelector("body").getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    this.startX = x;

    this.startValue = startValue;
    document.addEventListener("mousemove", this.handleMouseMove.bind(this));
    document.addEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  handleMouseMove(e) {
    if (this.mouseDown) {
      var rect = document.querySelector("body").getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      x = x - this.startX;

      this.updateProperty(this.property, parseFloat(this.startValue) + x);
    }
  }

  handleMouseUp(e) {
    this.mouseDown = false;
    document.removeEventListener("mousemove", this.handleMouseMove.bind(this));
    document.removeEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  handleColorChange(color, event) {
    // this.props.item.graphicsData[0].fillColor = color.hex.replace("#", "");
    if (this.props.item.type === "TEXT" || this.props.item.type === "CLOCK") {
      if (!this.props.item?.gradient?.enabled) {
        this.props.item.style.fill = color.hex;
      }
      this.props.item.colour = color.hex;
    } else if (this.props.item.type === "RECTANGLE") {
      this.props.item.fillColor = this.string2hex(color.hex);
      redrawRectangle({ item: this.props.item });
    } else if (this.props.item.type === "CIRCLE") {
      this.props.item.fillColor = this.string2hex(color.hex);
      this.props.item.colour = color.hex;
      redrawCircle({ item: this.props.item });
    } else {
      this.props.item.tint = this.string2hex(color.hex);
    }

    // this.props.item.material.color = new THREE.Color(
    //   "rgba(" +
    //     color.rgb.r +
    //     "," +
    //     color.rgb.g +
    //     "," +
    //     color.rgb.b +
    //     "," +
    //     color.rgb.a +
    //     ")"
    // );
    this.forceUpdate();
  }

  string2hex(string) {
    if (typeof string === "string" && string[0] === "#") {
      string = string.substr(1);
    }

    return parseInt(string, 16);
  }

  imageCallback(image) {
    this.props.item._texture = PIXI.Texture.from(image);
    this.props.item.src = image;
    this.forceUpdate();
  }
  videoCallback(video) {
    let videoTexture = new PIXI.resources.VideoResource(video, {
      autoLoad: true,
      autoPlay: false,
      loop: this.props.item.loop,
    });

    let baseTexture = new PIXI.BaseTexture(videoTexture);
    var texture = new PIXI.Texture(baseTexture);
    this.props.item.src = video;
    this.props.item.video_src = video;
    this.props.item.texture = texture;

    this.forceUpdate();
  }

  sequenceCallback(video, project) {
    let textures = [];

    for (let i = 0; i < video.images.length; i++) {
      textures.push(
        PIXI.Texture.from(
          `${window.projectServerURL}assets/sequence/${project}/${video.name}/${video.images[i]}`
        )
      );
    }
    this.props.item.src = textures;
    this.props.item.textures = textures;

    this.forceUpdate();
  }

  render() {
    let color =
      this.props.item.tint != null && this.props.item.tint !== undefined
        ? PIXI.utils.hex2string(this.props.item.tint)
        : "";

    if (this.props.item.type === "TEXT" || this.props.item.type === "CLOCK") {
      color = this.props.item.colour;
    }
    if (this.props.item.type === "RECTANGLE") {
      color = PIXI.utils.hex2string(this.props.item.fillColor);
    } else if (this.props.item.type === "CIRCLE") {
      color = PIXI.utils.hex2string(this.props.item.fillColor);
    } else if (
      this.props.item.type === "GROUP" &&
      (this.props.item.filters.length == 0 ||
        !this.props.item.filters?.find((f) => f.name === "ALPHA"))
    ) {
      let new_filter = new PIXI.filters.AlphaFilter(this.props.item.alpha);
      new_filter.name = "ALPHA";
      this.props.item.filters.push(new_filter);
    }

    let image = "";
    let video = "";

    if (this.props.item.type === "IMAGE") {
      image =
        this.props.item._texture !== undefined
          ? this.props.item._texture.textureCacheIds.length > 0
            ? this.props.item._texture.textureCacheIds[0]
            : ""
          : "";
    }

    return (
      <div className={propertiesStyle.Group}>
        <div
          className={propertiesStyle.GroupTitle}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <div className={propertiesStyle.Open}>
            {this.state.open && <TriangleDown />}
            {!this.state.open && <TriangleRight />}
          </div>
          <div className={propertiesStyle.Title}>Material</div>
        </div>
        {this.state.open && (
          <div>
            <div className={styles.lists}>
              <div className={styles.Property}>
                <div
                  style={{ backgroundColor: color }}
                  className={styles.Color}
                  onClick={(e) => this.colorClicked(e)}
                  draggable={true}
                  onDragStart={(event) => {
                    this.drag(event, "colour", color);
                  }}
                />
                <div className="ColourPicker">
                  <div
                    className="Dropdown"
                    style={
                      this.state.colorClicked
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <SketchPicker
                      disableAlpha={true}
                      color={color}
                      onChange={(color, event) =>
                        this.handleColorChange(color, event)
                      }
                      styles={{
                        picker: {
                          backgroundColor: "#373a4a",
                          padding: "10px 10px 0px",
                          borderRadius: "4px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.Property}>
                <label className={styles.alpha}>Blend Mode</label>
                <Select
                  onChange={(event) => {
                    this.props.item.blendMode = event.target.value;
                  }}
                  value={this.props.item.blendMode}
                >
                  {new Array(26).fill(1).map((font, index) => {
                    return <option value={index}>{index}</option>;
                  })}
                </Select>
              </div>
              <div className={styles.Property}>
                <label
                  className={styles.alpha}
                  draggable={true}
                  onDragStart={(event) => {
                    let alpha =
                      this.props.item.type === "GROUP"
                        ? this.props.item.filters[0].alpha
                        : this.props.item.alpha;
                    this.drag(event, "alpha", alpha);
                  }}
                >
                  Alpha
                  <div>
                    {this.props.item.type === "GROUP"
                      ? this.props.item.filters[0].alpha
                      : this.props.item.alpha}
                  </div>
                </label>
                <input
                  className={propertiesStyle.Slider}
                  type="range"
                  name="points"
                  min="0"
                  max="1"
                  step="0.1"
                  value={
                    this.props.item.type === "GROUP"
                      ? this.props.item.filters[0].alpha
                      : this.props.item.alpha
                  }
                  onChange={(e) => {
                    this.updateProperty("alpha", e.currentTarget.value);
                  }}
                />
                {/* <input
                  value={this.props.item.alpha}
                  onMouseDown={e => {
                    this.handleMouseDown(e, "alpha", this.props.item.alpha);
                  }}
                  onChange={e => {
                    this.updateProperty("alpha", e.currentTarget.value);
                  }}
                /> */}
              </div>
              <div className={styles.Property}>
                {this.props.item.type === "IMAGE" &&
                  this.props?.item?.src
                    ?.split("/")
                    [this.props?.item?.src?.split("/").length - 1]?.replace(
                      /%2F/g,
                      "/"
                    )}
                {this.props.item.type === "IMAGE" && (
                  <div
                    onDrop={(event) => this.drop(event, this.props.item)}
                    onDragOver={(event) => this.allowDrop(event)}
                    className={styles.Image}
                    onClick={() =>
                      this.props.toggleImageView((image) =>
                        this.imageCallback(image)
                      )
                    }
                    style={{
                      backgroundImage: 'url("' + this.props?.item?.src + '")',
                    }}
                  />
                )}
                {this.props.item.type === "VIDEO" && (
                  <div className={styles.Video}>
                    {
                      this.props.item.src.split("/")[
                        this.props.item.src.split("/").length - 1
                      ]
                    }
                    <video
                      className={styles.Image}
                      src={`${this.props.item.video_src}`}
                      controls="controls"
                      onClick={() =>
                        this.props.toggleVideoView((video) =>
                          this.videoCallback(video)
                        )
                      }
                    />
                    {/* <input
                      className={propertiesStyle.Slider}
                      type="range"
                      name="points"
                      min="0"
                      max={this.props.item.textures.length - 1}
                      step="1"
                      onChange={(e) => {
                        this.setState({
                          videoIndex: e.currentTarget.value,
                        });
                      }}
                    /> */}
                    <label
                      className={styles.Play}
                      draggable={true}
                      onDragStart={(event) =>
                        this.drag(event, "TRIGGER", "play")
                      }
                    >
                      <Play />
                    </label>
                    <div
                      className={this.props.item.loop && styles.Selected}
                      onClick={() => {
                        this.props.item.texture.baseTexture.resource.source.loop = true;
                        this.props.item.loop = true;
                        this.forceUpdate();
                      }}
                    >
                      LOOP ON
                    </div>
                    <div
                      className={!this.props.item.loop && styles.Selected}
                      onClick={() => {
                        this.props.item.texture.baseTexture.resource.source.loop = false;
                        this.props.item.loop = false;
                        this.forceUpdate();
                      }}
                    >
                      LOOP OFF
                    </div>

                    <label
                      className={styles.alpha}
                      draggable={true}
                      onDragStart={(event) =>
                        this.drag(event, "TRIGGER", "reset")
                      }
                    >
                      RESET
                    </label>
                    {/* <label
                      className={styles.alpha}
                      draggable={true}
                      onDragStart={(event) =>
                        this.drag(event, "TRIGGER", "forwards")
                      }
                    >
                      FORWARDS
                    </label>
                    <label
                      className={styles.alpha}
                      draggable={true}
                      onDragStart={(event) =>
                        this.drag(event, "TRIGGER", "backwards")
                      }
                    >
                      BACKWARDS
                    </label> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
