import React, { Component } from "react";
import styles from "./Properties.module.css";
import BezierEditor from "bezier-easing-editor";
import styled from "styled-components";

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  background-color: rgb(24 29 37);
  border: none;
  height: 20 px;
  color: #d7d7d7;
  outline: none;
  font-size: 15px;
  margin: 5px;
  padding: 5px;
`;
const Group = styled.div`
  display: flex;
  padding: 0.5em;
  flex-direction: column;
  margin-bottom: 2em;
  background-color: #1f252f;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
`;

const Title = styled.div`
  font-size: 1.1em;
  background-color: #282f3c;
  padding: 0.3em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #161a21;
`;
const Label = styled.div`
  display: flex;
  margin-right: 10px;
  min-width: 120px;
`;
export default class KeyProperties extends Component {
  constructor(props) {
    super(props);
    this.curves = {
      linear: [0, 0, 1, 1],
      "ease-in": [0.42, 0, 1, 1],
      "ease-out": [0, 0, 0.58, 1],
      "ease-in-out": [0.42, 0, 0.58, 1],
    };
    this.state = {
      customCurve: [0, 0, 1, 1],
    };
  }

  componentDidMount() {
    this.createCurve("linear");
    this.createCurve("ease-in");
    this.createCurve("ease-out");
    this.createCurve("ease-in-out");
    let start = true;
    // this.interval = setInterval(() => {
    //   try {
    //     if (this.props.keyframe.type != "none") {
    //       document.getElementById("Example").style.cssText =
    //         "transition-timing-function: cubic-bezier(" +
    //         this.props.keyframe.curve +
    //         ");";
    //       if (start) {
    //         document.getElementById("Example").style.cssText +=
    //           "margin-left: 90%;";
    //       } else {
    //         document.getElementById("Example").style.cssText +=
    //           "margin-left: 5%;";
    //       }
    //       start = !start;
    //     }
    //   } catch (err) {
    //     console.error(err);
    //   }
    // }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  createCurve(name) {
    try {
      var bezier = new window.CubicBezier(this.curves[name]);
      window.document.getElementById(name).setAttribute("width", 100);
      window.document.getElementById(name).setAttribute("height", 100);
      let canvas = new window.BezierCanvas(
        window.document.getElementById(name),
        bezier,
        0.15
      );
      canvas.plot({
        bezierThickness: 0.032,
        handleColor: "rgba(255,255,255,.3)",
        handleThickness: 0.018,
      });
    } catch (e) {
      alert(e);
    }
  }

  render() {
    return (
      <div className={styles.Properties}>
        <Group>
          <Title>Keyframe</Title>

          <Row>
            <Label>Frame Number</Label>
            <Input
              value={this.props.keyframe.frame}
              onChange={(e) => {
                this.props.keyframe.frame = parseInt(e.currentTarget.value);
                this.forceUpdate();
              }}
            />
          </Row>
          <Row>
            <Label>Keyframe name</Label>
            <Input
              value={this.props.keyframe.name || ""}
              onChange={(e) => {
                this.props.keyframe.name = e.currentTarget.value;
                this.forceUpdate();
              }}
            />
          </Row>
          <Row>
            <Label>Keyframe value</Label>
            <Input
              value={this.props.keyframe.value}
              onChange={(e) => {
                this.props.keyframe.value = parseInt(e.currentTarget.value);
                this.forceUpdate();
              }}
            />
          </Row>
          <Row>
            <label className={styles.CheckboxContainer}>
              <Label>Inherit Value</Label>
              <input
                type="checkbox"
                checked={this.props.keyframe.inherit}
                onChange={(e) => {
                  this.props.keyframe.inherit = e.currentTarget.checked;
                  this.forceUpdate();
                }}
              />
              <span className={styles.Checkmark} />
            </label>
          </Row>
          <Label>Curve</Label>
          <Input
            value={this.props.keyframe.curve}
            onChange={(e) => {
              let val = e.currentTarget.value.split(",");

              this.setState({ customCurve: val });
              this.props.keyframe.curve = val;
              this.forceUpdate();
            }}
          />
        </Group>
        <Group>
          <Title>Animation curves</Title>
          <div className={styles.Curves}>
            {/* <div className={styles.ExampleContainer}>
            <div className={styles.Example} id="Example"></div>
          </div> */}
            <div className={styles.Curvy}>
              <div className={styles.CurveContainer}>
                <div
                  onClick={() => {
                    this.props.keyframe.type = "none";
                    this.forceUpdate();
                  }}
                  className={`${styles.Curve} ${styles.None} ${
                    this.props.keyframe.type === "none" ? styles.Selected : ""
                  }`}
                >
                  None
                </div>
              </div>
              {Object.keys(this.curves).map((curve) => {
                return (
                  <div className={styles.CurveContainer}>
                    <canvas
                      id={curve}
                      className={`${styles.Curve} ${
                        this.props.keyframe.type === curve
                          ? styles.Selected
                          : ""
                      }`}
                      onClick={() => {
                        this.props.keyframe.type = curve;
                        this.props.keyframe.curve = this.curves[curve];

                        this.forceUpdate();
                      }}
                    ></canvas>
                    <div className={styles.CurveOverlay}>{curve}</div>
                  </div>
                );
              })}
            </div>
            <div className={styles.Editor}>
              <BezierEditor
                value={this.props.keyframe.curve}
                onChange={(val) => {
                  this.setState({ customCurve: val });
                  this.props.keyframe.curve = val;
                }}
                width={200}
                height={200}
                background="rgba(0,0,0,0)"
                curveColor="#d4d4d4"
                gridColor="#0e1219"
                handleColor="#db0a41"
                textStyle={{
                  fill: "rgba(0,0,0,0",
                }}
                padding={[50, 50, 50, 50]}
              />
            </div>
          </div>
        </Group>
      </div>
    );
  }
}
