import xmlToJson from "./xmlToJson";
import uuid from "uuid/v4";
import { setProperty as SET_PROPERTY } from "../../Scripts/PropertyUtils";

export default function XMLDataSource(options) {
  let data = {};
  let _uuid = options.uuid || uuid();
  let _modifier = null;
  let _modifierCode = "function update(data) {return data}";
  let _url = options.url || "";
  let _name = options.name || "";

  updateModifier(options.modifierCode || _modifierCode);
  fetchData();
  // setInterval(() => {
  //   fetchData();
  // }, 10000);

  function updateItem(item) {
    if (item.dataBinds) {
      try {
        let bind = item.dataBinds.filter(i => {
          return i.source === _uuid;
        });
        bind.map(b => {
          let data = getProperty(b.property);
          SET_PROPERTY(item, b.itemProperty, data);
        });
      } catch (e) {
        console.error(e);
      }
    }
    if (item.children) {
      item.children.map(child => {
        updateItem(child);
      });
    }
  }

  function fetchData() {
    fetch(_url)
      .then(response => response.text())
      .then(xml => new DOMParser().parseFromString(xml, "text/xml"))
      .then(xml => {
        data = JSON.parse(xmlToJson(xml).replace("undefined", ""));
        if (_modifier) {
          try {
            data = _modifier.update(data);
            let scenes = window.hyperAPI.getAllScenes();
            scenes.map(scene => {
              updateItem(scene.scene);
            });
          } catch (err) {
            console.log(err);
          }
        }
      });
  }
  function updateModifier(code) {
    try {
      _modifierCode = code;
      _modifier = new Function(
        "hyperAPI",
        `
              let window = '';
                let console = {
                log: function(text) {
                    hyperAPI.log(text);
                }
            }
               ${code}
                return {
                  update: function(data) {
                   try {
                    return update(data)
                   }catch(err) {
                     
                   } 
                  }
                };
              `
      )(window.hyperAPI);
    } catch (err) {
      console.log(err);
    }
  }

  function getProperty(property) {
    let variable = property.split(".");
    let obj = data;
    for (let i = 0; i < variable.length - 1; i++) {
      obj = obj[variable[i]];
    }
    return obj[variable[variable.length - 1]];
  }

  return {
    get uuid() {
      return _uuid;
    },
    set url(val) {
      _url = val;
    },
    get url() {
      return _url;
    },
    set name(val) {
      _name = val;
    },
    get name() {
      return _name;
    },
    get modifierCode() {
      return _modifierCode;
    },
    get modifier() {
      return _modifier;
    },
    set modifier(val) {
      updateModifier(val);
    },
    getData: function() {
      return data;
    },
    getProperty: function(property) {
      return getProperty(property);
    },
    saveToJson() {
      return {
        name: _name,
        uuid: _uuid,
        url: _url,
        modifierCode: _modifierCode
      };
    },
    onData: new Promise(function(resolve, reject) {
      fetch(_url)
        .then(response => response.text())
        .then(xml => new DOMParser().parseFromString(xml, "text/xml"))
        .then(xml => {
          data = JSON.parse(xmlToJson(xml).replace("undefined", ""));
          if (_modifier) {
            data = _modifier.update(data);
          }
          resolve(data);
        });
    })
  };
}
