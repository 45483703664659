import React, { Component } from "react";
import Plus from "../../SVG/Plus";
import styles from "./Project.module.css";
import Bolt from "../../SVG/Bolt";
import LayerGroup from "../../SVG/LayerGroup";
import TriangleRight from "../../SVG/TriangleRight";
import TriangleDown from "../../SVG/TriangleDown";
import Dialog from "../Dialog/Dialog";
import Confirm from "../Dialog/Confirm";
import NewItem from "../Dialog/NewItem";
import Popup from "../Dialog/Popup";
import ClipLoader from "react-spinners/ClipLoader";
import styled from "styled-components";

const On = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #00c283;
  margin-right: 2px;
`;
let FOLDER_TOGGLE = [];

export default class Project extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editSceneName: null,
      showContext: false,
      mouseX: 0,
      mouseY: 0,
      folderToggle: FOLDER_TOGGLE,
      deleteSceneDialog: false,
      showNewScene: false,
    };
  }

  selectedScene(scene) {
    this.props.app.player.project.scenes
      .filter((s) => s._id === this.state.editSceneName?._id)
      .forEach((scene) => {
        scene.old_name = null;
      });
    this.setState({
      editSceneName: null,
    });
    this.props.selectScene(scene);

    this.triggerTransition(scene);
  }

  triggerTransition(scene) {
    this.props.app.player.triggerLogic(scene);
  }

  addNewScene(name) {
    let scene = this.props.app.player.project.addNewScene(name);

    this.setState({
      editSceneName: scene,
    });
  }

  updateSceneName(value) {
    this.props.app.player.project.scenes
      .filter((s) => s._id === this.state.editSceneName?._id)
      .forEach((scene) => {
        scene.rename(value);
      });
    // / this.state.editSceneName.name = value;
    this.forceUpdate();
  }

  onKeyDown(e) {
    console.log(e.keyCode);

    if (e.keyCode === 13) {
      this.props.app.player.project.scenes
        .filter((s) => s._id === this.state.editSceneName?._id)
        .forEach((scene) => {
          scene.old_name = null;
        });
      this.props.selectScene(this.state.editSceneName);
      this.setState({
        editSceneName: null,
      });
    }
  }

  handleContext(e, scene) {
    this.props.app.player.project.scenes
      .filter((s) => s._id === this.state.editSceneName?._id)
      .forEach((scene) => {
        scene.old_name = null;
      });
    if (this.props.app.player.selectedScene.name !== scene.name) {
      this.selectedScene(scene);
    }

    e.preventDefault();

    this.setState({
      editSceneName: null,
      showContext: true,
      mouseX: e.clientX,
      mouseY: e.clientY,
    });
    window.addEventListener("click", this.handleClick.bind(this));
  }

  handleClick(e) {
    this.setState({
      showContext: false,
    });
    window.removeEventListener("click", this.handleClick.bind(this));
  }

  handleContextNewAspect(aspect) {
    this.props.app.player.project.duplicateScene(
      this.props.app.player.selectedScene,
      aspect
    );
  }

  handleContextRename() {
    this.props.app.player.project.scenes
      .filter((s) => s._id === this.props.app.player.selectedScene?._id)
      .forEach((scene) => {
        scene.old_name = this.props.app.player.selectedScene.name;
      });

    this.setState({
      editSceneName: this.props.app.player.selectedScene,
    });
  }

  handleContextDelete() {
    this.props.app.player.project.deleteScene(
      this.props.app.player.selectedScene
    );
  }

  handleContextDuplicate() {
    this.props.app.player.project.duplicateScene(
      this.props.app.player.selectedScene
    );
  }

  toggleBoltLogic() {
    this.props.app.player.boltLogic = !this.props.app.player.boltLogic;
    this.forceUpdate();
  }

  async save() {
    this.setState({
      ...this.state,
      saving: true,
      deleteSceneDialog: false,
    });
    await this.props.app.player.project.save();
    this.setState({
      ...this.state,
      saving: false,
    });
  }

  render() {
    if (this.props.app.player.project === null) {
      return <div />;
    }
    let folders = [];
    this.props.app.player.project.scenes.map((scene) => {
      folders.push(scene.folder);
    });

    folders = [...new Set(folders)]?.sort((a, b) => {
      return a.localeCompare(b);
    });
    return (
      <>
        <div>
          <div className={styles.Header}>
            <div
              className={styles.Item}
              onClick={() => this.props.selectProjectView()}
            >
              Open
            </div>
            {/* <div className={styles.Item}>New</div> */}
            {/* <div className={`${styles.Save}`} onClick={() => this.save()}>
              {this.state.saving === true ? "Saving.." : "Save All"}
              <ClipLoader
                color={"#ffffff"}
                loading={this.state.saving === true}
                size={15}
              />
            </div> */}
          </div>
          <div className={styles.ProjectList}>
            <div className={styles.Header}>
              <div className={styles.Project}>
                <div
                  className={`${styles.Bolt} ${
                    this.props.app.player.boltLogic === true
                      ? styles.Active
                      : ""
                  }`}
                  onClick={() => this.toggleBoltLogic()}
                >
                  <Bolt />
                </div>
                <div>{this.props.app.player.project.name}</div>
              </div>
              <div
                className={styles.NewScene}
                onClick={() => {
                  this.setState({ ...this.state, showNewScene: true });
                }}
              >
                <Plus />
              </div>
            </div>
            <div style={{ overflowY: "auto", height: "calc(100vh - 150px)" }}>
              {folders.map((folder) => {
                return (
                  <div className={styles.Item}>
                    <div className={styles.FolderTitle}>
                      <div className={styles.Type}>
                        <LayerGroup />
                      </div>
                      {folder}
                      <div
                        class={styles.Expanded}
                        onClick={() => {
                          if (this.state.folderToggle.indexOf(folder) > -1) {
                            this.setState({
                              ...this.state,
                              folderToggle: this.state.folderToggle.filter(
                                (f) => f !== folder
                              ),
                            });
                            FOLDER_TOGGLE = this.state.folderToggle.filter(
                              (f) => f !== folder
                            );
                          } else {
                            FOLDER_TOGGLE = [
                              ...this.state.folderToggle,
                              folder,
                            ];
                            this.setState({
                              ...this.state,
                              folderToggle: [
                                ...this.state.folderToggle,
                                folder,
                              ],
                            });
                          }
                        }}
                      >
                        {this.state.folderToggle.indexOf(folder) == -1 && (
                          <TriangleRight />
                        )}
                        {this.state.folderToggle.indexOf(folder) > -1 && (
                          <TriangleDown />
                        )}
                      </div>
                    </div>
                    <div className={styles.ScenesList}>
                      {this.state.folderToggle.indexOf(folder) > -1 &&
                        this.props.app.player.project.scenes
                          // .filter(
                          //   (scene, index) =>
                          //     this.props.app.player.project.scenes.findIndex(
                          //       (s) => s.name === scene.name
                          //     ) === index
                          // )
                          .filter((scene) => scene.folder === folder)
                          .sort((a, b) => {
                            let a_name = a?.old_name || a?.name;
                            let b_name = b?.old_name || b?.name;
                            return a_name.localeCompare(b_name);
                          })
                          .map((scene, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className={`${styles.Scene} ${
                                    this.props.app.player.selectedScene
                                      ?.name === scene?.name &&
                                    this.state.editSceneName === null
                                      ? styles.Active
                                      : ""
                                  }`}
                                  onClick={() => {
                                    this.selectedScene(scene);
                                  }}
                                  onContextMenu={(e) => {
                                    this.handleContext(e, scene);
                                  }}
                                >
                                  {this.state.editSceneName === scene && (
                                    <div className={styles.EditName}>
                                      <input
                                        value={scene.name}
                                        autoFocus
                                        onChange={(e) =>
                                          this.updateSceneName(
                                            e.currentTarget.value
                                          )
                                        }
                                        onKeyDown={(e) => this.onKeyDown(e)}
                                        onFocus={(e) =>
                                          e.currentTarget.select()
                                        }
                                      />
                                    </div>
                                  )}
                                  {this.state.editSceneName !== scene && (
                                    <div className={styles.SceneName}>
                                      {scene.scene.renderable && <On />}
                                      {scene.name}
                                    </div>
                                  )}
                                </div>
                                {/* {this.props.app.player.selectedScene.name ===
                              scene.name &&
                              this.props.app.player.project.scenes.filter(
                                (s) => s.name === scene.name
                              ).length > 1 &&
                              this.props.app.player.project.scenes
                                .filter((s) => s.name === scene.name)
                                .map((item) => {
                                  return (
                                    <div
                                      className={`${styles.Aspect} ${
                                        this.props.app.player.selectedScene ===
                                        item
                                          ? styles.Active
                                          : ""
                                      }`}
                                      onClick={() => this.selectedScene(item)}
                                    >
                                      <div className={styles.SceneName}>
                                        {item.aspect}
                                      </div>
                                    </div>
                                  );
                                })} */}
                              </>
                            );
                          })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {this.state.showContext && (
            <div
              className={styles.Context}
              style={{
                left: this.state.mouseX + "px",
                top: this.state.mouseY + "px",
              }}
            >
              <div
                className={styles.ContextItem}
                onClick={() => this.handleContextRename()}
              >
                Rename
              </div>
              <div
                className={styles.ContextItem}
                onClick={() => this.handleContextDuplicate()}
              >
                Duplicate
              </div>
              {/* {this.props.app.player.project.scenes.filter(
              (s) =>
                s.name === this.props.app.player.selectedScene.name &&
                s.aspect == "1x1"
            ).length === 0 && (
              <div
                className={styles.ContextItem}
                onClick={() => this.handleContextNewAspect("1x1")}
              >
                Create 1:1 Aspect
              </div>
            )}
            {this.props.app.player.project.scenes.filter(
              (s) =>
                s.name === this.props.app.player.selectedScene.name &&
                s.aspect == "9x16"
            ).length == 0 && (
              <div
                className={styles.ContextItem}
                onClick={() => this.handleContextNewAspect("9x16")}
              >
                Create 9:16 Aspect
              </div>
            )} */}
              <div
                className={styles.ContextItem}
                onClick={() =>
                  this.setState({ ...this.state, deleteSceneDialog: true })
                }
              >
                Delete
              </div>
            </div>
          )}
          {this.state.deleteSceneDialog && (
            <Popup
              title="Delete Scene"
              message="Are you sure you want to delete this scene?"
              onSave={() => {
                this.handleContextDelete();
                this.save();
              }}
              onCancel={() => {
                this.setState({ ...this.state, deleteSceneDialog: false });
              }}
            />
          )}
        </div>
        {this.state.showNewScene && (
          <NewItem
            title={"Create Scene"}
            placeholder="Scene Name..."
            onSave={(value, oldValue) => {
              this.addNewScene(value);
              this.setState({ ...this.state, showNewScene: false });
            }}
            onCancel={() => {
              this.setState({ ...this.state, showNewScene: false });
            }}
          />
        )}
      </>
    );
  }
}
