import React, { Component } from "react";
import propertiesStyle from "./Properties.module.css";
import styles from "./DropShadow.module.css";
import textStyles from "./Text.module.css";
import "./ColorPicker.css";
import { SketchPicker } from "react-color";
import TriangleDown from "../../SVG/TriangleDown";
import TriangleRight from "../../SVG/TriangleRight";
import { setProperty as SET_PROPERTY } from "../../Scripts/PropertyUtils";
import Play from "../../SVG/Play";
import styled from "styled-components";
import { DropShadowFilter } from "@pixi/filter-drop-shadow";
import DragInput from "../DragInput/DragInput";

const Select = styled.select`
  background-color: #181d25;
  color: #fff;
  padding: 4px;
  border: none;
  margin: 4px;
  border-radius: 4px;
`;

const PIXI = window.PIXI;

export default class DropShadow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorClicked: false,
      open: false,
      videoIndex: 0,
    };
  }

  drop(e, item) {
    if (e.dataTransfer.getData("source")) {
      if (!item.dataBinds) {
        item.dataBinds = [];
      }
      item.dataBinds.push({
        source: e.dataTransfer.getData("source"),
        property: e.dataTransfer.getData("property"),
        itemProperty: "image",
      });
    }
  }

  allowDrop(e) {
    e.preventDefault();
  }

  colorClicked(e) {
    this.setState({
      colorClicked: !this.state.colorClicked,
    });
  }

  updateProperty(property, value) {
    value = value;
    SET_PROPERTY(this.props.item, property, value);

    this.forceUpdate();
  }

  drag(e, property, value) {
    e.dataTransfer.setData("property", property);
    e.dataTransfer.setData("value", value);
    console.log(e);
  }

  handleMouseDown(e, property, startValue) {
    this.property = property;
    this.mouseDown = true;
    var rect = document.querySelector("body").getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    this.startX = x;

    this.startValue = startValue;
    document.addEventListener("mousemove", this.handleMouseMove.bind(this));
    document.addEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  handleMouseMove(e) {
    if (this.mouseDown) {
      var rect = document.querySelector("body").getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      x = x - this.startX;

      this.updateProperty(this.property, parseFloat(this.startValue) + x);
    }
  }

  handleMouseUp(e) {
    this.mouseDown = false;
    document.removeEventListener("mousemove", this.handleMouseMove.bind(this));
    document.removeEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  handleColorChange(color, event) {
    this.props.item.drop_shadow.colour = color.hex;
    if (this.props.item.filters.find((f) => f.name === "DROPSHADOW")) {
      this.props.item.filters.find((f) => f.name === "DROPSHADOW").color =
        this.string2hex(color.hex);
    }

    this.forceUpdate();
  }

  string2hex(string) {
    if (typeof string === "string" && string[0] === "#") {
      string = string.substr(1);
    }

    return parseInt(string, 16);
  }

  render() {
    return (
      <div className={propertiesStyle.Group}>
        <div
          className={propertiesStyle.GroupTitle}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <div className={propertiesStyle.Open}>
            {this.state.open && <TriangleDown />}
            {!this.state.open && <TriangleRight />}
          </div>
          <div className={propertiesStyle.Title}>Drop Shadow</div>
        </div>
        {this.state.open && (
          <div className={styles.Group}>
            <div className={styles.lists}>
              <div className={textStyles.Property}>
                <label className={textStyles.CheckboxContainer}>
                  Enabled
                  <input
                    type="checkbox"
                    checked={
                      this.props.item?.drop_shadow?.enabled ? true : false
                    }
                    onChange={(e) => {
                      this.updateProperty(
                        "drop_shadow.enabled",
                        e.currentTarget.checked
                      );

                      if (
                        this.props.item.filters?.find(
                          (f) => f.name === "DROPSHADOW"
                        )
                      ) {
                        this.props.item.filters =
                          this.props.item.filters?.filter(
                            (f) => f.name !== "DROPSHADOW"
                          );
                      } else {
                        let options = this.props.item.drop_shadow;
                        let new_filter = new DropShadowFilter(options);
                        debugger;
                        new_filter.name = "DROPSHADOW";
                        this.props.item.filters.push(new_filter);
                      }
                    }}
                  />
                  <span className={textStyles.Checkmark} />
                </label>
              </div>
            </div>
            <div className={styles.lists}>
              <div className={textStyles.Property}>
                <label className={textStyles.CheckboxContainer}>
                  Show Shadow only
                  <input
                    type="checkbox"
                    checked={
                      this.props.item?.drop_shadow?.shadowOnly ? true : false
                    }
                    onChange={(e) => {
                      this.updateProperty(
                        "drop_shadow.shadowOnly",
                        e.currentTarget.checked
                      );
                    }}
                  />
                  <span className={textStyles.Checkmark} />
                </label>
              </div>
            </div>
            <div className={styles.lists}>
              <div className={styles.Property}>
                <div
                  style={{
                    backgroundColor:
                      this.props.item.drop_shadow.colour || "#000000",
                  }}
                  className={styles.Color}
                  onClick={(e) => this.colorClicked(e)}
                />
                <div className="ColourPicker">
                  <div
                    className="Dropdown"
                    style={
                      this.state.colorClicked
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <SketchPicker
                      disableAlpha={true}
                      color={this.props.item.drop_shadow.colour}
                      onChange={(color, event) =>
                        this.handleColorChange(color, event)
                      }
                      styles={{
                        picker: {
                          backgroundColor: "#373a4a",
                          padding: "10px 10px 0px",
                          borderRadius: "4px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.lists}>
              <div className={styles.Title}>Offset</div>
              <div className={styles.OffsetProperty}>
                <label
                  className={styles.X}
                  draggable={true}
                  onDragStart={(event) =>
                    this.drag(
                      event,
                      "drop_shadow.offset.x",
                      this.props.item.drop_shadow.offset.x
                    )
                  }
                >
                  X
                </label>
                <DragInput
                  value={parseInt(this.props.item.drop_shadow.offset.x)}
                  onChange={(value) => {
                    this.updateProperty("drop_shadow.offset.x", value);
                  }}
                />
              </div>
              <div className={styles.OffsetProperty}>
                <label
                  className={styles.Y}
                  draggable={true}
                  onDragStart={(event) =>
                    this.drag(
                      event,
                      "drop_shadow.offset.y",
                      this.props.item.drop_shadow.offset.y
                    )
                  }
                >
                  Y
                </label>
                <DragInput
                  value={parseInt(this.props.item.drop_shadow.offset.y)}
                  onChange={(value) => {
                    this.updateProperty("drop_shadow.offset.y", value);
                  }}
                />
              </div>
            </div>
            <div className={styles.lists}>
              <div className={styles.Property}>
                <label className={styles.alpha}>
                  Blur
                  <div>
                    {this.props.item.drop_shadow?.blur !== undefined
                      ? this.props.item.drop_shadow.blur
                      : 2}
                  </div>
                </label>
                <input
                  className={propertiesStyle.Slider}
                  type="range"
                  name="points"
                  min="0"
                  max="100"
                  step="1"
                  value={
                    this.props.item.drop_shadow?.blur !== undefined
                      ? this.props.item.drop_shadow.blur
                      : 2
                  }
                  onChange={(e) => {
                    debugger;
                    this.updateProperty(
                      "drop_shadow.blur",
                      e.currentTarget.value
                    );
                  }}
                />
              </div>
            </div>

            <div className={styles.lists}>
              <div className={styles.Property}>
                <label className={styles.alpha}>
                  Alpha
                  <div>
                    {this.props.item.drop_shadow?.alpha !== undefined
                      ? this.props.item.drop_shadow.alpha
                      : 2}
                  </div>
                </label>
                <input
                  className={propertiesStyle.Slider}
                  type="range"
                  name="points"
                  min="0"
                  max="1"
                  step=".1"
                  value={
                    this.props.item.drop_shadow?.alpha !== undefined
                      ? this.props.item.drop_shadow.alpha
                      : 0.5
                  }
                  onChange={(e) => {
                    this.updateProperty(
                      "drop_shadow.alpha",
                      e.currentTarget.value
                    );
                  }}
                />
              </div>
            </div>
            <div className={styles.lists}>
              <div className={styles.Property}>
                <label className={styles.alpha}>
                  Quality
                  <div>
                    {this.props.item.drop_shadow?.quality !== undefined
                      ? this.props.item.drop_shadow.quality
                      : 2}
                  </div>
                </label>
                <input
                  className={propertiesStyle.Slider}
                  type="range"
                  name="points"
                  min="0"
                  max="10"
                  step="1"
                  value={
                    this.props.item.drop_shadow?.quality !== undefined
                      ? this.props.item.drop_shadow.quality
                      : 2
                  }
                  onChange={(e) => {
                    this.updateProperty(
                      "drop_shadow.quality",
                      e.currentTarget.value
                    );
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
