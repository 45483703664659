import { cloneDeep } from "lodash";
import moment from "moment";
import { setProperty as SET_PROPERTY, redrawRectangle } from "./PropertyUtils";
const PIXI = window.PIXI;

export default function HyperAPI(project, player) {
  function getPlayer() {
    return player;
  }
  function isPreview() {
    return window.preview;
  }
  function convertTime(duration) {
    let time = moment.duration(duration);
    let minutes = time.minutes() + time.hours() * 60;
    let clock =
      minutes.toString().padStart(2, "0") +
      ":" +
      time.seconds().toString().padStart(2, "0");
    return clock;
  }
  function formatTime(time, format) {
    if (moment(time, [moment.ISO_8601, "HH:mm"]).isValid()) {
      return moment(time, [moment.ISO_8601, "HH:mm"]).format(format);
    } else {
      return time;
    }
  }
  function getAllScenes() {
    try {
      return project.scenes;
    } catch (err) {
      console.error(err);
    }
  }

  function getSceneByName(name) {
    try {
      return project.scenes.find((scene) => scene.name === name);
    } catch (err) {
      console.error(err);
    }
  }

  function getItemByName(scene, name) {
    try {
      return find(getSceneByName(scene).scene, name);
    } catch (err) {
      console.error(err);
    }
  }

  function find(item, name) {
    if (item.name === name) {
      return item;
    }

    if (item.children) {
      for (let i = 0; i < item.children.length; i++) {
        let c = find(item.children[i], name);
        if (c) {
          return c;
        }
      }
    }
  }

  // function setProperty(item, property, value) {
  //   try {
  //     let variable = property.split(".");
  //     let obj = item;
  //     for (let i = 0; i < variable.length - 1; i++) {
  //       obj = obj[variable[i]];
  //     }
  //     obj[variable[variable.length - 1]] = value;

  //     if (property === "text") {
  //       let style = obj.style;
  //       style.fontSize = style.original_size || style.fontSize;
  //       let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
  //       obj.width = parseInt(textMetrics.width);
  //       obj.height = parseInt(textMetrics.height);
  //       style.original_size = style.fontSize;
  //       while (obj.maxHeight > 0 && obj.height > obj.maxHeight) {
  //         style.fontSize -= 1;

  //         let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
  //         obj.height = parseInt(textMetrics.height);
  //       }
  //     }

  //     if (obj.maxWidth > 0 && obj.width > obj.maxWidth) {
  //       obj.width = obj.maxWidth;
  //     }
  //   } catch (e) {}
  // }

  function updateText(item) {
    let style = item.style;
    let textMetrics = PIXI.TextMetrics.measureText(item.text, style);
    item.width = parseInt(textMetrics.width);
    if (item.maxWidth > 0 && item.width > item.maxWidth) {
      item.width = item.maxWidth;
    }
  }

  function addKeyframe(property, value, item) {
    if (player.autoKeyframe) {
      let tl = player.selectedScene.activeTimeline;

      tl.addKeyframe({
        frame: tl.frameNumber + 1,
        property: property,
        value: value,
        item: item,
      });
    }
  }

  function getTimeline(scene, timeline) {
    let tl = project.scenes
      .find((s) => s.name === scene)
      .timelines.find((tl) => tl.name === timeline);
    return tl;
  }

  function log(text) {
    console.log(text);
    // let logger = window.document.getElementById("logger");
    // logger.value = text + "\n";
    // if (logger.rows > 5) {
    //   logger.value = logger.value
    //     .split("\n")
    //     .slice(1, logger.value.split("\n").length);
    // }
  }

  function getGlobal(name) {
    try {
      return window.hyperGlobals[name];
    } catch (err) {
      return null;
    }
  }

  function setGlobal(name, value) {
    try {
      window.hyperGlobals[name] = value;
    } catch (err) {
      return null;
    }
  }

  function string2hex(string) {
    if (typeof string === "string" && string[0] === "#") {
      string = string.substr(1);
    }

    return parseInt(string, 16);
  }

  function duplicateItem(item, group, scene, name) {
    try {
      let obj = cloneDeep(item);
      obj.name = name || obj.name;
      obj = processDuplicateChildren(obj, scene);
      scene.importObject(obj, group, true, true);
    } catch (err) {}
  }

  function processDuplicateChildren(obj, scene) {
    try {
      if (obj.type == "RECTANGLE") {
        try {
          obj.graphicsData = {
            ...obj.geometry.graphicsData[0].shape,
          };
          obj.fillColor = obj.geometry.graphicsData[0].fillStyle.color;

          // obj.transform =
        } catch (err) {
          debugger;
        }
      }
      if (obj.type == "GROUP") {
        for (let i = 0; i < obj.children.length; i++) {
          obj.children[i] = processDuplicateChildren(obj.children[i]);
        }
      }

      let item_animations = scene.timelines.filter((tl) => {
        let t = tl.tracks?.findIndex((track) => track.object.uuid === obj.uuid);
        return t > -1;
      });

      item_animations = item_animations.map((tl) => {
        return {
          ...tl,
          tracks: tl.tracks?.filter((track) => track.object.uuid === obj.uuid),
        };
      });
      if (item_animations?.length > 0) {
        obj.animations = cloneDeep(item_animations);
      }

      return obj;
    } catch (err) {}
  }

  function deleteItem(item, scene) {
    try {
      removeFromTimelines(item, scene);
      item.parent.removeChild(item);
      this.props.selectItem(null);
    } catch (err) {}
  }

  function removeFromTimelines(item, scene) {
    try {
      scene.timelines.map((timeline) => {
        timeline.removeTrack(item);
      });
      item.children.map((child) => {
        removeFromTimelines(child, scene);
      });
    } catch (err) {}
  }

  function offsetAnimation(item, scene, timeline, frames, keep_first_frame) {
    let tracks = scene.timelines
      ?.find((tl) => tl.name === timeline)
      .tracks.filter((o) => o.object?.uuid === item.uuid);

    tracks.forEach((tl) => {
      tl.animations.forEach((anim) => {
        let new_frames = [...anim.keyframes];

        anim.keyframes.forEach((kf) => {
          if (kf.frame === 1 && keep_first_frame) {
            new_frames.push({ ...kf, frame: kf.frame + frames });
          } else {
            kf.frame = kf.frame + frames;
          }
        });
        anim.keyframes = new_frames;
      });
    });
  }

  function _gradient({
    palette,
    width = 256,
    height = 256,
    type = "radial",
    angle = 90,
  }) {
    const c = document.createElement("canvas");

    c.width = width;
    c.height = height;
    const ctx = c.getContext("2d");
    let grd;
    if (type === "linear") {
      let points = linearGradient_a(width, height, angle);
      grd = ctx.createLinearGradient(
        points.tx,
        points.ty,
        points.bx,
        points.by
      );
      ctx.beginPath();

      for (let i = 0; i < palette.length; i++) {
        let p = palette[i];
        grd.addColorStop(parseFloat(p.offset), p.color);
      }
    } else if (type === "radial") {
      grd = ctx.createRadialGradient(
        width / 2,
        height / 2,
        0,
        width / 2,
        height / 2,
        width / 2
      );
      for (let i = 0; i < palette.length; i++) {
        let p = palette[i];
        grd.addColorStop(parseFloat(p.offset), p.color);
      }
    }

    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, width, height);
    return PIXI.Texture.from(c);
  }

  //Calculate Linear Gradient Angle and Cut Points
  function linearGradient_a(w, h, deg) {
    var caseAngle1 = Math.round((Math.atan(w / h) * 180) / Math.PI), //rundung
      caseAngle2 = Math.round(180 - caseAngle1),
      caseAngle3 = Math.round(180 + caseAngle1),
      caseAngle4 = Math.round(360 - caseAngle1);

    let tx;
    let wh;
    var bx = (tx = wh = w / 2);
    let hh = h / 2;
    let ty = h;
    let by = 0;
    let angInRad = (deg * Math.PI) / 180;
    let count1;

    if (deg == caseAngle1) {
      tx = 0;
      bx = w;
    } else if (deg == caseAngle2) {
      tx = 0;
      ty = 0;
      bx = w;
      by = h;
    } else if (deg == caseAngle3) {
      tx = w;
      ty = 0;
      bx = 0;
      by = h;
    } else if (deg == caseAngle4) {
      tx = w;
      ty = h;
      bx = 0;
      by = 0;
    } else {
      var mtan = Math.tan(angInRad);

      if (0 < deg && deg < caseAngle1) {
        count1 = (mtan * h) / 2;
        tx = wh - count1;
        bx = wh + count1;
      } else if (caseAngle1 < deg && deg < caseAngle2) {
        count1 = wh / mtan;
        tx = 0;
        ty = hh + count1;
        bx = w;
        by = hh - count1;
      } else if (caseAngle2 < deg && deg < caseAngle3) {
        count1 = (mtan * h) / 2;
        tx = wh + count1;
        ty = 0;
        bx = wh - count1;
        by = h;
      } else if (caseAngle3 < deg && deg < caseAngle4) {
        count1 = wh / mtan;
        tx = w;
        ty = hh - count1;
        bx = 0;
        by = hh + count1;
      } else if (caseAngle4 < deg && deg < 361) {
        count1 = (mtan * h) / 2;
        tx = wh - count1;
        ty = h;
        bx = wh + count1;
        by = 0;
      }
    }
    return { tx: tx, ty: ty, bx: bx, by: by };
  }

  function getUrlParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  return {
    getSceneByName: getSceneByName,
    getItemByName: getItemByName,
    setProperty: SET_PROPERTY,
    log: log,
    addKeyframe: addKeyframe,
    getAllScenes: getAllScenes,
    getTimeline: getTimeline,
    getGlobal: getGlobal,
    setGlobal: setGlobal,
    string2hex: string2hex,
    redrawRectangle: redrawRectangle,
    getPlayer: getPlayer,
    updateText: updateText,
    formatTime: formatTime,
    convertTime: convertTime,
    duplicateItem: duplicateItem,
    deleteItem: deleteItem,
    offsetAnimation,
    moment,
    getUrlParameterByName,
    isPreview,
  };
}
