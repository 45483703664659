import React, { Component } from "react";
import propertiesStyle from "./Properties.module.css";
import textStyles from "./Text.module.css";
import TriangleDown from "../../SVG/TriangleDown";
import TriangleRight from "../../SVG/TriangleRight";
import { setProperty as SET_PROPERTY } from "../../Scripts/PropertyUtils";
import styled from "styled-components";
import { SketchPicker } from "react-color";
import DragInput from "../DragInput/DragInput";

const Select = styled.select`
  background-color: #181d25;
  color: #fff;
  padding: 4px;
  border: none;
  margin: 4px;
  border-radius: 4px;
`;

export default class Texttroke extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorClicked: false,
      open: false,
      font: null,
      showContext: false,
    };
  }

  handleColorChange(color, event) {
    this.props.item.style.stroke = color.hex;

    this.forceUpdate();
  }

  string2hex(string) {
    if (typeof string === "string" && string[0] === "#") {
      string = string.substr(1);
    }

    return parseInt(string, 16);
  }

  colorClicked(e) {
    this.setState({
      colorClicked: !this.state.colorClicked,
    });
  }

  updateProperty(property, value) {
    SET_PROPERTY(this.props.item, property, value);

    this.setState({ update: true });
  }

  drag(e, property, value) {
    e.dataTransfer.setData("property", property);
    e.dataTransfer.setData("value", value);
  }
  handleMouseDown(e, property, startValue) {
    this.property = property;
    this.mouseDown = true;
    var rect = document.querySelector("body").getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    this.startX = x;

    this.startValue = startValue;
    document.addEventListener("mousemove", this.handleMouseMove.bind(this));
    document.addEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  handleMouseMove(e) {
    if (this.mouseDown) {
      var rect = document.querySelector("body").getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      x = x - this.startX;
      let value = this.startValue + x;

      if (this.property === "style.fontSize") {
        debugger;
        value = Math.max(value, 1);
      }
      this.updateProperty(this.property, value);

      this.updateProperty("text", this.props.item.text);
    }
  }

  handleMouseUp(e) {
    this.mouseDown = false;
    document.removeEventListener("mousemove", this.handleMouseMove.bind(this));
    document.removeEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  render() {
    let color = this.props.item.style ? this.props.item.style.stroke : "";

    return (
      <div className={propertiesStyle.Group}>
        <div
          className={propertiesStyle.GroupTitle}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <div className={propertiesStyle.Open}>
            {this.state.open && <TriangleDown />}
            {!this.state.open && <TriangleRight />}
          </div>
          <div className={propertiesStyle.Title}>Text Stroke</div>
        </div>
        {this.state.open && (
          <div className={textStyles.Group}>
            <div className={textStyles.lists}>
              <div className={textStyles.Property}>
                <div
                  style={{ backgroundColor: color }}
                  className={textStyles.Color}
                  onClick={(e) => this.colorClicked(e)}
                  draggable={true}
                  onDragStart={(event) => {
                    this.drag(event, "colour", color);
                  }}
                />
                <div className="ColourPicker">
                  <div
                    className="Dropdown"
                    style={
                      this.state.colorClicked
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <SketchPicker
                      disableAlpha={true}
                      color={color}
                      onChange={(color, event) =>
                        this.handleColorChange(color, event)
                      }
                      styles={{
                        picker: {
                          backgroundColor: "#373a4a",
                          padding: "10px 10px 0px",
                          borderRadius: "4px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={textStyles.Property}>
                <label
                  className={textStyles.Y}
                  draggable={true}
                  onDragStart={(event) =>
                    this.drag(
                      event,
                      "style.strokeThickness",
                      this.props.item.style.strokeThickness
                    )
                  }
                >
                  Size
                </label>
                <DragInput
                  type="number"
                  min="1"
                  value={this.props.item.style.strokeThickness}
                  onChange={(value) => {
                    this.updateProperty(
                      "style.strokeThickness",
                      parseInt(value)
                    );
                    this.updateProperty("text", this.props.item.text);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
