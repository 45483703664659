import React, { Component } from "react";
import propertiesStyle from "./Properties.module.css";
import styles from "./Mask.module.css";
import TriangleDown from "../../SVG/TriangleDown";
import TriangleRight from "../../SVG/TriangleRight";
import SpriteMaskFilter_Alpha from "../../Scripts/PIXI/SpriteMaskFilter_Alpha";
const PIXI = window.PIXI;

export default class Mask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  updateProperty(property, value) {
    this.props.item.text = value.toString();
    // this.props.item.geometry.update(value.toString());
    this.setState({ update: true });
  }

  applyMask(item) {
    if (
      item.mask === this.props.item ||
      item.filters?.find((f) => f.class_name == "SpriteMaskFilter_Alpha")
        ?.maskSprite === this.props.item
    ) {
      item.mask = null;
      let filter = item.filters?.find(
        (f) => f.class_name == "SpriteMaskFilter_Alpha"
      );
      if (filter) {
        filter.enabled = false;
      }
      item.filters = item.filters?.filter(
        (f) => f.class_name != "SpriteMaskFilter_Alpha"
      );
    } else {
      item.mask = this.props.item;
    }
  }

  renderChild(item) {
    let filter = item.filters?.find(
      (f) => f.class_name == "SpriteMaskFilter_Alpha"
    );

    return (
      <div>
        <div>
          <div
            onClick={() => this.applyMask(item)}
            className={`${styles.MaskItem} ${
              item.mask === this.props.item ||
              filter?.maskSprite === this.props.item
                ? styles.Active
                : ""
            }`}
          >
            {item.name}
          </div>
          {(item.mask?.type === "IMAGE" ||
            item.filters?.find((f) => f.class_name === "SpriteMaskFilter_Alpha")
              ?.maskSprite === this.props.item) && (
            <div className={styles.Property}>
              <div
                className={`${styles.Button} ${
                  item.mask == this.props.item ? styles.Active : ""
                }`}
                onClick={() => {
                  item.mask = null;
                  let filter = item.filters?.find(
                    (f) => f.class_name == "SpriteMaskFilter_Alpha"
                  );
                  if (filter) {
                    filter.enabled = false;
                  }
                  item.filters = item.filters?.filter(
                    (f) => f.class_name != "SpriteMaskFilter_Alpha"
                  );
                  item.mask = this.props.item;
                }}
              >
                Colour
              </div>
              <div
                className={`${styles.Button} ${
                  filter?.maskSprite === this.props.item ? styles.Active : ""
                }`}
                onClick={() => {
                  if (!item.filters) {
                    item.filters = [];
                  }
                  item.filters = item.filters?.filter(
                    (f) => f.class_name != "SpriteMaskFilter_Alpha"
                  );

                  let sprite_fill = new SpriteMaskFilter_Alpha(this.props.item);
                  item.mask = null;
                  item.alpha_mask = this.props.item;
                  item.filters.push(sprite_fill);
                }}
              >
                Alpha
              </div>
            </div>
          )}
        </div>

        {item.children.map((i) => {
          return this.renderChild(i);
        })}
      </div>
    );
  }

  render() {
    return (
      <div className={propertiesStyle.Group}>
        <div
          className={propertiesStyle.GroupTitle}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <div className={propertiesStyle.Open}>
            {this.state.open && <TriangleDown />}
            {!this.state.open && <TriangleRight />}
          </div>
          <div className={propertiesStyle.Title}>Masking</div>
        </div>
        {this.state.open && (
          <div className={styles.Group}>
            <div className={styles.lists}>
              <div className={styles.Property}>
                {this.props.scene.children.map((item) => {
                  return this.renderChild(item);
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
