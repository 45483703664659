import React, { Component } from "react";
import LayerGroup from "../../SVG/LayerGroup";
import TriangleDown from "../../SVG/TriangleDown";
import TriangleRight from "../../SVG/TriangleRight";
import styles from "./ExportViewer.module.css";
import styled from "styled-components";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";

const Button = styled.div`
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  margin: 10px;
  justify-content: center;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  background-color: #2a2c39;
  align-items: center;
  height: 31px;
`;
const Row = styled.div`
  display: flex;
`;
export default class ExportViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editSceneName: null,
      showContext: false,
      mouseX: 0,
      mouseY: 0,
      folderToggle: [],
      exports: [],
    };
  }

  export(exports) {
    fetch(
      `${window.projectServerURL}export/` + this.props.app.player.project.name,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(exports),
      }
    )
      .then((response) => response.text())
      .then((data) => {
        if (data) {
          let link = document.getElementById("download");
          link.href = `${window.projectServerURL}export/${this.props.app.player.project.name}/${data}`;
          link.click();
        }
      });
  }

  urlToPromise(url) {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  getAllImages({ path_id, prefix }) {
    console.log(`${window.projectServerURL}assets/images/${prefix}${path_id}`);
    return fetch(`${window.projectServerURL}assets/images/${prefix}${path_id}`)
      .then((response) => response.json())
      .then((data) => {
        let new_images = {};

        new_images["#"] = {
          isDir: true,
          name: "Project",
          id: "#",
          selectable: false,
          childrenIds: [],
          draggable: false,
          droppable: true,
          path: "",
        };
        // for (let i = 0; i < data.length; i++) {
        for (let i = 0; i < 1; i++) {
          let image = data[i];
          let id = image?.name?.replace(/\//g, "#");
          let path = image.name
            ?.substring(0, image.name.lastIndexOf("/") + 1)
            ?.split("/");
          path = path?.filter((p) => p !== "");
          new_images[id] = {
            id,
            path: image.name?.substring(0, image.name.lastIndexOf("/") + 1),
            name: image.name.replace(/^.*[\\\/]/, ""),
            url: `${
              window.projectServerURL
            }assets/images/${prefix}${path_id}/${image.name?.replace(
              /\//g,
              "%2F"
            )}`,
            thumbnailUrl: `${
              window.projectServerURL
            }assets/images/${prefix}thumbnail/${path_id}/${image.name?.replace(
              /\//g,
              "%2F"
            )}`,
            parentId:
              image.name
                ?.substring(0, image.name.lastIndexOf("/"))
                ?.replace(/\//g, "#") || "#",
            size: parseInt(image.size),
            modDate: image.last_updated,
            draggable: true,
            droppable: false,
          };

          let folder_path = "";
          let prev_id = "";
          if (
            path.length === 0 &&
            new_images["#"]?.childrenIds?.indexOf(id) === -1
          ) {
            new_images["#"].childrenIds.push(id);
          }

          for (let x = 0; x < path.length; x++) {
            let folder = path[x];
            folder_path += folder;
            let folder_id = ("#" + folder_path)?.replace(/\//g, "#");

            if (
              x === 0 &&
              new_images["#"]?.childrenIds?.indexOf(folder_id) === -1
            ) {
              new_images["#"].childrenIds.push(folder_id);
            } else if (
              new_images[prev_id]?.childrenIds?.indexOf(folder_id) === -1
            ) {
              new_images[prev_id].childrenIds.push(folder_id);
            }
            folder_path += "/";
            new_images[folder_id] = {
              isDir: true,
              name: folder,
              id: folder_id,
              path: folder_path,
              parentId: prev_id || "#",
              childrenIds: [...(new_images[folder_id]?.childrenIds || [])],
              selectable: false,
              draggable: false,
              droppable: true,
            };
            if (
              x === path.length - 1 &&
              new_images[folder_id]?.childrenIds?.indexOf(id) === -1
            ) {
              new_images[folder_id].childrenIds.push(id);
            }
            prev_id = folder_id;
          }
        }
        console.log(new_images);
        return new_images;
      });
  }

  getImages({ path_id, prefix, images_to_download }) {
    console.log(`${window.projectServerURL}assets/images/${prefix}${path_id}`);
    return fetch(`${window.projectServerURL}assets/images/${prefix}${path_id}`)
      .then((response) => response.json())
      .then((data) => {
        let new_images = {};

        new_images["#"] = {
          isDir: true,
          name: "Project",
          id: "#",
          selectable: false,
          childrenIds: [],
          draggable: false,
          droppable: true,
          path: "",
        };
        for (let i = 0; i < data.length; i++) {
          let image = data[i];
          if (
            images_to_download?.indexOf(
              `${
                window.projectServerURL
              }assets/images/${prefix}${path_id}/${image.name?.replace(
                /\//g,
                "%2F"
              )}`
            ) > -1
          ) {
            let id = image?.name?.replace(/\//g, "#");
            let path = image.name
              ?.substring(0, image.name.lastIndexOf("/") + 1)
              ?.split("/");
            path = path?.filter((p) => p !== "");

            new_images[id] = {
              id,
              path: image.name?.substring(0, image.name.lastIndexOf("/") + 1),
              name: image.name.replace(/^.*[\\\/]/, ""),
              url: `${
                window.projectServerURL
              }assets/images/${prefix}${path_id}/${image.name?.replace(
                /\//g,
                "%2F"
              )}`,
              thumbnailUrl: `${
                window.projectServerURL
              }assets/images/${prefix}thumbnail/${path_id}/${image.name?.replace(
                /\//g,
                "%2F"
              )}`,
              parentId:
                image.name
                  ?.substring(0, image.name.lastIndexOf("/"))
                  ?.replace(/\//g, "#") || "#",
              size: parseInt(image.size),
              modDate: image.last_updated,
              draggable: true,
              droppable: false,
            };

            let folder_path = "";
            let prev_id = "";
            if (
              path.length === 0 &&
              new_images["#"]?.childrenIds?.indexOf(id) === -1
            ) {
              new_images["#"].childrenIds.push(id);
            }

            for (let x = 0; x < path.length; x++) {
              let folder = path[x];
              folder_path += folder;
              let folder_id = ("#" + folder_path)?.replace(/\//g, "#");

              if (
                x === 0 &&
                new_images["#"]?.childrenIds?.indexOf(folder_id) === -1
              ) {
                new_images["#"].childrenIds.push(folder_id);
              } else if (
                new_images[prev_id]?.childrenIds?.indexOf(folder_id) === -1
              ) {
                new_images[prev_id].childrenIds.push(folder_id);
              }
              folder_path += "/";
              new_images[folder_id] = {
                isDir: true,
                name: folder,
                id: folder_id,
                path: folder_path,
                parentId: prev_id || "#",
                childrenIds: [...(new_images[folder_id]?.childrenIds || [])],
                selectable: false,
                draggable: false,
                droppable: true,
              };
              if (
                x === path.length - 1 &&
                new_images[folder_id]?.childrenIds?.indexOf(id) === -1
              ) {
                new_images[folder_id].childrenIds.push(id);
              }
              prev_id = folder_id;
            }
          }
        }
        console.log(new_images);
        return new_images;
      });
  }

  async getFonts({ path_id, prefix }) {
    return fetch(`${window.projectServerURL}assets/fonts/${prefix}${path_id}`)
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => []);
  }

  async getVideos({ path_id, prefix }) {
    return fetch(`${window.projectServerURL}assets/videos/${prefix}${path_id}`)
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => []);
  }

  async parseItem({ images, item, zip, folder, root }) {
    if (item.isDir) {
      let new_folder;
      if (root) {
        new_folder = zip.folder("images");
      } else {
        new_folder = folder.folder(item.name);
      }

      for (let i = 0; i < item.childrenIds.length; i++) {
        await this.parseItem({
          item: images[item.childrenIds[i]],
          zip,
          images,
          folder: new_folder,
        });
      }
    } else {
      let binary = await this.urlToPromise(item.url);
      folder.file(item.name, binary, {
        binary: true,
      });
    }
  }

  async exportProject(scene_names) {
    let path_id = this.props.app?.player?.project?.use_project_folder
      ? this.props.app?.player?.project._id
      : localStorage.getItem("user-id");
    let prefix = this.props.app?.player?.project?.use_project_folder
      ? "project/"
      : "";
    const zip = new JSZip();
    zip.folder("scenes");
    zip.folder("fonts");
    let images_to_download = [];
    for (let i = 0; i < scene_names?.length; i++) {
      let scene = this.props.app.player.project.scenes?.find(
        (s) => s.name === scene_names[i]
      );

      let items_array = scene.getItemsByType({
        type: "IMAGE",
        children: scene.scene.children,
      });
      for (let x = 0; x < items_array.length; x++) {
        images_to_download.push(items_array[x].src);
      }
      zip.file("scenes/" + scene.name + ".json", JSON.stringify(scene.save()));
    }
    let images = await this.getImages({
      path_id,
      prefix,
      images_to_download,
    });

    await this.parseItem({
      images,
      item: images["#"],
      zip,
      root: true,
    });
    let fonts = await this.getFonts({
      path_id,
      prefix,
    });
    for (let x = 0; x < fonts.length; x++) {
      let font = fonts[x];
      zip.file(
        "fonts/" + font.name,
        this.urlToPromise(
          window.projectServerURL +
            "assets/fonts/" +
            prefix +
            path_id +
            "/" +
            font.name
        ),
        {
          binary: true,
        }
      );
    }

    // let videos = await this.getVideos({
    //   path_id,
    //   prefix,
    // });
    // for (let x = 0; x < videos.length; x++) {
    //   let video = videos[x];
    //   zip.file(
    //     "videos/" + video.name,
    //     this.urlToPromise(
    //       window.projectServerURL +
    //         "assets/fonts/" +
    //         prefix +
    //         path_id +
    //         "/" +
    //         video.name
    //     ),
    //     {
    //       binary: true,
    //     }
    //   );
    // }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, this.props.app?.player?.project?.name + ".hyper");
    });
  }

  render() {
    if (this.props.app.player.project === null) {
      return <div />;
    }

    let folders = [];
    this.props.app.player.project.scenes.map((scene) => {
      folders.push(scene.folder);
    });
    folders = [...new Set(folders)];
    return (
      <div className={styles.Main}>
        <Row>
          <Button
            onClick={() => {
              this.props.selectEditView();
            }}
          >
            Back
          </Button>
        </Row>
        <Row>
          <div className={styles.ProjectViewer}>
            {folders.map((folder) => {
              return (
                <div className={styles.Item}>
                  <div className={styles.FolderTitle}>
                    <div className={styles.Type}>
                      <LayerGroup />
                    </div>
                    {folder}
                    <div
                      class={styles.Expanded}
                      onClick={() => {
                        if (this.state.folderToggle.indexOf(folder) > -1) {
                          this.setState({
                            ...this.state,
                            folderToggle: this.state.folderToggle.filter(
                              (f) => f !== folder
                            ),
                          });
                        } else {
                          this.setState({
                            ...this.state,
                            folderToggle: [...this.state.folderToggle, folder],
                          });
                        }
                      }}
                    >
                      {this.state.folderToggle.indexOf(folder) == -1 && (
                        <TriangleRight />
                      )}
                      {this.state.folderToggle.indexOf(folder) > -1 && (
                        <TriangleDown />
                      )}
                    </div>
                  </div>
                  <div className={styles.ScenesList}>
                    {this.state.folderToggle.indexOf(folder) > -1 &&
                      this.props.app.player.project.scenes
                        .filter(
                          (scene) =>
                            scene.folder === folder &&
                            this.state.exports.findIndex(
                              (e) => e === scene.name
                            ) === -1
                        )
                        .map((scene, index) => {
                          return (
                            <div
                              key={index}
                              className={`${styles.Scene} ${
                                false && this.state.editSceneName === null
                                  ? styles.Active
                                  : ""
                              }`}
                              onClick={() =>
                                this.setState({
                                  ...this.state,
                                  exports: [...this.state.exports, scene.name],
                                })
                              }
                            >
                              {this.state.editSceneName !== scene && (
                                <div>{scene.name}</div>
                              )}
                            </div>
                          );
                        })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.ProjectViewer} style={{ marginLeft: "10px" }}>
            <div className={styles.ScenesList}>
              {this.state.exports.map((scene, index) => {
                return (
                  <div
                    key={index}
                    className={`${styles.Scene} ${
                      this.props.app.player.selectedScene === scene &&
                      this.state.editSceneName === null
                        ? styles.Active
                        : ""
                    }`}
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        exports: this.state.exports.filter((e) => e !== scene),
                      })
                    }
                  >
                    {this.state.editSceneName !== scene && <div>{scene}</div>}
                  </div>
                );
              })}
            </div>
          </div>
          {this.state.exports.length > 0 && (
            <div
              className={`${styles.Button}`}
              onClick={() => {
                this.exportProject(this.state.exports);
              }}
            >
              EXPORT
            </div>
          )}
        </Row>
      </div>
    );
  }
}
