import React, { useState } from "react";
import styles from "./styles.module.css";

export default function XMLViewer(props) {
  return (
    <div>
      {props.data &&
        typeof props.data === "object" &&
        Object.keys(props.data).map(key => {
          return (
            <XmlNode
              name={key}
              item={props.data[key]}
              keyValue={key}
              uuid={props.uuid}
            />
          );
        })}
      {typeof props.data !== "object" && (
        <div className={styles.Value}>{props.data}</div>
      )}
    </div>
  );
}

function XmlNode(props) {
  const [expanded, setExpanded] = useState(false);

  let name = props.name;
  let key = props.keyValue;
  let item = props.item;

  function drag(e, property, source) {
    e.dataTransfer.setData("source", source);
    e.dataTransfer.setData("property", property);
  }

  if (name === "expanded") {
    return;
  }

  return (
    <div className={styles.Node}>
      <div className={styles.NodeContent}>
        {typeof item === "object" && (
          <div
            className={styles.Expand}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            +
          </div>
        )}

        <div
          className={styles.Name}
          draggable={true}
          onDragStart={event => {
            drag(event, key, props.uuid);
          }}
        >
          {name}
        </div>
        {typeof item !== "object" && <div className={styles.Value}>{item}</div>}
      </div>

      {typeof item === "object" &&
        expanded === true &&
        Object.keys(item).map(child => {
          return (
            <XmlNode
              name={child}
              item={item[child]}
              keyValue={key + "." + child}
              uuid={props.uuid}
            />
          );
        })}
    </div>
  );
}
