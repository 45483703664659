const PIXI = window.PIXI;

export default class SpriteMaskFilter_Alpha extends PIXI.Filter {
  maskSprite;
  maskMatrix;

  constructor(sprite) {
    const maskMatrix = new PIXI.Matrix();
    let vertex = `attribute vec2 aVertexPosition;
      attribute vec2 aTextureCoord;
      
      uniform mat3 projectionMatrix;
      uniform mat3 otherMatrix;
      
      varying vec2 vMaskCoord;
      varying vec2 vTextureCoord;
      
      void main(void)
      {
          gl_Position = vec4((projectionMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);
      
          vTextureCoord = aTextureCoord;
          vMaskCoord = ( otherMatrix * vec3( aTextureCoord, 1.0)  ).xy;
      }
    `;

    let fragment = `varying vec2 vMaskCoord;
    varying vec2 vTextureCoord;
    
    uniform sampler2D uSampler;
    uniform sampler2D mask;
    uniform float alpha;
    uniform float npmAlpha;
    uniform vec4 maskClamp;
    
    void main(void)
    {
      float clip = step(3.5,
        step(maskClamp.x, vMaskCoord.x) +
        step(maskClamp.y, vMaskCoord.y) +
        step(vMaskCoord.x, maskClamp.z) +
        step(vMaskCoord.y, maskClamp.w));
  
        vec4 original = texture2D(uSampler, vTextureCoord);
        vec4 masky = texture2D(mask, vMaskCoord);
        float alphaMul = 1.0 - npmAlpha * (1.0 - masky.a);
    
        original *= (alphaMul * masky.a * alpha * clip);
    
        gl_FragColor = original;
    }
    `;
    super(vertex, fragment);

    //sprite.renderable = false;
    this.maskSprite = sprite;
    this.maskMatrix = maskMatrix;
    this.class_name = "SpriteMaskFilter_Alpha";
  }

  apply(filterManager, input, output, clearMode) {
    const maskSprite = this.maskSprite;
    const tex = maskSprite._texture;

    if (!tex.valid) {
      return;
    }
    if (!tex.uvMatrix) {
      // margin = 0.0, let it bleed a bit, shader code becomes easier
      // assuming that atlas textures were made with 1-pixel padding
      tex.uvMatrix = new PIXI.TextureMatrix(tex, 0.0);
    }
    tex.uvMatrix.update();

    this.uniforms.npmAlpha = tex.baseTexture.alphaMode ? 0.0 : 1.0;
    this.uniforms.mask = tex;
    // get _normalized sprite texture coords_ and convert them to _normalized atlas texture coords_ with `prepend`
    this.uniforms.otherMatrix = filterManager
      .calculateSpriteMatrix(this.maskMatrix, maskSprite)
      .prepend(tex.uvMatrix.mapCoord);
    this.uniforms.alpha = maskSprite.worldAlpha;
    this.uniforms.maskClamp = tex.uvMatrix.uClampFrame;

    filterManager.applyFilter(this, input, output, clearMode);
  }
}
