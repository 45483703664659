import * as Stats from "stats-js";
import * as THREE from "three";
import { setProperty as SET_PROPERTY } from "../../Scripts/PropertyUtils";

const PIXI = window.PIXI;
const size = 1920 * 1080 * 4;
let index = 0;
let playback = 0;
const pixels = new Uint8Array(1920 * 1080 * 4);
let prevHardwareTime = 0;
class PIXIPlayer {
  constructor(app) {
    this.app = app;
    this.project = null;
    this.stats = new Stats();
    this.sendNDI = false;
    this.autoKeyframe = false;
    this.showKey = false;
    this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    const keyFilterCode = `
    varying vec2 vTextureCoord;
    uniform sampler2D uSampler;
    void main(){
        vec4 pixel = texture2D(uSampler, vTextureCoord);
        gl_FragColor = vec4(pixel.a, pixel.a, pixel.a, pixel.a);
     }`;
    this.keyFilter = new PIXI.Filter(null, keyFilterCode);
    this.bgraFilterCode = `
    varying vec2 vTextureCoord;
    uniform sampler2D uSampler;
    void main(){
        vec4 pixel = texture2D(uSampler, vTextureCoord);
        gl_FragColor = vec4(pixel.b, pixel.g, pixel.r, pixel.a);
     }`;

    this.setup();
    this.getScrubber();
    this.getFrameDisplay();
  }

  getScrubber() {
    this.scrubber = document.getElementById("Scrubber");
    if (!this.scrubber) {
      setTimeout(() => this.getScrubber(), 1000);
    }
  }

  getFrameDisplay() {
    this.frameDisplay = document.getElementById("Frame");
    if (!this.frameDisplay) {
      setTimeout(() => this.getFrameDisplay(), 1000);
    }
  }

  setup() {
    PIXI.settings.GC_MODE = PIXI.GC_MODES.MANUAL;
    PIXI.settings.GC_MODE = PIXI.GC_MODES.AUTO;

    // PIXI.settings.CREATE_IMAGE_BITMAP = true;

    PIXI.settings.FILTER_MULTISAMPLE = PIXI.MSAA_QUALITY.HIGH;
    this.bgraFilter = new PIXI.Filter(null, this.bgraFilterCode);
    //PIXI.settings.GC_MAX_IDLE = 1000;
    // this.renderer = app.renderer;
    PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;
    // PIXI.settings.RESOLUTION = 1;
    PIXI.settings.PRECISION_FRAGMENT = "highp";
    PIXI.settings.MIPMAP_TEXTURES = PIXI.MIPMAP_MODES.ON;

    this.renderer = new PIXI.Renderer({
      width: window.engine_output_size?.width || 1920,
      height: window.engine_output_size?.height || 1080,
      transparent: true,
      antialias: window.useAntiAlias,
      powerPreference: "high-performance",
      // context: window.gl,
      // backgroundColor: 0xff0000,
    });

    PIXI.Ticker.system.stop();
    window.renderer = this.renderer;

    this.renderer.view.style.width = "auto";
    this.renderer.view.style.height = "100%";
    this.createStage();
    this.animate();
  }

  createStage() {
    this.stage = new PIXI.Container();
    this.stage.sortableChildren = true;
    window.stage = this.stage;
  }

  toggleKey() {
    if (this.showKey) {
      this.showKey = false;
      this.stage.filters = null;
    } else {
      this.showKey = true;
      this.stage.filters = [this.keyFilter];
    }
  }

  triggerLogic(scene) {
    if (this.boltLogic) {
      scene.triggerTransition();
    }
  }

  async animate() {
    try {
      if (!this.clock) {
        this.clock = new THREE.Clock();
        this.totalDelta = 0;
      }

      let delta = this.clock.getDelta();

      let now = performance.now();
      this.stats.begin();

      if (window.process && window.usingWindow !== true) {
        // this.stage.position.y = this.renderer.height / this.renderer.resolution;
        // this.stage.scale.y = -1;
      } else {
        //  this.stage.position.y = 0;
        // this.stage.scale.y = window.height / this.renderer.height;
        // this.stage.scale.x = window.width / this.renderer.width;
      }
      if (this.project !== null) {
        for (let i = 0; i < this.project.scenes.length; i++) {
          if (window.editor) {
            //this.project.scenes[i].scene.renderable = false;
            // debugger;
            // if (this.app.sceneView) {
            //   if (this.project.scenes[i] !== this.selectedScene) {
            //     // this.project.scenes[i].scene.visible = false;
            //     this.project.scenes[i].scene.renderable = false;
            //   }
            // } else {
            //   // this.project.scenes[i].scene.visible = true;
            //   this.project.scenes[i].scene.renderable = false;
            // }
            // this.project.scenes[i].scene.renderable = true;
          }

          await this.project.scenes[i].render(delta);

          if (this.project.scenes[i].scene.renderable) {
            this.executeCode(
              this.project.scenes[i].scene,
              delta,
              this.project.scenes[i]
            );
          }

          if (window.editor) {
            if (this.selectedScene === this.project.scenes[i]) {
              let frames = this.project.scenes[i].activeTimeline.frameNumber;

              if (this.scrubber) {
                this.scrubber.style.left = frames * 13 + "px";
              }
              if (this.frameDisplay) {
                this.frameDisplay.value = "Frame: " + frames;
              }

              if (frames % 10 === 0) {
                // window.gl.clearColor(1, 1, 0, 1);
                //  window.gl.clear(window.gl.COLOR_BUFFER_BIT);
                //  debugger
              } else {
                // window.gl.clearColor(1, 0, 1, 1);
                // window.gl.clear(window.gl.COLOR_BUFFER_BIT);
              }
            }
          }
        }

        // render the container
        // if (window.process) {
        //   this.renderer.clear();
        // }
        // console.time("pixi");
        // window.gl.clearColor(1, 1, 0, 1);
        //  window.gl.clear(window.gl.COLOR_BUFFER_BIT);
        //  window.doc.swap();

        if (window.process && false) {
        } else {
          if (
            (window.editor || window.preview || !window.process) &&
            !window.hide_design_window
          ) {
            // this.stage.position.y = 0;
            //this.stage.scale.y = window.height / 1080;
            //this.stage.scale.x = window.width / 1920;
            // await this.renderer.render(this.stage);
          }
        }

        // window.gl.finish(); // for timing
        // window.gl.readPixels(0, 0, 1920, 1080, window.gl.RGBA, window.gl.UNSIGNED_BYTE, window.pixels)
        // //

        this.stage.filters = this.showKey ? [this.keyFilter] : [];
        this.stage.position.y = 0;
        this.stage.scale.y = 1;
        if (window.render_preview) {
          await this.renderer.render(this.stage);
        }
        if (window.playback) {
          this.totalDelta += delta * 1000;

          // window.remote.getGlobal('shared').pixels = window.pixels;
          //
          if (this.totalDelta >= 1000 / 50) {
            this.stage.position.y =
              this.renderer.height / this.renderer.resolution;
            this.stage.scale.y = -1;
            this.stage.filters = [this.bgraFilter];
            await this.renderer.render(this.stage);
            this.renderer.gl.readPixels(
              0,
              0,
              this.renderer.width,
              this.renderer.height,
              this.renderer.gl.RGBA, //webgl mde
              //32993, /// opengl mode
              this.renderer.gl.UNSIGNED_BYTE,
              window.pixels
            );

            window.playback.displayFrame(window.pixels);

            //
            // if (window.g) {
            //   await window.g.send({ video: window.pixels });
            // }

            // console.log("schedule");
            //if (window.playback && window.playback.displayFrame) {
            // index++;
            //  await window.playback.displayFrame(window.pixels);

            // window.playback.schedule({
            //   video: window.pixels,
            //   //sampleFrameCount: 1920,
            //   time: index * 1000,
            // });

            // if (index === 3) {
            //   window.playback.start({ startTime: 0 });
            // }

            // if (index > 3) {
            //   playback++;
            //   if (window.playback.bufferedFrames() > 1) {
            //     let obj = await window.playback
            //       .played(
            //         playback * 1000 - window.playback.bufferedFrames() * 1000
            //       )
            //       .catch((err) => console.error(err));
            //   }
            // } else {
            //   playback = 4;
            // }
          }
          if (window.showNDI == true) {
            console.log("send to ndi");
            window.g.send({ video: window.pixels });
          }

          // console.log("sending: ", this.totalDelta);
          //window.playback.displayFrame(window.pixels);
          // window.sharedMem.writeChannel(
          //   window.channel,
          //   window.pixels,
          //   1920 * 1080 * 4
          // );

          // window.frameSender.send("asynchronous-message", window.pixels);
          this.totalDelta = 0;
        }
        //await timer(5000); // Replace frame data around every 50fps
        if (window.process) {
          // if (index >= this.buffers.length) {
          //   index = 0;
          // }
          // this.stats.end();
          // window.requestAnimationFrameGLFW(() => this.animate());
        }
      }

      // diff = 10;
      // if (diff < 0) {
      // setImmediate(() => this.animate());
      // } else {
      this.stats.end();

      //
      // }
    } catch (err) {
      debugger;
    } finally {
      if (window.snapshot) {
        let name = window.snapshot;
        window.snapshot = false;
        var canvas = document.querySelector("canvas");
        canvas.toBlob((blob) => {
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style.display = "none";

          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = name + ".png";
          a.click();
        });
      }

      //if (window.process) {
      if (window.useRAF) {
        window.requestAnimationFrame(() => this.animate());
      } else {
        setTimeout(() => this.animate(), 5);
      }

      //} else {
      //this.animate();
      //setTimeout(() => this.animate(), 1);
      //  window.requestAnimationFrame(() => this.animate());
      //}
    }
  }

  executeCode(item, delta, scene) {
    if (item.type === "CLOCK") {
      item.update_clock();
    }
    if (item.codeRunner) {
      try {
        item.codeRunner.update(item, delta, scene);
      } catch (e) {
        if (window.editor) {
          console.error(scene.name, item.name, e);
        }
      }
    }
    if (item.autofollowCode) {
      try {
        item.autofollowCode.update(item, delta, scene);
      } catch (e) {
        console.error(e);
      }
    }
    if (item.dataBinds && this.project.dataSources.length > 10) {
      try {
        item.dataBinds.map((binding) => {
          let ds = this.project.dataSources.find((ds) => {
            return ds.uuid === binding.source;
          });
          if (ds) {
            let data = ds.getProperty(binding.property);
            SET_PROPERTY(item, binding.itemProperty || "text", data);
          }
        });
      } catch (e) {
        console.error(e);
      }
    }
    if (item.updateAnchor) {
      item.updateAnchor();
    }
    if (item.children) {
      item.children.map((child) => {
        this.executeCode(child, delta, scene);
      });
    }
  }
}

function timer(t) {
  return new Promise((f, r) => {
    setTimeout(f, t);
  });
}
export default PIXIPlayer;
