import React, { Component } from "react";
import styles from "./Config.module.css";

import Sidebar from "./Sidebar";
import Hardware from "./Hardware";
import ViewBar from "./ViewBar";

export default class Config extends Component {
  render() {
    return (
      <div className={styles.Config}>
        <ViewBar selectEditView={this.props.selectEditView} />
        <Sidebar />
        <div className={styles.Main}>
          <Hardware />
        </div>
      </div>
    );
  }
}
