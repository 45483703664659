import React, { Component, useContext } from "react";
import styled from "styled-components";
import { UserContext } from "../../Contexts/UserContext";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "@szhsin/react-menu/dist/theme-dark.css";
import styles from "./styles.module.css";
import Popup from "../Dialog/Popup";
import { Button } from "../../Theme/Hyper";
import { useState } from "react";

const FileButton = styled(MenuButton)`
  background-color: #0e1219;
  color: white;
  border-radius: 3px;
  cursor: pointer;

  :hover {
    background-color: #db0a41;
  }
`;
export default function TopBar(props) {
  const { user, signOut } = useContext(UserContext);
  const [showExport, setShowExport] = useState(false);

  function processZip(e) {
    debugger;
    let path_id = props.app.player.project?.use_project_folder
      ? props.app.player.project?._id
      : localStorage.getItem("user-id");
    let prefix = props.app.player.project?.use_project_folder ? "project/" : "";

    var file = e.target.files[0];
    let index = 0;
    let img_index = 0;
    let font_index = 0;
    let promises = [];
    JSZip.loadAsync(file) // 1) read the Blob
      .then(function (zip) {
        zip.forEach(async (relativePath, zipEntry) => {
          if (
            relativePath.indexOf("scenes") === 0 &&
            zipEntry.dir === false &&
            index === 0
          ) {
            let data = await zip
              .file(relativePath)
              .async("string")
              .then(function (data) {
                return data;
              });

            let json = JSON.parse(data);

            props.app.player.project.importScene({
              data: json,
              name: json.name,
            });
            index++;
          }

          if (
            relativePath.indexOf("images") === 0 &&
            zipEntry.dir === false &&
            img_index === 0
          ) {
            let data = await zip.file(relativePath).async("uint8array");
            const file = new File([data], zipEntry.name);
            const formData = new FormData();
            formData.append("file", file);
            if (relativePath.indexOf("images") === 0) {
              formData.append(
                "filename",
                zipEntry.name?.replace("images/", "")?.replace("images", "")
              );
            } else {
              formData.append("filename", zipEntry.name);
            }

            promises.push(
              fetch(
                `${window.projectServerURL}assets/images/${prefix}${path_id}`,
                {
                  method: "POST",
                  body: formData,
                }
              ).catch((err) => {})
            );

            img_index++;
          }

          if (
            relativePath.indexOf("fonts") === 0 &&
            zipEntry.dir === false &&
            font_index === 0
          ) {
            let data = await zip.file(relativePath).async("uint8array");
            const file = new File([data], zipEntry.name);
            const formData = new FormData();
            formData.append("file", file);
            formData.append(file.name, zipEntry.name);

            fetch(
              `${window.projectServerURL}assets/fonts/${prefix}${path_id}`,
              {
                method: "POST",
                body: formData,
              }
            );

            font_index++;
          }

          if (
            relativePath.indexOf("videos") === 0 &&
            zipEntry.dir === false &&
            font_index === 0
          ) {
            let data = await zip.file(relativePath).async("uint8array");
            const file = new File([data], zipEntry.name);
            const formData = new FormData();
            formData.append("file", file);
            formData.append(file.name, zipEntry.name);

            fetch(
              `${window.projectServerURL}assets/videos/${prefix}${path_id}`,
              {
                method: "POST",
                body: formData,
              }
            );

            font_index++;
          }
        });
        Promise.all(promises).then(() => {});
      });
  }

  return (
    <div className={styles.TopBar}>
      <div className={styles.Left}>
        {props.app.player.project && (
          <Menu
            menuButton={<FileButton>Project</FileButton>}
            transition
            theming={"dark"}
          >
            <MenuItem
              onClick={async () => {
                props.selectExportView(true);
              }}
            >
              Export
            </MenuItem>
            <MenuItem
              onClick={async () => {
                document.getElementById("import").click();
                document.getElementById("import").value = null;
              }}
            >
              Import
            </MenuItem>
          </Menu>
        )}
        <input
          type="file"
          id="import"
          name="file"
          accept=".hyper"
          style={{ display: "none" }}
          onChange={(e) => {
            processZip(e);
          }}
        />
      </div>

      <img src="Images/HyperLogo.png" alt="Hyper Logo" />
      <div className={styles.Right}>
        {user && (
          <div className={styles.Profile}>
            {user?.username}
            <div
              className={styles.SignOut}
              onClick={() => {
                signOut();
              }}
            >
              Sign Out
            </div>
          </div>
        )}
      </div>
      {showExport && <ExportPopup setShowExport={setShowExport} {...props} />}
    </div>
  );
}

function ExportPopup(props) {
  function urlToPromise(url) {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  function getImages({ path_id, prefix }) {
    console.log(`${window.projectServerURL}assets/images/${prefix}${path_id}`);
    return fetch(`${window.projectServerURL}assets/images/${prefix}${path_id}`)
      .then((response) => response.json())
      .then((data) => {
        let new_images = {};

        new_images["#"] = {
          isDir: true,
          name: "Project",
          id: "#",
          selectable: false,
          childrenIds: [],
          draggable: false,
          droppable: true,
          path: "",
        };
        for (let i = 0; i < data.length; i++) {
          let image = data[i];
          let id = image?.name?.replace(/\//g, "#");
          let path = image.name
            ?.substring(0, image.name.lastIndexOf("/") + 1)
            ?.split("/");
          path = path?.filter((p) => p !== "");
          new_images[id] = {
            id,
            path: image.name?.substring(0, image.name.lastIndexOf("/") + 1),
            name: image.name.replace(/^.*[\\\/]/, ""),
            url: `${
              window.projectServerURL
            }assets/images/${prefix}${path_id}/${image.name?.replace(
              /\//g,
              "%2F"
            )}`,
            thumbnailUrl: `${
              window.projectServerURL
            }assets/images/${prefix}thumbnail/${path_id}/${image.name?.replace(
              /\//g,
              "%2F"
            )}`,
            parentId:
              image.name
                ?.substring(0, image.name.lastIndexOf("/"))
                ?.replace(/\//g, "#") || "#",
            size: parseInt(image.size),
            modDate: image.last_updated,
            draggable: true,
            droppable: false,
          };

          let folder_path = "";
          let prev_id = "";
          if (
            path.length === 0 &&
            new_images["#"]?.childrenIds?.indexOf(id) === -1
          ) {
            new_images["#"].childrenIds.push(id);
          }

          for (let x = 0; x < path.length; x++) {
            let folder = path[x];
            folder_path += folder;
            let folder_id = ("#" + folder_path)?.replace(/\//g, "#");

            if (
              x === 0 &&
              new_images["#"]?.childrenIds?.indexOf(folder_id) === -1
            ) {
              new_images["#"].childrenIds.push(folder_id);
            } else if (
              new_images[prev_id]?.childrenIds?.indexOf(folder_id) === -1
            ) {
              new_images[prev_id].childrenIds.push(folder_id);
            }
            folder_path += "/";
            new_images[folder_id] = {
              isDir: true,
              name: folder,
              id: folder_id,
              path: folder_path,
              parentId: prev_id || "#",
              childrenIds: [...(new_images[folder_id]?.childrenIds || [])],
              selectable: false,
              draggable: false,
              droppable: true,
            };
            if (
              x === path.length - 1 &&
              new_images[folder_id]?.childrenIds?.indexOf(id) === -1
            ) {
              new_images[folder_id].childrenIds.push(id);
            }
            prev_id = folder_id;
          }
        }
        console.log(new_images);
        return new_images;
      });
  }

  async function getFonts({ path_id, prefix }) {
    return fetch(`${window.projectServerURL}assets/fonts/${prefix}${path_id}`)
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => []);
  }

  async function getVideos({ path_id, prefix }) {
    return fetch(`${window.projectServerURL}assets/videos/${prefix}${path_id}`)
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => []);
  }

  async function parseItem({ images, item, zip, folder, root }) {
    debugger;
    console.log(images);
    if (item.isDir) {
      let new_folder;
      if (root) {
        new_folder = zip.folder("images");
      } else {
        new_folder = folder.folder(item.name);
      }

      for (let i = 0; i < item.childrenIds.length; i++) {
        await parseItem({
          item: images[item.childrenIds[i]],
          zip,
          images,
          folder: new_folder,
        });
      }
    } else {
      let binary = await urlToPromise(item.url);
      folder.file(item.name, binary, {
        binary: true,
      });
    }
  }

  async function exportProject() {
    let path_id = props.app?.player?.project?.use_project_folder
      ? props.app?.player?.project._id
      : localStorage.getItem("user-id");
    let prefix = props.app?.player?.project?.use_project_folder
      ? "project/"
      : "";
    const zip = new JSZip();
    zip.folder("scenes");
    zip.folder("fonts");
    for (let i = 0; i < props.app?.player?.project?.scenes?.length; i++) {
      let scene = props.app.player.project.scenes[i];
      zip.file("scenes/" + scene.name + ".json", JSON.stringify(scene.save()));
    }
    let images = await getImages({
      path_id,
      prefix,
    });

    await parseItem({
      images,
      item: images["#"],
      zip,
      root: true,
    });
    let fonts = await getFonts({
      path_id,
      prefix,
    });
    for (let x = 0; x < fonts.length; x++) {
      let font = fonts[x];
      zip.file(
        "fonts/" + font.name,
        urlToPromise(
          window.projectServerURL +
            "assets/fonts/" +
            prefix +
            path_id +
            "/" +
            font.name
        ),
        {
          binary: true,
        }
      );
    }

    let videos = await getVideos({
      path_id,
      prefix,
    });
    for (let x = 0; x < videos.length; x++) {
      let video = videos[x];
      zip.file(
        "videos/" + video.name,
        urlToPromise(
          window.projectServerURL +
            "assets/fonts/" +
            prefix +
            path_id +
            "/" +
            video.name
        ),
        {
          binary: true,
        }
      );
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, props.app?.player?.project?.name + ".hyper");
    });
  }
  return (
    <Popup
      title={"Export templates"}
      //onSave={() => this.deleteImage()}
      onCancel={() => {
        debugger;
        props.setShowExport(false);
      }}
      confirmButton={"Export"}
    >
      List of scenes
      {props.app?.player?.project?.scenes?.map((scene) => {
        return <span>{scene?.name}</span>;
      })}
    </Popup>
  );
}
