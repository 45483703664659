import React, { Component } from "react";
import styles from "./Properties.module.css";
import transformStyles from "./Transform.module.css";
import textStyles from "./Text.module.css";
import TriangleDown from "../../SVG/TriangleDown";
import TriangleRight from "../../SVG/TriangleRight";
import {
  setProperty as SET_PROPERTY,
  redrawCircle,
  redrawRectangle,
} from "../../Scripts/PropertyUtils";
import DragInput from "../DragInput/DragInput";

export default class Tranform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      property: null,
      locked: false,
    };
  }

  setRatioLock() {
    let ratioWidth = this.props.item.height / this.props.item.width;
    let ratioHeight = this.props.item.width / this.props.item.height;
    this.setState({ ...this.state, ratioWidth, ratioHeight, locked: true });
  }

  updateProperty(property, value) {
    if (property === "visible") {
      SET_PROPERTY(this.props.item, property, value);
    } else if (property === "zIndex") {
      if (value >= this.props.item.parent.children.length) {
        value = this.props.item.parent.children.length - 1;
      }
      this.props.item.parent.setChildIndex(this.props.item, value);
    } else if (property === "width" && this.state.locked) {
      let height = parseInt(value) * this.state.ratioWidth;
      if (height <= 0) {
        return;
      }

      this.props.item.height = parseInt(height);

      this.props.item.width = parseInt(value);
      SET_PROPERTY(this.props.item, "width", this.props.item.width);
      SET_PROPERTY(this.props.item, "height", this.props.item.height);
    } else if (property === "height" && this.state.locked) {
      let width = parseInt(value * this.state.ratioHeight);
      if (width <= 0) {
        return;
      }

      this.props.item.width = parseInt(width);

      this.props.item.height = parseInt(value);
      SET_PROPERTY(this.props.item, "width", this.props.item.width);
      SET_PROPERTY(this.props.item, "height", this.props.item.height);
    } else {
      // if (isNaN(value) === false) {
      // value = parseFloat(value);
      SET_PROPERTY(this.props.item, property, value);

      if (this.props.item.updateAnchor) {
        this.props.item.updateAnchor();
      }
      // }
    }
    window.hyperAPI.addKeyframe(property, value, this.props.item);
  }
  drag(e, property, value) {
    e.dataTransfer.setData("property", property);
    e.dataTransfer.setData("value", value);
    console.log(e);
  }

  handleMouseDown(e, property, startValue) {
    this.property = property;
    this.mouseDown = true;
    var rect = document.querySelector("body").getBoundingClientRect();

    var x = e.clientX - rect.left; //x position within the element.
    this.startX = x;

    this.startValue = parseInt(startValue);
    document.addEventListener("mousemove", this.handleMouseMove.bind(this));
    document.addEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  handleMouseMove(e) {
    if (this.mouseDown) {
      var rect = document.querySelector("body").getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      x = x - this.startX;
      if (this.property === "radius" && this.props.item.type === "RECTANGLE") {
        this.props.item.radius = this.startValue + x;
        redrawRectangle({ item: this.props.item });
      } else if (
        this.property === "radius" &&
        this.props.item.type === "CIRCLE"
      ) {
        this.props.item.radius = this.startValue + x;
        redrawCircle({ item: this.props.item });
      } else if (this.property === "rotation") {
        let value = parseFloat(
          (parseInt(this.startValue) + parseInt(x)) / 57.2958
        );

        this.updateProperty(this.property, value);
        if (this.props.item.updateAnchor) {
          this.props.item.updateAnchor();
        }
      } else {
        this.updateProperty(
          this.property,
          parseInt(this.startValue) + parseInt(x)
        );
        if (this.props.item.updateAnchor) {
          this.props.item.updateAnchor();
        }
      }
    }
  }

  handleMouseUp(e) {
    this.mouseDown = false;
    document.removeEventListener("mousemove", this.handleMouseMove.bind(this));
    document.removeEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  render() {
    return (
      <div className={styles.Group}>
        <div
          className={styles.GroupTitle}
          onClick={() =>
            this.setState({ ...this.state, open: !this.state.open })
          }
        >
          {/* {this.props.item.uuid} */}
          <div className={styles.Open}>
            {this.state.open && <TriangleDown />}
            {!this.state.open && <TriangleRight />}
          </div>
          <div className={styles.Title}>Transform</div>
        </div>
        {this.state.open && (
          <div className={transformStyles.Group}>
            <div className={transformStyles.lists}>
              <div className={textStyles.Property}>
                <label className={textStyles.CheckboxContainer}>
                  Visible
                  <input
                    type="checkbox"
                    checked={this.props.item.visible ? true : false}
                    onChange={(e) => {
                      this.updateProperty("visible", e.currentTarget.checked);
                    }}
                  />
                  <span className={textStyles.Checkmark} />
                </label>
              </div>
            </div>
            <div className={transformStyles.lists}>
              <div className={transformStyles.Title}>Position</div>
              <div className={transformStyles.Property}>
                <label
                  className={transformStyles.X}
                  draggable={true}
                  onDragStart={(event) =>
                    this.drag(event, "position.x", this.props.item.position.x)
                  }
                >
                  X
                </label>
                <DragInput
                  value={parseInt(this.props.item.position.x)}
                  onChange={(value) => {
                    this.updateProperty("position.x", value);
                  }}
                />
              </div>
              <div className={transformStyles.Property}>
                <label
                  className={transformStyles.Y}
                  draggable={true}
                  onDragStart={(event) =>
                    this.drag(event, "position.y", this.props.item.position.y)
                  }
                >
                  Y
                </label>
                <DragInput
                  value={parseInt(this.props.item.position.y)}
                  onChange={(value) => {
                    this.updateProperty("position.y", value);
                  }}
                />
              </div>
              {/*<div className={transformStyles.Property}>
                <label
                  className={transformStyles.Z}
                  draggable={true}
                  onDragStart={event =>
                    this.drag(event, "zIndex", this.props.item.zIndex)
                  }
                >
                  Z
                </label>
                <input
                  value={this.props.item.zIndex}
                  onMouseDown={e => {
                    this.handleMouseDown(e, "zIndex", this.props.item.zIndex);
                  }}
                  onChange={e => {
                    this.updateProperty("zIndex", e.currentTarget.value);
                  }}
                />
                </div>*/}
            </div>
            {this.props.item.type !== "GROUP" &&
              this.props.item.type !== "CIRCLE" && (
                <>
                  <div className={transformStyles.lists}>
                    <div className={transformStyles.Title}>Scale</div>
                    {(!this.props.item.size_to_fit ||
                      this.props.item.size_to_fit.length === 0) && (
                      <>
                        <div className={transformStyles.Property}>
                          <label
                            className={transformStyles.X}
                            draggable={true}
                            onDragStart={(event) =>
                              this.drag(event, "width", this.props.item.width)
                            }
                          >
                            X
                          </label>
                          <DragInput
                            value={parseInt(this.props.item.width)}
                            onChange={(value) => {
                              this.updateProperty("width", value);
                            }}
                          />
                        </div>
                        <div className={transformStyles.Property}>
                          <label
                            className={transformStyles.Y}
                            draggable={true}
                            onDragStart={(event) =>
                              this.drag(event, "height", this.props.item.height)
                            }
                          >
                            Y
                          </label>
                          <DragInput
                            value={parseInt(this.props.item.height)}
                            onChange={(value) => {
                              this.updateProperty("height", value);
                            }}
                          />
                        </div>
                        <div
                          style={{ color: this.state.locked ? "red" : "" }}
                          onClick={() => {
                            if (!this.state.locked) {
                              this.setRatioLock();
                            } else {
                              this.setState({
                                ...this.state,
                                locked: false,
                              });
                            }
                          }}
                        >
                          LOCK
                        </div>
                      </>
                    )}
                    {this.props.item.size_to_fit &&
                      this.props.item.size_to_fit.length > 0 && (
                        <span style={{ color: "#8a8c8e" }}>
                          Using Size to fit
                        </span>
                      )}
                    {/*<div className={transformStyles.Property}>
                <label
                  className={transformStyles.Z}
                  draggable={true}
                  onDragStart={event =>
                    this.drag(event, "scale.z", this.props.item.scale.z)
                  }
                >
                  Z
                </label>
                <input
                  value={this.props.item.scale.z.toFixed(3)}
                  onMouseDown={e => {
                    this.handleMouseDown(e, "scale.z", this.props.item.scale.z);
                  }}
                  onChange={e => {
                    this.updateProperty("scale.z", e.currentTarget.value);
                  }}
                />
                </div>*/}
                  </div>

                  <div className={transformStyles.lists}>
                    <div className={transformStyles.Title}>Skew</div>
                    <div className={transformStyles.Property}>
                      <label
                        className={transformStyles.X}
                        draggable={true}
                        onDragStart={(event) =>
                          this.drag(event, "skew.x", this.props.item.skew.x)
                        }
                      >
                        X
                      </label>
                      <input
                        value={this.props.item.skew.x}
                        onMouseDown={(e) => {
                          this.handleMouseDown(
                            e,
                            "skew.x",
                            this.props.item.skew.x
                          );
                        }}
                        onChange={(e) => {
                          this.updateProperty("skew.x", e.currentTarget.value);
                        }}
                      />
                    </div>
                    <div className={transformStyles.Property}>
                      <label
                        className={transformStyles.Y}
                        draggable={true}
                        onDragStart={(event) =>
                          this.drag(event, "skew.y", this.props.item.skew.y)
                        }
                      >
                        Y
                      </label>
                      <input
                        value={this.props.item.skew.y}
                        onMouseDown={(e) => {
                          this.handleMouseDown(
                            e,
                            "skew.y",
                            this.props.item.skew.y
                          );
                        }}
                        onChange={(e) => {
                          this.updateProperty("skew.y", e.currentTarget.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={transformStyles.lists}>
                    <div className={transformStyles.Title}>Max Size</div>
                    <div className={transformStyles.Property}>
                      <label
                        className={transformStyles.X}
                        draggable={true}
                        onDragStart={(event) =>
                          this.drag(event, "maxWidth", this.props.item.maxWidth)
                        }
                      >
                        X
                      </label>
                      <DragInput
                        value={this.props.item.maxWidth}
                        onChange={(value) => {
                          this.updateProperty("maxWidth", value);
                        }}
                      />
                    </div>
                    {this.props.item.type === "TEXT" && (
                      <div className={transformStyles.Property}>
                        <label
                          className={transformStyles.Y}
                          draggable={true}
                          onDragStart={(event) =>
                            this.drag(
                              event,
                              "maxHeight",
                              this.props.item.maxHeight
                            )
                          }
                        >
                          Y
                        </label>
                        <DragInput
                          value={this.props.item.maxHeight}
                          onChange={(value) => {
                            this.updateProperty("maxHeight", value);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            {(this.props.item.type === "RECTANGLE" ||
              this.props.item.type === "CIRCLE") && (
              <div className={transformStyles.lists}>
                <div className={transformStyles.Title}>Radius</div>
                <div className={transformStyles.Property}>
                  <label
                    className={transformStyles.X}
                    draggable={true}
                    onDragStart={(event) =>
                      this.drag(event, "radius", this.props.item.radius)
                    }
                  >
                    R
                  </label>
                  <DragInput
                    value={this.props.item.radius}
                    onChange={(value) => {
                      this.updateProperty("radius", value);
                    }}
                  />
                </div>
              </div>
            )}

            {/* {this.props.item.type === "GROUP" && (
              <div className={transformStyles.lists}>
                <div className={transformStyles.Title}>Anchor</div>
                <div className={transformStyles.Property}>
                  <label
                    className={transformStyles.X}
                    draggable={true}
                    onDragStart={event =>
                      this.drag(event, "pivot.x", this.props.item.pivot.x)
                    }
                  >
                    X
                  </label>
                  <input
                    value={this.props.item.pivot.x}
                    onMouseDown={e => {
                      this.handleMouseDown(
                        e,
                        "pivot.x",
                        this.props.item.pivot.x
                      );
                    }}
                    onChange={e => {
                      this.updateProperty("pivot.x", e.currentTarget.value);
                    }}
                  />
                </div>
                <div className={transformStyles.Property}>
                  <label
                    className={transformStyles.Y}
                    draggable={true}
                    onDragStart={event =>
                      this.drag(event, "pivot.y", this.props.item.pivot.y)
                    }
                  >
                    Y
                  </label>
                  <input
                    value={this.props.item.pivot.y}
                    onMouseDown={e => {
                      this.handleMouseDown(
                        e,
                        "pivot.y",
                        this.props.item.pivot.y
                      );
                    }}
                    onChange={e => {
                      this.updateProperty("pivot.y", e.currentTarget.value);
                    }}
                  />
                </div>
              </div>
            )} */}
            {(this.props.item.type === "IMAGE" ||
              this.props.item.type === "TEXT" ||
              this.props.item.type === "VIDEO" ||
              this.props.item.type === "CLOCK") && (
              <div className={transformStyles.lists}>
                <div className={transformStyles.Title}>Anchor</div>
                <div className={transformStyles.Property}>
                  <label
                    className={transformStyles.X}
                    draggable={true}
                    onDragStart={(event) =>
                      this.drag(event, "anchor.x", this.props.item.anchor.x)
                    }
                  >
                    X
                  </label>
                  <input
                    value={this.props.item.anchor.x}
                    onMouseDown={(e) => {
                      this.handleMouseDown(
                        e,
                        "anchor.x",
                        this.props.item.anchor.x
                      );
                    }}
                    onChange={(e) => {
                      this.updateProperty("anchor.x", e.currentTarget.value);
                    }}
                  />
                </div>
                <div className={transformStyles.Property}>
                  <label
                    className={transformStyles.Y}
                    draggable={true}
                    onDragStart={(event) =>
                      this.drag(event, "anchor.y", this.props.item.anchor.y)
                    }
                  >
                    Y
                  </label>
                  <input
                    value={this.props.item.anchor.y}
                    onMouseDown={(e) => {
                      this.handleMouseDown(
                        e,
                        "anchor.y",
                        this.props.item.anchor.y
                      );
                    }}
                    onChange={(e) => {
                      this.updateProperty("anchor.y", e.currentTarget.value);
                    }}
                  />
                </div>
              </div>
            )}
            {(this.props.item.type === "RECTANGLE" ||
              this.props.item.type === "GROUP") && (
              <div className={transformStyles.lists}>
                <div className={transformStyles.Title}>Anchor</div>
                <div className={transformStyles.Property}>
                  <label
                    className={transformStyles.X}
                    draggable={true}
                    onDragStart={(event) =>
                      this.drag(event, "anchorX", this.props.item.anchorX)
                    }
                  >
                    X
                  </label>
                  <input
                    value={this.props.item.anchorX}
                    onMouseDown={(e) => {
                      this.handleMouseDown(
                        e,
                        "anchorX",
                        this.props.item.anchorX
                      );
                    }}
                    onChange={(e) => {
                      this.updateProperty("anchorX", e.currentTarget.value);
                    }}
                  />
                </div>
                <div className={transformStyles.Property}>
                  <label
                    className={transformStyles.Y}
                    draggable={true}
                    onDragStart={(event) =>
                      this.drag(event, "anchorY", this.props.item.anchorY)
                    }
                  >
                    Y
                  </label>
                  <input
                    value={this.props.item.anchorY}
                    onMouseDown={(e) => {
                      this.handleMouseDown(
                        e,
                        "anchorY",
                        this.props.item.anchorY
                      );
                    }}
                    onChange={(e) => {
                      this.updateProperty("anchorY", e.currentTarget.value);
                    }}
                  />
                </div>
              </div>
            )}

            {this.props.item.type === "GROUP" && (
              <div className={transformStyles.lists}>
                <div className={transformStyles.Title}>Scale</div>
                <div className={transformStyles.Property}>
                  <label
                    className={transformStyles.X}
                    draggable={true}
                    onDragStart={(event) =>
                      this.drag(event, "scale.x", this.props.item.scale.x)
                    }
                  >
                    X
                  </label>
                  <DragInput
                    value={this.props.item.scale.x}
                    onChange={(value) => {
                      this.updateProperty("scale.x", value);
                    }}
                  />
                </div>
                <div className={transformStyles.Property}>
                  <label
                    className={transformStyles.Y}
                    draggable={true}
                    onDragStart={(event) =>
                      this.drag(event, "scale.y", this.props.item.scale.y)
                    }
                  >
                    Y
                  </label>
                  <DragInput
                    value={this.props.item.scale.y}
                    onChange={(value) => {
                      this.updateProperty("scale.y", value);
                    }}
                  />
                </div>
                {/*<div className={transformStyles.Property}>
                <label
                  className={transformStyles.Z}
                  draggable={true}
                  onDragStart={event =>
                    this.drag(event, "scale.z", this.props.item.scale.z)
                  }
                >
                  Z
                </label>
                <input
                  value={this.props.item.scale.z.toFixed(3)}
                  onMouseDown={e => {
                    this.handleMouseDown(e, "scale.z", this.props.item.scale.z);
                  }}
                  onChange={e => {
                    this.updateProperty("scale.z", e.currentTarget.value);
                  }}
                />
                </div>*/}
              </div>
            )}
            <div className={transformStyles.lists}>
              <div className={transformStyles.Title}>Rotation</div>
              <div className={transformStyles.Property}>
                <label
                  className={transformStyles.X}
                  draggable={true}
                  onDragStart={(event) =>
                    this.drag(event, "rotation", this.props.item.rotation)
                  }
                >
                  X
                </label>
                <input
                  value={parseInt(this.props.item.rotation * 57.2958)}
                  onMouseDown={(e) => {
                    this.handleMouseDown(
                      e,
                      "rotation",
                      this.props.item.rotation * 57.2958
                    );
                  }}
                  onChange={(e) => {
                    this.updateProperty(
                      "rotation",
                      parseFloat(e.currentTarget.value / 57.2958)
                    );
                  }}
                />
              </div>
            </div>
            {/* <div className={transformStyles.lists}>
              <div
                className={transformStyles.Title}
                draggable={true}
                onDragStart={(event) =>
                  this.drag(event, "scale.x", this.props.item.scale.x)
                }
              >
                Flip X
              </div>
              <div className={transformStyles.Property}>
                <input
                  className={transformStyles.Checkbox}
                  type="checkbox"
                  checked={this.props.item.scale.x === -1}
                  onChange={(e) => {
                    this.updateProperty(
                      "scale.x",
                      this.props.item.scale.x === -1 ? 1 : -1
                    );
                  }}
                />
              </div>
            </div> */}
          </div>
        )}
      </div>
    );
  }
}
