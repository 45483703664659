import React, { Component } from "react";
import styles from "./Sidebar.module.css";
import Eye from "../../SVG/Eye";
import TriangleRight from "../../SVG/TriangleRight";
import TriangleDown from "../../SVG/TriangleDown";
import LayerGroup from "../../SVG/LayerGroup";
import Image from "../../SVG/Image";
import Movie from "../../SVG/Movie";
import uuid from "uuid/v4";
import cloneDeep from "lodash/cloneDeep";
import { SiJavascript } from "react-icons/si";
import styled from "styled-components";
import Circle from "../../SVG/Circle";
import ClipLoader from "react-spinners/ClipLoader";

const Rectangle = styled.div`
  width: 15px;
  height: 15px;
  background-color: #4b5063;
`;

export default class Scene extends Component {
  constructor(props) {
    super(props);
    this.state = { showContext: false };
  }
  toggleVisibility(item) {
    item.visible = !item.visible;
    this.forceUpdate();
  }

  drag(e, value) {
    this.props.scene.draggingItem = value;
  }

  drop(e, item) {
    var value = this.props.scene.draggingItem;
    e.currentTarget.classList.remove(styles.DropOver);

    if (this.props.scene.draggingTarget.type === "GROUP") {
      value.parent.removeChild(value);
      item.addChild(value);
    } else {
      value.parent.removeChild(value);
      item.parent.addChildAt(value, item.parent.children.indexOf(item) + 1);
    }

    this.props.scene.draggingItem = null;
    this.forceUpdate();
  }

  allowDrop(e, item) {
    if (item !== this.props.scene.draggingItem) {
      e.preventDefault();
    }
  }

  enterDrop(e, item) {
    this.props.scene.draggingTarget = item;
    if (this.props.scene.draggingTarget !== this.props.scene.draggingItem) {
      e.currentTarget.classList.add(styles.DropOver);
    }
  }

  exitDrop(e, item) {
    if (this.props.scene.draggingTarget !== item) {
      e.currentTarget.classList.remove(styles.DropOver);
    }
  }

  toggleExpand(item) {
    item.expanded = !item.expanded;
    this.forceUpdate();
  }

  handleContext(e, item) {
    this.props.selectItem(item);
    e.preventDefault();

    this.setState({
      editItemName: null,
      showContext: true,
      mouseX: e.clientX,
      mouseY: e.clientY,
      editingItem: item,
    });
    window.addEventListener("click", this.handleClick.bind(this));
  }

  handleClick(e) {
    this.setState({
      showContext: false,
    });
    window.removeEventListener("click", this.handleClick.bind(this));
  }

  handleContextRename() {
    this.setState({
      editItemName: this.state.editingItem,
    });
  }

  handleContextDelete() {
    this.removeFromTimelines(this.state.editingItem);
    this.state.editingItem.parent.removeChild(this.state.editingItem);
    this.props.selectItem(null);
  }

  removeFromTimelines(item) {
    this.props.app.player.selectedScene.timelines.map((timeline) => {
      timeline.removeTrack(item);
    });
    item.children.map((child) => {
      this.removeFromTimelines(child);
    });
  }

  handleContextAnimate() {
    this.props.app.player.selectedScene.activeTimeline.addTrack(
      this.state.editingItem
    );
    this.props.refresh();
  }

  handleContextRemoveAnimate() {
    this.props.app.player.selectedScene.activeTimeline.removeTrack(
      this.state.editingItem
    );
  }

  handleContextExpand() {
    this.expand(this.state.editingItem);
  }

  expand(item) {
    item.expanded = true;
    item.children.map((child) => {
      this.expand(child);
    });
  }

  handleContextCollapse() {
    this.collpase(this.state.editingItem);
  }

  collpase(item) {
    item.expanded = false;
    item.children.map((child) => {
      this.expand(child);
    });
  }

  handleContextDuplicate() {
    let obj = cloneDeep(this.props.selectedItem);

    try {
      obj.fillColor =
        this.props.selectedItem.geometry.graphicsData[0].fillStyle.color;
      obj.graphicsData = {
        radius: this.props.selectedItem.geometry.graphicsData[0].shape.radius,
      };
    } catch (err) {}

    this.incrementName(obj);
    obj = this.processDuplicateChildren(obj);
    this.props.scene.importObject(obj, this.props.selectedItem.parent, true);

    this.forceUpdate();
  }

  processDuplicateChildren(obj) {
    if (obj.type == "RECTANGLE") {
      try {
        obj.graphicsData = {
          ...obj.geometry.graphicsData[0].shape,
        };
        obj.fillColor = obj.geometry.graphicsData[0].fillStyle.color;

        // obj.transform =
      } catch (err) {
        debugger;
      }
    }
    if (obj.type == "GROUP") {
      for (let i = 0; i < obj.children.length; i++) {
        obj.children[i] = this.processDuplicateChildren(obj.children[i]);
      }
    }

    let item_animations = this.props.scene.timelines.filter((tl) => {
      let t = tl.tracks?.findIndex((track) => track.object.uuid === obj.uuid);
      return t > -1;
    });

    item_animations = item_animations.map((tl) => {
      return {
        ...tl,
        tracks: tl.tracks?.filter((track) => track.object.uuid === obj.uuid),
      };
    });
    if (item_animations?.length > 0) {
      obj.animations = item_animations;
    }

    return obj;
  }

  handleContextCopy() {
    let obj = cloneDeep(this.props.selectedItem);
    obj = this.processDuplicateChildren(obj);
    window.copyObj = obj;
    this.forceUpdate();
  }

  handleContextPaste() {
    if (window.copyObj) {
      this.props.scene.importObject(
        window.copyObj,
        this.props.selectedItem,
        true
      );
    }
    window.copyObj = null;
  }

  handleContextPasteAnimation() {
    if (window.copyObj) {
      this.props.scene.importObject(
        window.copyObj,
        this.props.selectedItem,
        true,
        true
      );
    }
    window.copyObj = null;
  }
  // clone(obj) {
  //   return {
  //     ...obj,
  //     children: obj.children.map((c) => {
  //       return this.clone(c);
  //     }),
  //     uuid: null,
  //     x: obj.transform.position.x,
  //     y: obj.transform.position.y,
  //     width: obj._width || obj.width,
  //     height: obj._height || obj.height,
  //     skew: {
  //       x: obj.skew.x,
  //       y: obj.skew.y,
  //     },
  //     anchorX: obj.anchorX,
  //     anchorY: obj.anchorY,
  //   };
  // }
  incrementName(obj) {
    try {
      if (isNaN(obj.name.split("_")[1]) === false) {
        let tempName = obj.name.split("_").slice(0);
        obj.name = "";
        tempName.forEach((n, index) => {
          if (index > 0) {
            obj.name += "_";
          }
          if (index === 1) {
            obj.name += parseInt(n) + 1;
          } else {
            obj.name += n;
          }
        });
      }

      if (obj.children && obj.children.length > 0) {
        obj.children.forEach((child) => {
          this.incrementName(child);
        });
      }
    } catch (e) {}
  }

  updateItemName(value) {
    this.state.editItemName.name = value;
    this.forceUpdate();
  }

  onKeyDown(e) {
    console.log(e.keyCode);

    if (e.keyCode === 13) {
      this.props.selectItem(this.state.editItemName);
      this.setState({
        editItemName: null,
      });
    }
  }

  async save() {
    this.setState({
      ...this.state,
      saving: true,
    });
    await this.props.app.player.project.save(this.props.scene.name);
    this.setState({
      ...this.state,
      saving: false,
    });
  }

  renderChildren(item, level) {
    return (
      <div>
        <div
          key={item.uuid}
          className={`${styles.Item} ${
            this.props.selectedItem === item ? styles.Active : ""
          }
         `}
          style={{ paddingLeft: level + "em" }}
          draggable={true}
          onDragStart={(event) => this.drag(event, item)}
          onDrop={(event) => this.drop(event, item)}
          onDragOver={(event) => this.allowDrop(event, item)}
          onDragEnter={(event) => this.enterDrop(event, item)}
          onDragLeave={(event) => this.exitDrop(event, item)}
          onContextMenu={(e) => {
            this.handleContext(e, item);
          }}
        >
          <div
            onClick={() => this.toggleVisibility(item)}
            className={item.visible === true ? styles.Visible : styles.Hidden}
          >
            <Eye />
          </div>
          <div className={styles.Type}>
            {item.type === "GROUP" && <LayerGroup />}
            {item.type === "IMAGE" && <Image />}
            {item.type === "VIDEO" && <Movie />}
            {item.type === "RECTANGLE" && <Rectangle />}
            {item.type === "CIRCLE" && <Circle />}
            {item.type === "TEXT" && "A"}
            {item.code && item.code !== "" && <SiJavascript />}
          </div>
          <div
            className={styles.ObjectName}
            onClick={() => this.props.selectItem(item)}
          >
            {this.state.editItemName === item && (
              <div className={styles.EditName}>
                <input
                  value={item.name}
                  autoFocus
                  onChange={(e) => this.updateItemName(e.currentTarget.value)}
                  onKeyDown={(e) => this.onKeyDown(e)}
                  onFocus={(e) => e.currentTarget.select()}
                />
              </div>
            )}
            {this.state.editItemName !== item && <div>{item.name}</div>}
          </div>
          {item.type === "GROUP" && (
            <div
              class={styles.Expanded}
              onClick={() => this.toggleExpand(item)}
            >
              {item.expanded === false && <TriangleRight />}
              {item.expanded === true && <TriangleDown />}
            </div>
          )}
        </div>
        {item.expanded &&
          item.children.map((i) => {
            return this.renderChildren(i, level + 1);
          })}
      </div>
    );
  }

  render() {
    return (
      <>
        <div className={`${styles.Item} ${styles.Title}`}>
          {this.props.scene.name}
          <div
            className={`${styles.Item} ${styles.Save}`}
            onClick={() => this.save()}
          >
            {this.state.saving === true ? "Saving.." : "Save Scene"}
            <ClipLoader
              color={"#ffffff"}
              loading={this.state.saving === true}
              size={15}
            />
          </div>
        </div>
        <div className={styles.SceneTree}>
          {this.props.scene.scene.children
            .filter((item) => item.type !== "HELPER")
            .map((item) => {
              return this.renderChildren(item, 0);
            })}
          {this.state.showContext && (
            <div
              className={styles.Context}
              style={{
                left: this.state.mouseX + "px",
                top: this.state.mouseY + "px",
              }}
            >
              <div
                className={styles.ContextItem}
                onClick={() => this.handleContextRename()}
              >
                Rename
              </div>
              <div
                className={styles.ContextItem}
                onClick={() => this.handleContextAnimate()}
              >
                Add To Timeline
              </div>
              <div
                className={styles.ContextItem}
                onClick={() => this.handleContextRemoveAnimate()}
              >
                Remove From Timeline
              </div>
              <div
                className={styles.ContextItem}
                onClick={() => this.handleContextDelete()}
              >
                Delete
              </div>
              <div
                className={styles.ContextItem}
                onClick={() => this.handleContextDuplicate()}
              >
                Duplicate
              </div>
              <div
                className={styles.ContextItem}
                onClick={() => this.handleContextCopy()}
              >
                Copy
              </div>
              {window.copyObj && (
                <div
                  className={styles.ContextItem}
                  onClick={() => this.handleContextPaste()}
                >
                  Paste
                </div>
              )}
              {window.copyObj && (
                <div
                  className={styles.ContextItem}
                  onClick={() => this.handleContextPasteAnimation()}
                >
                  Paste with animations
                </div>
              )}
              <div
                className={styles.ContextItem}
                onClick={() => this.handleContextExpand()}
              >
                Expand
              </div>
              <div
                className={styles.ContextItem}
                onClick={() => this.handleContextCollapse()}
              >
                Collapse
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
