import React, { Component } from "react";
import propertiesStyle from "./Properties.module.css";
import transformStyles from "./Transform.module.css";
import textStyles from "./Text.module.css";
import styles from "./Layout.module.css";
import TriangleDown from "../../SVG/TriangleDown";
import TriangleRight from "../../SVG/TriangleRight";
import { setProperty as SET_PROPERTY } from "../../Scripts/PropertyUtils";

export default class Control extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      controlText: "",
      controlID: 0,
      option: "",
    };
  }

  updateProperty(property, value) {
    SET_PROPERTY(this.props.item, property, value);
    this.setState({ update: true });
  }

  render() {
    return (
      <div className={propertiesStyle.Group}>
        <div
          className={propertiesStyle.GroupTitle}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <div className={propertiesStyle.Open}>
            {this.state.open && <TriangleDown />}
            {!this.state.open && <TriangleRight />}
          </div>
          <div className={propertiesStyle.Title}>Control</div>
        </div>
        {this.state.open && (
          <div className={styles.Group}>
            <div className={transformStyles.lists}>
              <div className={transformStyles.Title}>ControlID</div>
              <div className={transformStyles.Property}>
                <input
                  value={parseInt(this.props.item.control.id)}
                  onChange={(e) => {
                    this.updateProperty("control.id", e.currentTarget.value);
                  }}
                />
              </div>
            </div>
            <div className={transformStyles.lists}>
              <div className={transformStyles.Title}>Control Name</div>
              <div className={transformStyles.Property}>
                <input
                  style={{ width: "200px" }}
                  value={this.props.item.control.name}
                  onChange={(e) => {
                    this.updateProperty("control.name", e.currentTarget.value);
                  }}
                />
              </div>
            </div>

            <div className={textStyles.Property}>
              <label className={textStyles.CheckboxContainer}>
                Position.x
                <input
                  type="checkbox"
                  checked={
                    this.props.item.control?.editables?.find(
                      (e) => e.name === "x"
                    )?.enabled || false
                  }
                  onChange={(e) => {
                    if (!this.props.item.control.editables) {
                      this.props.item.control.editables = [];
                    }
                    let prev = this.props.item.control?.editables?.findIndex(
                      (e) => e.name === "x"
                    );
                    if (prev === -1) {
                      this.props.item.control.editables.push({
                        name: "x",
                        enabled: true,
                      });
                    } else {
                      this.props.item.control.editables[prev].enabled =
                        !this.props.item.control.editables[prev].enabled;
                    }
                    this.setState({ update: true });
                  }}
                />
                <span className={textStyles.Checkmark} />
              </label>
            </div>

            <div className={textStyles.Property}>
              <label className={textStyles.CheckboxContainer}>
                Position.y
                <input
                  type="checkbox"
                  checked={
                    this.props.item.control?.editables?.find(
                      (e) => e.name === "y"
                    )?.enabled || false
                  }
                  onChange={(e) => {
                    if (!this.props.item.control.editables) {
                      this.props.item.control.editables = [];
                    }
                    let prev = this.props.item.control?.editables?.findIndex(
                      (e) => e.name === "y"
                    );
                    if (prev === -1) {
                      this.props.item.control.editables.push({
                        name: "y",
                        enabled: true,
                      });
                    } else {
                      this.props.item.control.editables[prev].enabled =
                        !this.props.item.control.editables[prev].enabled;
                    }
                    this.setState({ update: true });
                  }}
                />
                <span className={textStyles.Checkmark} />
              </label>
            </div>

            {this.props.item?.type === "TEXT" && (
              <div className={textStyles.Property}>
                <label className={textStyles.CheckboxContainer}>
                  Text
                  <input
                    type="checkbox"
                    checked={
                      this.props.item.control?.editables?.find(
                        (e) => e.name === "text"
                      )?.enabled || false
                    }
                    onChange={(e) => {
                      if (!this.props.item.control.editables) {
                        this.props.item.control.editables = [];
                      }
                      let prev = this.props.item.control?.editables?.findIndex(
                        (e) => e.name === "text"
                      );
                      if (prev === -1) {
                        this.props.item.control.editables.push({
                          name: "text",
                          enabled: true,
                        });
                      } else {
                        this.props.item.control.editables[prev].enabled =
                          !this.props.item.control.editables[prev].enabled;
                      }
                      this.setState({ update: true });
                    }}
                  />
                  <span className={textStyles.Checkmark} />
                </label>
              </div>
            )}
            {this.props.item?.type === "TEXT" && (
              <div className={textStyles.Property}>
                <label className={textStyles.CheckboxContainer}>
                  Font size
                  <input
                    type="checkbox"
                    checked={
                      this.props.item.control?.editables?.find(
                        (e) => e.name === "font_size"
                      )?.enabled || false
                    }
                    onChange={(e) => {
                      if (!this.props.item.control.editables) {
                        this.props.item.control.editables = [];
                      }
                      let prev = this.props.item.control?.editables?.findIndex(
                        (e) => e.name === "font_size"
                      );
                      if (prev === -1) {
                        this.props.item.control.editables.push({
                          name: "font_size",
                          enabled: true,
                        });
                      } else {
                        this.props.item.control.editables[prev].enabled =
                          !this.props.item.control.editables[prev].enabled;
                      }
                      this.setState({ update: true });
                    }}
                  />
                  <span className={textStyles.Checkmark} />
                </label>
              </div>
            )}
            {this.props.item?.type === "IMAGE" && (
              <div className={textStyles.Property}>
                <label className={textStyles.CheckboxContainer}>
                  Image
                  <input
                    type="checkbox"
                    checked={
                      this.props.item.control?.editables?.find(
                        (e) => e.name === "image"
                      )?.enabled || false
                    }
                    onChange={(e) => {
                      if (!this.props.item.control.editables) {
                        this.props.item.control.editables = [];
                      }
                      let prev = this.props.item.control?.editables?.findIndex(
                        (e) => e.name === "image"
                      );
                      if (prev === -1) {
                        this.props.item.control.editables.push({
                          name: "image",
                          enabled: true,
                        });
                      } else {
                        this.props.item.control.editables[prev].enabled =
                          !this.props.item.control.editables[prev].enabled;
                      }
                      this.setState({ update: true });
                    }}
                  />
                  <span className={textStyles.Checkmark} />
                </label>
              </div>
            )}
            {
              <div className={textStyles.Property}>
                <label className={textStyles.CheckboxContainer}>
                  Colour
                  <input
                    type="checkbox"
                    checked={
                      this.props.item.control?.editables?.find(
                        (e) => e.name === "colour"
                      )?.enabled || false
                    }
                    onChange={(e) => {
                      if (!this.props.item.control.editables) {
                        this.props.item.control.editables = [];
                      }
                      let prev = this.props.item.control?.editables?.findIndex(
                        (e) => e.name === "colour"
                      );
                      if (prev === -1) {
                        this.props.item.control.editables.push({
                          name: "colour",
                          enabled: true,
                        });
                      } else {
                        this.props.item.control.editables[prev].enabled =
                          !this.props.item.control.editables[prev].enabled;
                      }
                      this.setState({ update: true });
                    }}
                  />
                  <span className={textStyles.Checkmark} />
                </label>
              </div>
            }
            {this.props.item?.type === "TEXT" && (
              <>
                <div className={transformStyles.lists}>
                  <div className={transformStyles.Title}>Options</div>
                  <div
                    className={transformStyles.Property}
                    style={{ width: "100%" }}
                  >
                    <input
                      style={{ width: "200px" }}
                      value={this.state.option}
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          option: e.currentTarget.value,
                        });
                      }}
                    />
                    <div
                      className={textStyles.UploadButton}
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          option: "",
                        });
                        let new_options = [
                          ...(this.props.item.control.options || []),
                        ];
                        new_options.push({
                          name: this.state.option,
                          value: this.state.option,
                        });
                        this.updateProperty("control.options", new_options);
                      }}
                    >
                      Add
                    </div>
                  </div>
                </div>
                <div
                  className={transformStyles.lists}
                  style={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                  {this.props.item?.control?.options?.map((item, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "90%",
                          padding: "3px",
                          backgroundColor: index % 2 ? "#12161d" : "",
                        }}
                      >
                        {item?.name}
                        <div
                          className={textStyles.UploadButton}
                          style={{ marginLeft: "auto" }}
                          onClick={() => {
                            let new_options = [
                              ...(this.props.item.control.options || []),
                            ];
                            new_options = new_options.filter(
                              (i) => i.name !== item?.name
                            );
                            this.updateProperty("control.options", new_options);
                          }}
                        >
                          Remove
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}
