import React, { Component } from "react";
import styles from "./Sidebar.module.css";

export default class Sidebar extends Component {
  render() {
    return (
      <div className={styles.Sidebar}>
        <div className={styles.Header}>Configuration</div>
        <div className={`${styles.Item} ${styles.Active}`}>Ouputs</div>
      </div>
    );
  }
}
