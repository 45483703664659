import React, { useEffect, useState } from "react";
import { Button } from "../../../Theme/Hyper";
import styled from "styled-components";
import NeverNoData from "./NeverNo";
import NeverNoPollData from "./NeverNoPoll";
const Buttons = styled.div`
  display: flex;
`;

const OptionButton = styled(Button)`
  background-color: ${(props) => (props.green ? "green" : "")};
`;
export default function NeverNoOptions({ scene, app }) {
  const [type, setType] = useState();
  useEffect(() => {
    setType(scene?.neverno?.type);
  }, [scene]);
  return (
    <div>
      <Buttons>
        <OptionButton
          green={type === "tweet"}
          onClick={() => {
            scene.neverno.type = "tweet";
            setType("tweet");
          }}
        >
          Tweet
        </OptionButton>
        <OptionButton
          green={type === "poll"}
          onClick={() => {
            scene.neverno.type = "poll";
            setType("poll");
          }}
        >
          Poll
        </OptionButton>
      </Buttons>
      {type === "tweet" && <NeverNoData scene={scene} app={app} />}
      {type === "poll" && <NeverNoPollData scene={scene} app={app} />}
    </div>
  );
}
