import React, { Component } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/cobalt.css";
import "codemirror/mode/javascript/javascript";
import "./styles.css";
const defaultCode = `// Executed when graphic loaded
function loaded() {

}

// Executed when graphic
// updated each frame
function update(item, frame, scene) {
  
}`;
export default class CodeEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code:
        this.props.item?.code && this.props.item?.code !== ""
          ? this.props.item?.code
          : this.props.default_code || defaultCode,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.item?.uuid !== this.props.item?.uuid ||
      prevProps.item?.code !== this.props.item?.code
    ) {
      this.setState({
        code:
          this.props.item?.code && this.props.item?.code !== ""
            ? this.props.item?.code
            : this.props.default_code || defaultCode,
      });
    }
  }

  render() {
    if (!this.props.item) {
      return <div />;
    }
    return (
      <div style={{ maxHeight: "100%", overflow: "hidden" }}>
        <CodeMirror
          value={this.state.code}
          onBeforeChange={(editor, data, value) => {
            this.setState({ code: value });
          }}
          onChange={(editor, data, value) => {
            this.props.item.code = value;
            try {
              let func = new Function(
                "hyperAPI",
                "currentItem",
                `
              let window = '';
                let console = {
                log: function(text) {
                    hyperAPI.log(text);
                }
            }

               function onUpdateAnim() {

              }

              function onBeforeValueChange() {

              }

              function update() {

              }

              function loaded() {

              }
              function trigger() {

              }
              function runAnimation() {

              }
               ${value}
                return {
                  trigger: trigger,
                  update: update,
                  loaded: loaded,
                  onUpdateAnim: onUpdateAnim,
                  onBeforeValueChange: onBeforeValueChange,
                  runAnimation: runAnimation
                };
              `
              )(window.hyperAPI, this.props.item);

              this.props.item.codeRunner = func;
            } catch (e) {
              console.error(e);
            }
          }}
          // onChange={(code) => {
          //   debugger
          //   this.props.item.code = code;
          //   try {
          //     let func = new Function(
          //       "hyperAPI",
          //       "currentItem",
          //       `
          //     let window = '';
          //       let console = {
          //       log: function(text) {
          //           hyperAPI.log(text);
          //       }
          //   }

          //        function onUpdateAnim() {

          //     }

          //      ${code}
          //       return {
          //         update: update,
          //         loaded: loaded,
          //         onUpdateAnim: onUpdateAnim
          //       };
          //     `
          //     )(window.hyperAPI, this.props.item);

          //     this.props.item.codeRunner = func;
          //   } catch (e) {
          //     console.error(e);
          //   }
          // }}
          options={{
            lineNumbers: true,
            mode: "javascript",
            theme: "cobalt",
            lineWrapping: false,
          }}
        />
        {/* <textarea id="logger"></textarea> */}
        {/* <div
          onClick={() => {
            this.props.item.codeRunner.loaded();
          }}
        >
          Execute
        </div> */}
      </div>
    );
  }
}
