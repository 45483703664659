import React, { Component } from "react";
import styles from "./Canvas.module.css";
import Expand from "../../SVG/Expand";
import styled from "styled-components";
import { ScrollBar } from "../../Theme/Hyper";

const ScrollRow = styled(ScrollBar)`
  display: flex;
  background-color: ${({ selected }) => (selected ? "red" : "")};
  justify-content: center;
  overflow-x: auto;
  width: 100px;
`;

const Select = styled.select`
  background-color: #181d25;
  color: #fff;
  padding: 4px;
  border: none;
  margin: 4px;
  border-radius: 4px;
  height: 30px;
`;

export default class Canvas extends Component {
  constructor(props) {
    super(props);
    this.player = props.player;
    this.state = {
      showingKey: false,
      selectedEngine: "",
    };
  }
  componentDidMount() {
    //pixi
    // document.getElementById("Player").appendChild(this.player.renderer.view);

    //playcanvas
    // document.getElementById("Player").appendChild( window.canvas);
    // if (window.process && window.preview === false) {
    //   document.getElementById("Player").appendChild(window.playoutCanvas);
    // } else {
    this.player?.renderer?.view &&
      document.getElementById("Player").appendChild(this.player.renderer.view);
    // }

    this.player.stats.dom.style.position = window.showFPS
      ? "absolute"
      : "initial";

    if (this.props.engine === true) {
      document.querySelector("canvas").style.width = window.width + "px";
      document.querySelector("canvas").style.height = window.height + "px";
      document.querySelector("canvas").style.position = "absolute";
      document.querySelector("canvas").style.left = "0px";
      if (window.showFPS) {
        document.getElementById("Viewport").appendChild(this.player.stats.dom);
      }
    } else {
      document.getElementById("Viewport").appendChild(this.player.stats.dom);
      document.querySelector("canvas").style.width = "960px";
      document.querySelector("canvas").style.height = "540px";
    }
  }

  toggleKey() {
    this.player.toggleKey();
    this.setState({
      ...this.state,
      showingKey: !this.state.showingKey,
    });
  }

  toggleViewport() {
    if (document.querySelector("canvas").style.position === "absolute") {
      document.querySelector("canvas").style.position = "inherit";
      document.querySelector("canvas").style.width = "auto";
      document.querySelector("canvas").style.height = "100%";
    } else {
      document.querySelector("canvas").style.position = "absolute";
      document.querySelector("canvas").style.left = "0px";
      document.querySelector("canvas").style.width = "1920px";
      document.querySelector("canvas").style.height = "1080px";
    }
  }

  snapshot() {
    try {
      window.snapshot = this.props.player.selectedScene.name;
    } catch (err) {}
  }

  render() {
    return (
      <div
        className={`${styles.Canvas} ${
          this.props.engine === true ? styles.Engine : ""
        }`}
        id="Viewport"
      >
        {this.props.engine === false && (
          <div className={styles.Toolbar}>
            <div
              className={`${styles.Button} ${
                this.state.showingKey ? styles.Active : ""
              }`}
              onClick={() => this.toggleKey()}
            >
              KEY
            </div>
            <div
              className={`${styles.Button} `}
              onClick={() => this.snapshot()}
              style={{ width: "80px" }}
            >
              SNAPSHOT
            </div>
            <Select
              value={
                this.props.engines?.find(
                  (e) => e?.key === this.state.selectedEngine
                )?.name
              }
              onChange={(e) => {
                window.ws.disconnect();

                window.ws.connect(e.currentTarget.value);
                this.setState({
                  ...this.state,
                  selectedEngine: e.currentTarget.value,
                });
              }}
            >
              <option value="">No Engine Selected</option>;
              {this.props.engines?.map((engine) => {
                return <option value={engine.key}>{engine.name}</option>;
              })}
            </Select>

            {/* <div
              className={`${styles.Button} ${
                this.state.showingKey ? styles.Active : ""
              }`}
              onClick={() => this.toggleKey()}
            >
              Connect to engine...
            </div> */}
            {/* <div
              className={`${styles.Button} ${
                this.state.showingHD ? styles.Active : ""
              }`}
              onClick={() => {
                this.player.renderer.resize(1920, 1080);
              }}
            >
              HD
            </div>
            <div
              className={`${styles.Button} ${
                this.state.showing4K ? styles.Active : ""
              }`}
              onClick={() => {
                this.player.renderer.resize(3840, 2160);
              }}
            >
              4K
            </div> */}
            {window.process && (
              <div
                style={{ width: "150px" }}
                className={`${styles.Button} ${
                  window.render_preview ? styles.Active : ""
                }`}
                onClick={() => {
                  window.render_preview = !window.render_preview;
                  this.forceUpdate();
                }}
              >
                PREVIEW TOGGLE
              </div>
            )}
            <div
              className={styles.Expand}
              onClick={() => this.toggleViewport()}
            >
              <Expand />
            </div>
          </div>
        )}

        <div
          id="Player"
          className={`${styles.Player} ${
            this.props.engine === true ? styles.Engine : ""
          }
          ${
            this.props.engine === false && this.props.dataView
              ? styles.DataView
              : ""
          }`}
        />
      </div>
    );
  }
}
