import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const Main = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const Screen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.primaryBackgroundColour};
  opacity: 0.7;
  z-index: 100;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid grey;
  padding: 0.5em;
  font-weight: 100;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.panelColour};
  width: 500px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20%;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
`;
const CancelButton = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${(props) => props.theme.secondaryBackgroundColour};
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;
const Button = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${(props) => props.theme.highlightColour};
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5em;
`;
const Input = styled.input`
  display: flex;
  justify-content: center;
  margin: 0.5em;
  background-color: ${(props) => props.theme.secondaryBackgroundColour};
  border-radius: 4px;
  outline: none;
  border: none;
  color: #fff;
  padding: 0.5em;
  font-weight: 100;
  font-size: 1.2em;
`;
const Theme = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5em;
  flex-direction: column;
`;
const Themes = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5em;
`;
const Item = styled.div`
  display: flex;
  padding: 0.5em;
  align-items: center;
  border-radius: 4px;
  background-color: ${(props) => (props.selected ? "#db0a41" : "")};
  :hover {
    background-color: ${(props) => (props.selected ? "#db0a41" : "#1f222b")};
  }
`;
const Colour = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.colour};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 0.5em;
`;
export default function NewItem({
  value,
  onCancel,
  onSave,
  title,
  placeholder,
}) {
  const [localValue, setLocalValue] = useState(value);
  const [oldValue] = useState(value);
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.select();
  }, []);

  useEffect(() => {
    function keydown(e) {
      if (e.keyCode === 27) {
        onCancel();
        e.preventDefault();
      } else if (e.keyCode === 13) {
        onSave(localValue, oldValue);
        e.preventDefault();
      }
    }
    window.addEventListener("keydown", keydown);

    return () => {
      window.removeEventListener("keydown", keydown);
    };
  }, [localValue, oldValue, onCancel, onSave]);
  return (
    <>
      <Screen></Screen>
      <Main>
        <Content>
          <Header>{title}</Header>
          <Input
            ref={inputRef}
            value={localValue}
            onChange={(e) => {
              setLocalValue(e.currentTarget.value);
            }}
            placeholder={placeholder}
          ></Input>
          <Buttons>
            <CancelButton
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </CancelButton>
            {(localValue === undefined ||
              localValue === null ||
              localValue === "") && <Button>Save</Button>}
            {localValue && localValue !== "" && (
              <Button
                onClick={() => {
                  onSave(localValue, oldValue);
                }}
              >
                Save
              </Button>
            )}
          </Buttons>
        </Content>
      </Main>
    </>
  );
}
