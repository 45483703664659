class MovieRenderer {
  constructor({ fileName, app, uid }) {
    this.app = app;
    this.uid = uid;
    this.fileName = fileName;

    this.setup();
  }

  setup() {
    //this.app.player.stage.filters = [this.app.player.argbFilter];
    window.frameSender.sendSync("start-render", this.fileName);
  }

  async render(data) {
    if (this.app.player.project) {
      this.app.player.project.scenes.map((scene) => {
        scene.scene.visible = false;
      });
      let scene = this.app.player.project.scenes.find(
        (scene) => scene.name === data.scene
      );
      scene.scene.visible = true;
      let currentTime = 0;

      for (let t = 0; t < data.timelines.length; t++) {
        let tl = data.timelines[t];
        if (tl.action && tl.action === "PAUSE") {
          for (let i = 0; i < tl.duration; i++) {
            currentTime += 1;
            window.frameSender.sendSync("render-frame", this.grabFrame());
          }
        } else {
          let timeline = scene.timelines.find((tl) => {
            return tl.name === data.timelines[t].name;
          });

          for (
            let i = 1;
            i <= (data.timelines[t].duration || timeline.duration + 2);
            i++
          ) {
            // console.log("frame: ", i);

            currentTime += 1;
            await timeline.seek(i);
            this.app.player.executeCode(
              scene.scene,
              1000 / timeline.fps,
              scene
            );

            this.app.player.renderer.render(this.app.player.stage);
            //this.app.player.executeCode(scene.scene, 1000 / 25, scene);
            window.frameSender.sendSync("render-frame", this.grabFrame());
          }
        }
      }
    }

    let filename = window.frameSender.sendSync("end-render", window.pixels);
    // this.app.orca.send({
    //   action: "RENDER_COMPLETE",
    //   filename: filename,
    //   uid: this.uid,
    // });
  }

  thumbnail() {}

  grabFrame() {
    this.app.player.renderer.gl.readPixels(
      0,
      0,
      1920,
      1080,
      this.app.player.renderer.gl.RGBA,
      //32993,
      this.app.player.renderer.gl.UNSIGNED_BYTE,
      window.pixels
    );
    return window.pixels;
  }
}

export default MovieRenderer;
