import React from "react";
import styles from "./styles.module.css";
import XMLViewer from "./XMLViewer";
import CodeMirror from "react-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/cobalt.css";
import "codemirror/mode/javascript/javascript";

export default function Source(props) {
  const [, updateState] = React.useState();

  let defaultCode = `
function update() {
  
}`;

  return (
    <div className={styles.DataSource}>
      <div className={styles.DataSourceHeader}>
        <input
          placeholder="Name"
          value={props.item.name}
          onChange={e => {
            props.updateName(e.currentTarget.value);
          }}
        ></input>
        <input
          placeholder="Name"
          value={props.item.url}
          onChange={e => {
            props.updateUrl(e.currentTarget.value);
          }}
        ></input>
      </div>
      <div className={styles.Main}>
        <div className={styles.DataContent}>
          <XMLViewer data={props.item.getData()} uuid={props.item.uuid} />
        </div>
        <div className={styles.Code}>
          <CodeMirror
            value={
              props.item.modifierCode && props.item.modifierCode !== ""
                ? props.item.modifierCode
                : defaultCode
            }
            onChange={code => {
              try {
                props.item.modifier = code;
                updateState({});
              } catch (e) {
                console.error(e);
              }
            }}
            options={{
              lineNumbers: true,
              mode: "javascript",
              theme: "cobalt",
              lineWrapping: true
            }}
          />
        </div>
        <div className={styles.DataContent}>
          <XMLViewer data={props.item.getData()} uuid={props.item.uuid} />
        </div>
      </div>
    </div>
  );
}
