import React, { Component } from "react";
import propertiesStyle from "./Properties.module.css";
import textStyles from "./Text.module.css";
import TriangleDown from "../../SVG/TriangleDown";
import TriangleRight from "../../SVG/TriangleRight";
import { setProperty as SET_PROPERTY } from "../../Scripts/PropertyUtils";
import styled from "styled-components";
import DragInput from "../DragInput/DragInput";

const Select = styled.select`
  background-color: #181d25;
  color: #fff;
  padding: 4px;
  border: none;
  margin: 4px;
  border-radius: 4px;
`;

export default class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorClicked: false,
      open: false,
      font: null,
      showContext: false,
    };
  }

  drag(e, property, value) {
    e.dataTransfer.setData("property", property);
    e.dataTransfer.setData("value", value);
    console.log(e);
  }

  handleContext(e, item) {
    e.preventDefault();

    this.setState({
      showContext: true,
      mouseX: e.clientX,
      mouseY: e.clientY,
    });
    window.addEventListener("click", this.handleClick.bind(this));
  }

  handleClick(e) {
    this.setState({
      showContext: false,
    });
    window.removeEventListener("click", this.handleClick.bind(this));
  }

  updateProperty(property, value) {
    SET_PROPERTY(this.props.item, property, value);

    this.setState({ update: true });
  }

  colorClicked(e) {
    this.setState({
      colorClicked: !this.state.colorClicked,
    });
  }

  allowDrop(e) {
    e.preventDefault();
  }

  handleMouseDown(e, property, startValue) {
    this.property = property;
    this.mouseDown = true;
    var rect = document.querySelector("body").getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    this.startX = x;

    this.startValue = startValue;
    document.addEventListener("mousemove", this.handleMouseMove.bind(this));
    document.addEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  handleMouseMove(e) {
    if (this.mouseDown) {
      var rect = document.querySelector("body").getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      x = x - this.startX;
      let value = this.startValue + x;

      if (this.property === "style.fontSize") {
        value = Math.max(value, 1);
      }
      this.updateProperty(this.property, value);

      this.updateProperty("text", this.props.item.text);
    }
  }

  handleMouseUp(e) {
    this.mouseDown = false;
    document.removeEventListener("mousemove", this.handleMouseMove.bind(this));
    document.removeEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  handleColorChange(color, event) {
    // this.props.item.material.color = new THREE.Color(
    //   "rgba(" +
    //     color.rgb.r +
    //     "," +
    //     color.rgb.g +
    //     "," +
    //     color.rgb.b +
    //     "," +
    //     color.rgb.a +
    //     ")"
    // );
    this.forceUpdate();
  }

  drop(e, item) {
    if (e.dataTransfer.getData("source")) {
      if (!item.dataBinds) {
        item.dataBinds = [];
      }
      item.dataBinds.push({
        source: e.dataTransfer.getData("source"),
        property: e.dataTransfer.getData("property"),
        itemProperty: "text",
      });
    }
  }

  handleContextUnbind() {
    this.props.item.dataBinds = this.props.item.dataBinds.filter(
      (d) => d.itemProperty !== "text"
    );
    this.props.item.text = "";
    this.forceUpdate();
  }

  upload(e) {
    const files = Array.from(e.target.files);

    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append("file", file);
    });

    let path_id = this.props.app.player.project?.use_project_folder
      ? this.props.app.player.project?._id
      : this.props.app.player.project.user._id;
    let prefix = this.props.app.player.project?.use_project_folder
      ? "project/"
      : "";

    fetch(`${window.projectServerURL}assets/fonts/${prefix}${path_id}`, {
      method: "POST",
      body: formData,
    }).then(() => {
      let font = new FontFace(
        files[0].name,
        "url('" +
          window.projectServerURL +
          "assets/fonts/" +
          prefix +
          path_id +
          "/" +
          files[0].name +
          "')"
      );
      font.load().then((loadedFont) => {
        window.document.fonts.add(loadedFont);
      });
    });
  }

  render() {
    let color = this.props.item.style ? "#" + this.props.item.style._fill : "";

    let text =
      this.props.item.dataBinds &&
      this.props.item.dataBinds.find((bind) => bind.itemProperty === "text")
        ? this.props.item.dataBinds.find((bind) => bind.itemProperty === "text")
            .property
        : this.props.item._text;
    let fonts = window.document.fonts.entries();
    let done = false;
    let font_array = [];

    while (!done) {
      const font = fonts.next();
      if (!font.done) {
        font_array.push(font.value[0]);
        // console.log(font.value[0])
      } else {
        done = font.done;
      }
    }

    return (
      <div className={propertiesStyle.Group}>
        <div
          className={propertiesStyle.GroupTitle}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <div className={propertiesStyle.Open}>
            {this.state.open && <TriangleDown />}
            {!this.state.open && <TriangleRight />}
          </div>
          <div className={propertiesStyle.Title}>Clock</div>
        </div>
        {this.state.open && (
          <div className={textStyles.Group}>
            <div className={textStyles.Property}>
              <Select
                onChange={(event) => {
                  this.setState({ font: event.target.value });
                  this.props.item.style.fontFamily = event.target.value;
                }}
                value={this.props.item.style.fontFamily}
              >
                {font_array.map((font) => {
                  return <option value={font.family}>{font.family}</option>;
                })}
              </Select>
              <div
                className={textStyles.UploadButton}
                onClick={() => document.getElementById("font-input").click()}
              >
                Upload Font
              </div>
              <input
                id="font-input"
                type="file"
                name="font"
                style={{ display: "none" }}
                onChange={(e) => {
                  this.upload(e);
                }}
              />
            </div>
            <div className={textStyles.Property}>
              <label
                className={textStyles.Y}
                draggable={true}
                onDragStart={(event) =>
                  this.drag(
                    event,
                    "style.fontSize",
                    this.props.item.style.fontSize
                  )
                }
              >
                Font Size
              </label>
              <DragInput
                value={this.props.item.style.fontSize}
                onChange={(value) => {
                  this.updateProperty("style.fontSize", parseInt(value));
                  this.updateProperty("text", this.props.item.text);
                }}
              />
            </div>

            <div className={textStyles.Property}>
              <label
                className={textStyles.Y}
                draggable={true}
                onDragStart={(event) =>
                  this.drag(
                    event,
                    "style.wordWrapWidth",
                    this.props.item.style.wordWrapWidth
                  )
                }
              >
                Letter Spacing
              </label>
              <input
                value={this.props.item.style.letterSpacing}
                onMouseDown={(e) => {
                  this.handleMouseDown(
                    e,
                    "style.letterSpacing",
                    this.props.item.style.letterSpacing
                  );
                }}
                onChange={(e) => {
                  this.updateProperty(
                    "style.letterSpacing",
                    e.currentTarget.value
                  );
                  this.updateProperty("text", this.props.item.text);
                }}
              />
            </div>
            <div className={textStyles.lists}>
              <div className={textStyles.Property}>
                <label className={textStyles.Y}>Format</label>
                <input
                  value={this.props.item.format}
                  onChange={(e) => {
                    this.updateProperty("format", e.currentTarget.value);
                  }}
                />
              </div>
            </div>
            <div className={textStyles.lists}>
              <div className={textStyles.Property}>
                <label className={textStyles.Y}>Type</label>
                <Select
                  onChange={(e) => {
                    this.updateProperty("clock_type", e.currentTarget.value);
                  }}
                  value={this.props.item.clock_type}
                >
                  <option value={"TIME_OF_DAY"}>TIME OF DAY</option>;
                  <option value={"COUNTDOWN"}>COUNTDOWN</option>;
                  <option value={"COUNTUP"}>COUNTUP</option>;
                </Select>
              </div>
            </div>

            {(this.props.item.clock_type === "COUNTDOWN" ||
              this.props.item.clock_type === "COUNTUP") && (
              <div className={textStyles.Property}>
                <div className={textStyles.lists}>
                  <div className={textStyles.Property}>
                    <label className={textStyles.Y}>Start Time (Seconds)</label>
                    <DragInput
                      value={this.props.item.count}
                      onChange={(value) => {
                        this.updateProperty("count", value);
                      }}
                    />
                  </div>
                </div>
                <div
                  className={textStyles.UploadButton}
                  onClick={() => {
                    if (this.props.item.clock_type === "COUNTDOWN") {
                      this.props.item.start(this.props.item.count);
                    } else {
                      this.props.item.start();
                    }
                  }}
                >
                  Start
                </div>
                <div
                  className={textStyles.UploadButton}
                  onClick={() => {
                    this.props.item.stop();
                  }}
                >
                  Stop
                </div>
                <div
                  className={textStyles.UploadButton}
                  onClick={() => {
                    if (this.props.item.clock_type === "COUNTDOWN") {
                      this.props.item.reset(this.props.item.count);
                    } else {
                      this.props.item.reset();
                    }
                  }}
                >
                  Reset
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
