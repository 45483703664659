import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

const Property = styled.input`
  border: 1px solid #232531 !important;
  padding-left: 0.3em !important;
  font-size: 0.9em !important;
  background-color: #12161d !important;
  border-radius: 3px !important;
  cursor: ew-resize;
`;

function DragInput({
  value: inValue,
  onChange,
  step: inStep,
  modifiers: _modifiers = {},
  min,
  max,
  defaultValue,
  onInput,
}) {
  const [value, setValue] = useState(inValue || 0);
  const [modifier, setModifier] = useState();
  const startValue = useRef(0);
  const inputRef = useRef();
  const step = inStep ? +inStep : 1;
  const modifiers = useMemo(
    () => ({
      shiftKey: 0.1,
      ..._modifiers,
    }),
    [_modifiers]
  );

  const [, setStartPos] = useState(0);

  const handleChange = (e) => {
    const newValue = e.target.value;

    if (isNaN(+newValue)) {
      return;
    }

    setValue(+newValue);

    onChange?.(+newValue, inputRef.current);
  };

  const handleDragEnd = debounce((newValue) => {
    onChange?.(newValue, inputRef.current);
  }, 1);

  const handleInput = useCallback(
    (newValue) => {
      requestAnimationFrame(() => {
        onInput?.(newValue, inputRef.current);
      });
      handleDragEnd(newValue);
    },
    [handleDragEnd, onInput]
  );

  const handleMove = useCallback(
    (e) => {
      e.preventDefault();
      setStartPos((pos) => {
        const { clientX: x2, clientY: y2 } = e;
        const [x1, y1] = pos;

        const a = x1 - x2;
        const b = y1 - y2;

        let mod = 1;

        if (modifier) {
          mod = modifiers[modifier] || 1;
        }

        const stepModifer = step * mod;
        const decimals = countDecimals(stepModifer);

        let delta = Math.sqrt(a * a + b * b) * stepModifer;
        if (x2 < x1) delta = -delta;

        let newValue = startValue.current + delta;

        if (min) newValue = Math.max(newValue, +min);
        if (max) newValue = Math.min(newValue, +max);

        newValue = +newValue.toFixed(decimals);

        setValue(newValue);
        handleInput(newValue);

        return pos;
      });
    },
    [modifier, max, min, step, handleInput, modifiers]
  );

  const handleMoveEnd = useCallback(() => {
    document.removeEventListener("mousemove", handleMove);
    document.removeEventListener("mouseup", handleMoveEnd);
  }, [handleMove]);

  const handleDown = useCallback(
    (e) => {
      let _startValue = +value;

      if (isNaN(_startValue)) {
        _startValue = +(defaultValue || min || 0);
      }

      startValue.current = _startValue;

      setStartPos([e.clientX, e.clientY]);

      document.addEventListener("mousemove", handleMove);
      document.addEventListener("mouseup", handleMoveEnd);
    },
    [handleMove, handleMoveEnd, value, min, defaultValue]
  );

  const handleKeyDown = (e) => {
    if (e.metaKey) {
      setModifier("metaKey");
    } else if (e.ctrlKey) {
      setModifier("ctrlKey");
    } else if (e.altKey) {
      setModifier("altKey");
    } else if (e.shiftKey) {
      setModifier("shiftKey");
    }
  };
  const handleKeyUp = () => {
    setModifier("");
  };

  useEffect(() => {
    if (inValue !== value && typeof inValue === "number") setValue(inValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inValue]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handleMoveEnd);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Property
      value={value}
      onMouseDown={handleDown}
      onChange={handleChange}
      ref={inputRef}
    />
  );
}

export default DragInput;

/**
 * Based on GIST: https://gist.github.com/fr-ser/ded7690b245223094cd876069456ed6c
 * by Sergej Herbert https://gist.github.com/fr-ser
 */

/**
 * Debounce
 * @param func Function to debounce
 * @param wait Millisecond to wait before firing
 * @returns
 */
function debounce(func, wait) {
  let timeoutID;

  if (!Number.isInteger(wait)) {
    console.warn("Called debounce without a valid number");
    wait = 300;
  }

  // conversion through any necessary as it wont satisfy criteria otherwise
  return (...args) => {
    clearTimeout(timeoutID);
    const context = this;

    timeoutID = window.setTimeout(function () {
      func.apply(context, args);
    }, wait);
  };
}

function countDecimals(value) {
  if (Math.floor(value) === value) return 0;

  const valueAsString = value.toString();
  return (
    valueAsString.split(".")[1].length ||
    valueAsString.split(",")[1].length ||
    0
  );
}
