import React, { Component } from "react";
import propertiesStyle from "./Properties.module.css";
import styles from "./AutoFollow.module.css";
import TriangleDown from "../../SVG/TriangleDown";
import TriangleRight from "../../SVG/TriangleRight";
import { setProperty as SET_PROPERTY } from "../../Scripts/PropertyUtils";
import DragInput from "../DragInput/DragInput";

export default class AutoFollow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorClicked: false,
      open: false,
    };
  }

  updateProperty(property, value) {
    SET_PROPERTY(this.props.item, property, value);
    this.setState({ update: true });
  }

  colorClicked(e) {
    this.setState({
      colorClicked: !this.state.colorClicked,
    });
  }

  handleMouseDown(e, property, startValue) {
    this.property = property;
    this.mouseDown = true;
    var rect = document.querySelector("body").getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    this.startX = x;

    this.startValue = startValue;
    document.addEventListener("mousemove", this.handleMouseMove.bind(this));
    document.addEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  handleMouseMove(e) {
    if (this.mouseDown) {
      var rect = document.querySelector("body").getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      x = x - this.startX;

      this.updateProperty(this.property, this.startValue + x);
    }
  }

  handleMouseUp(e) {
    this.mouseDown = false;
    document.removeEventListener("mousemove", this.handleMouseMove.bind(this));
    document.removeEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  applyAutoFollow(item) {
    if (this.props.item.autoFollow) {
      this.props.item.autoFollow = null;
      return;
    }
    if (!this.props.item.autoFollowPadding) {
      this.props.item.autoFollowPadding = 50;
    }
    this.props.item.autoFollow = item;
    try {
      let func = new Function(
        "hyperAPI",
        "currentItem",
        `
          let window = '';
   
           function update() {
         
            if (currentItem.autoFollow) {
             if (currentItem.autoFollow.type === 'TEXT') {
               if (currentItem.autoFollow.text !== '' && currentItem.autoFollow.visible === true) {
                hyperAPI.setProperty(currentItem, 'position.x', parseInt(currentItem.autoFollow.position.x) + parseInt(currentItem.autoFollow._width) + parseInt(currentItem.autoFollowPadding))
               }else {
                hyperAPI.setProperty(currentItem, 'position.x', parseInt(currentItem.autoFollow.position.x) + parseInt(currentItem.autoFollow._width))
               }
               
             }  else  {
               hyperAPI.setProperty(currentItem, 'position.x', parseInt(currentItem.autoFollow.position.x) + parseInt(currentItem.autoFollow.width) + parseInt(currentItem.autoFollowPadding))
            } 
          }
           }
            return {
              update: update
            };
          `
      )(window.hyperAPI, this.props.item);

      this.props.item.autofollowCode = func;
    } catch (err) {}
  }

  renderChild(children) {
    return children.map((item) => {
      return (
        <div>
          <div
            onClick={() => this.applyAutoFollow(item)}
            className={`${styles.Item} ${
              item === this.props.item.autoFollow ? styles.Active : ""
            }`}
          >
            {item.name}
          </div>
          {this.renderChild(item.children)}
        </div>
      );
    });
  }

  render() {
    return (
      <div className={propertiesStyle.Group}>
        <div
          className={propertiesStyle.GroupTitle}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <div className={propertiesStyle.Open}>
            {this.state.open && <TriangleDown />}
            {!this.state.open && <TriangleRight />}
          </div>
          <div className={propertiesStyle.Title}>Auto Follow</div>
        </div>
        {this.state.open && (
          <div className={styles.Group}>
            <div className={styles.Property}>
              <label
                className={styles.Y}
                draggable={true}
                onDragStart={(event) =>
                  this.drag(
                    event,
                    "autoFollowPadding",
                    this.props.item.autoFollowPadding
                  )
                }
              >
                Padding
              </label>
              <DragInput
                value={this.props.item.autoFollowPadding}
                onChange={(value) => {
                  this.updateProperty("autoFollowPadding", value);
                }}
              />
            </div>
            <div className={styles.lists}>
              <div className={styles.Property}>
                {this.renderChild(this.props.scene.children)}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
