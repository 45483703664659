import React, { Component, useContext, useEffect, useState } from "react";

import "./App.css";
import Sidebar from "./Components/Sidebar/Sidebar";
import Canvas from "./Components/Canvas/Canvas";

import Properties from "./Components/Properties/Properties";
import HyperAPI from "./Scripts/HyperAPI";
import Objects from "./Components/Objects/Objects";
import ImageViewer from "./Components/NewImageViewer/ImageViewer";
import Timeline from "./Components/Timeline/Timeline";
import Config from "./Components/Config/Config";
import ProjectViewer from "./Components/ProjectViewer/ProjectViewer";
import Project from "./Scripts/PIXI/Project";
import PIXIPlayer from "./Scripts/PIXI/PIXI";
import VideoViewer from "./Components/VideoViewer/VideoViewer";
import Commander from "./Scripts/Commander/Commander";

import SequenceViewer from "./Components/SequenceViewer/SequenceViewer";
import PlayCanvasPlayer from "./Scripts/PlayCanvas/playCanvas";
import TopBar from "./Components/TopBar/TopBar";
import KeyProperties from "./Components/KeyProperties/KeyProperties";
import CodeEditor from "./Components/CodeEditor/CodeEditor";
import Components from "./Components/Components/Components";
import TimelineProperties from "./Components/TimelineProperties/TimelineProperties";
import DataView from "./Components/DataView/DataView";
import ControlEditor from "./Components/ControlEditor/ControlEditor";
import TagEditor from "./Components/TagEditor/TagEditor";
import { UserContext, UserProvider } from "./Contexts/UserContext";
import ExportViewer from "./Components/ExportViewer/ExportViewer";
import { getApolloContext, gql } from "@apollo/client";
import Data from "./Components/Properties/Data";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SceneProperties from "./Components/Properties/SceneProperties";
import Player from "./Components/Player/Player";
import PlayerStatic from "./Components/Player/PlayerManCity";
import PlayerManCity from "./Components/Player/PlayerManCity";
import ProjectProperties from "./Components/Properties/ProjectProperties";
import Errors from "./Components/Errors/Errors";
import { MonitorProvider } from "./Contexts/MonitorContext";

const PROJECTS = gql`
  query Projects($projectsByUserUserId: String!) {
    projectsByUser(userId: $projectsByUserUserId) {
      name
      colour
      _id
      user {
        _id
      }
    }
  }
`;

const PROJECTS_BY_ID = gql`
  query ProjectsById($id: String!) {
    projectsById(id: $id) {
      name
      colour
      use_project_folder
      engine_version
      last_updated
      user {
        _id
        license_type
      }
      _id
    }
  }
`;

const GET_ENGINES = gql`
  query GetEngines {
    enginesForUser {
      name
      _id
      key
    }
  }
`;

export default function App() {
  const [state, setState] = useState(false);
  const [player, setPlayer] = useState();
  const [engine, setEngine] = useState();
  const [version, setVersion] = useState();
  const [projectName, setProjectName] = useState();
  const [preview, setPreview] = useState();
  const [renderPVW, setRenderPVW] = useState();
  const [groupID, setGroupID] = useState();
  const [canOutput, setCanOutput] = useState();
  const [update, setUpdate] = useState();
  const [engines, setEngines] = useState([]);
  const [projects, setProjects] = useState([]);
  const [errors, setErrors] = useState([]);
  const apolloClient = useContext(getApolloContext());
  const { user } = useContext(UserContext);
  window.monitor_server =
    getParameterByName("mnonitor_server") ||
    window.ENV?.REACT_APP_MONITOR_SERVER ||
    process.env.REACT_APP_MONITOR_SERVER;
  useEffect(() => {
    let func = (e) => {
      setErrors([
        ...(errors || []),
        {
          error:
            "Failed to load image " + e.detail.error.currentTarget.currentSrc,
          scene: e.detail.scene,
          node: e.detail.node,
        },
      ]);
    };
    document.addEventListener("ImageError", func);
    return () => {
      document.removeEventListener("ImageError", func);
    };
  }, [errors]);
  useEffect(() => {
    start();
    setInterval(() => {
      setUpdate(Date.now());
    }, 1000);
    let input1 = document.getElementById("input_1");
    // Getting the WebGL rendering context.
    let ctx = input1.getContext("2d");
    ctx.rect(0, 0, input1.width, input1.height);
    ctx.fillStyle = "green";
    ctx.fill();
    // window.input_1_ctx.viewport(
    //   0,
    //   0,
    //   window.input_1_ctx.drawingBufferWidth,
    //   window.input_1_ctx.drawingBufferHeight
    // );
    // window.input_1_ctx.clearColor(
    //   (1 / 100) * 19.6,
    //   (1 / 100) * 80.4,
    //   (1 / 100) * 19.6,
    //   1.0
    // );
    // window.input_1_ctx.clear(window.input_1_ctx.COLOR_BUFFER_BIT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (projectName && player) {
      apolloClient.client
        .query({
          query: PROJECTS_BY_ID,
          variables: { id: projectName },
        })
        .then((response) => {
          let data = response.data.projectsById;
          let proj = data.find((p) => p._id === projectName);
          setProjects(data);
          selectProject(proj);
        })
        .catch((err) => console.error(err));
      apolloClient.client
        .query({
          query: GET_ENGINES,
        })
        .then((response) => {
          setEngines(
            response.data.enginesForUser.map((e) => ({
              ...e,
              status: "connected",
            }))
          );
        })
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectName, player]);

  async function start() {
    window.addEventListener("message", (event) => {
      try {
        let data = JSON.parse(event?.data);
        if (data?.event === "snapshot") {
          // Get the canvas
          window.snapshot = data.data?.name;
        }
      } catch (err) {}
    });
    window.render_preview = true;
    setVersion("2020.1.7");

    var pathname = window.location.pathname
      ?.replace(process.env.PUBLIC_URL, "")
      ?.split("/");

    let isEngine =
      getParameterByName("engine") === "true" ||
      pathname[1]?.toLowerCase() === "preview" ||
      pathname[1]?.toLowerCase() === "live"
        ? true
        : false;
    setEngine(isEngine);

    if (!isEngine) {
      document.querySelector("body").style.backgroundColor = "#0e1219";
    } else {
      document.querySelector("body").style.backgroundColor = "rgba(0,0,0,0)";
    }

    let project_name = !isEngine ? pathname[1] : pathname[3];

    if (project_name !== "player") {
      setProjectName(project_name);
    }
    window.query_params = new URLSearchParams(window.location.search);
    let preview =
      getParameterByName("preview") === "true" ||
      pathname[1]?.toLowerCase() === "preview"
        ? true
        : false;
    setPreview(preview);
    setRenderPVW(getParameterByName("renderPVW") == "true" ? true : false);
    setGroupID(getParameterByName("groupID") || "");

    window.width =
      getParameterByName("width") || window.engine_output_size?.width || 1920;
    window.height =
      getParameterByName("height") || window.engine_output_size?.height || 1080;
    window.showFPS = getParameterByName("showFPS") == "true" ? true : false;
    window.useRAF = getParameterByName("useRAF") == "false" ? false : true;
    window.useAntiAlias =
      getParameterByName("useAntiAlias") == "false" ? false : true;

    window.preview = preview;
    window.editor = !isEngine;
    window.renderPVW = renderPVW;
    window.groupID = getParameterByName("groupID") || "";

    window.commandServerURL =
      getParameterByName("command_server") ||
      window.ENV?.REACT_APP_COMMAND_SERVER ||
      process.env.REACT_APP_COMMAND_SERVER;

    window.projectServerURL =
      (window.ENV?.REACT_APP_PROJECT_SERVER ||
        process.env.REACT_APP_PROJECT_SERVER) + "/api/";

    // window.projectServerURL = "http://design.hyperstudios.tv:6060/"
    window.name = getParameterByName("name");
    // window.commandServerURL = "ws://localhost:6002";
    // engine = true;
    // this.projectName = "EFL";

    setCanOutput(window.process !== undefined);

    // TURNED OFF WHILE DEV
    // document.oncontextmenu = function() {
    //   return false;
    // };

    let player = new PIXIPlayer(this);

    setPlayer(player);

    // player = new PlayCanvasPlayer(this);

    window.ws = new Commander({ ...this, player, preview: preview });

    if (
      pathname[1]?.toLowerCase() === "live" ||
      pathname[1]?.toLowerCase() === "preview"
    ) {
      window.ws.connect(pathname[2]);
      window.engine_key = pathname[2];
    }

    // if (window.process) {
    //   this.orca = new Commander(this, "ws://grabyo.hyperstudios.co.uk:6003");
    //   window.orca = this.orca;
    // }

    //this.sceneView = false;

    setState({
      ...state,
      added: false,
      selectedItem: null,
      imageView: false,
      videoView: false,
      configView: false,
      projectView: false,
      exportView: false,
      dataView: false,
      loading: false,
      loaded: "0%",
      propertyPanel: 0,
      sidebarView: 0,
    });

    window.addEventListener("keydown", keydown.bind(this));
    // setTimeout(() => {
    //   player.selectedScene = player.project.scenes[0];
    //   player.project.scenes[0].scene.add(player.transformControl);
    //   this.selectItem(player.project.scenes[0].scene.children[0]);
    //   this.forceUpdate();
    // }, 200);
  }

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  function keydown(e) {
    if (e.ctrlKey && e.keyCode === 82) {
      player.stage.scale.x = 1;
      player.stage.scale.y = 1;
      // player.camera.position.x = 0;
      // player.camera.position.y = 0;
      // player.camera.position.z = 7;
      // player.camera.rotation.x = 0;
      // player.camera.rotation.y = 0;
      // player.camera.rotation.z = 0;
    } else if (e.ctrlKey && e.keyCode === 32) {
      player.selectedScene.activeTimeline.togglePlay();
    }
  }

  function added(item, cb) {
    if (item.type === "IMAGE") {
      setState({
        ...state,
        added: true,
        imageView: true,
        videoView: false,
        imageCallback: cb,
        selectedItem: item,
        selectedKeyframe: null,
      });
    } else if (item.type === "VIDEO") {
      setState({
        ...state,
        added: true,
        imageView: false,
        videoView: true,
        videoCallback: cb,
        selectedItem: item,
        selectedKeyframe: null,
      });
    } else {
      setState({
        ...state,
        added: true,
        selectedItem: item,
        selectedKeyframe: null,
      });
    }
  }

  function selectItem(item) {
    setState({
      ...state,
      selectedItem: item,
      selectedKeyframe: null,
    });
    // player.transformControl.attach(item);
  }

  function selectScene(scene) {
    player.selectedScene = scene;
    // player.renderer.resize(scene.width, scene.height);
    //document.querySelector("canvas").style.width = scene.width / 2 + "px";
    //document.querySelector("canvas").style.height = scene.height / 2 + "px";
    player.selectedScene.activeTimeline = scene.timelines[0];
    switch (scene.aspect) {
      case "1x1":
        player.renderer.resize(1080, 1080);
        document.querySelector("canvas").style.width = 1080 / 2 + "px";
        document.querySelector("canvas").style.height = 1080 / 2 + "px";
        break;
      case "9x16":
        player.renderer.resize(1080, 1920);
        document.querySelector("canvas").style.width = 1080 / 3.5 + "px";
        document.querySelector("canvas").style.height = 1920 / 3.5 + "px";
        break;
      case "16x9":
      default:
        player.renderer.resize(
          scene.canvas_width || window.engine_output_size?.width || 1920,
          scene.canvas_height || window.engine_output_size?.height || 1080
        );
        document.querySelector("canvas").style.width =
          (scene.canvas_width || window.engine_output_size?.width || 1920) / 2 +
          "px";
        document.querySelector("canvas").style.height =
          (scene.canvas_height || window.engine_output_size?.height || 1080) /
            2 +
          "px";
        break;
    }

    setUpdate(Date.now());
  }

  function selectTimeline(timeline) {
    player.selectedScene.activeTimeline = timeline;
    setUpdate(Date.now());
  }

  function toggleImageView(cb) {
    setState({
      ...state,
      imageView: !state.imageView,
      videoView: false,
      imageCallback: cb,
    });
  }

  function toggleVideoView(cb) {
    setState({
      ...state,
      imageView: false,
      videoView: !state.videoView,
      videoCallback: cb,
    });
  }

  function selectEditView() {
    setState({
      ...state,
      imageView: false,
      configView: false,
      projectView: false,
      exportView: false,
    });
  }

  function selectConfigView() {
    setState({
      ...state,
      imageView: false,
      configView: true,
      exportView: false,
    });
  }

  function selectProjectView() {
    setState({
      ...state,
      imageView: false,
      configView: false,
      projectView: true,
      exportView: false,
      loading: false,
    });
  }

  function selectExportView() {
    setState({
      ...state,
      imageView: false,
      configView: false,
      projectView: false,
      exportView: true,
      loading: false,
    });
  }

  function selectDataView() {
    setState({
      ...state,
      imageView: false,
      configView: false,
      projectView: false,
      dataView: true,
      loading: false,
    });
  }

  function selectProject(project) {
    let newState = state;
    if (!newState) {
      newState = {
        added: false,
        selectedItem: null,
        imageView: false,
        videoView: false,
        configView: false,
        projectView: true,
        exportView: false,
        dataView: false,
        loading: false,
        loaded: "0%",
        propertyPanel: 0,
        sidebarView: 0,
      };
    } else {
      newState = state;
    }

    let p = new Project(
      project.name,
      player,
      project.assets,
      apolloClient,
      project._id,
      project.user,
      project.use_project_folder,
      project.engine_version || "0.0.0"
    );

    window.engines = engines;
    window.project_name = project.name;
    window.project_last_updated = project.last_updated;
    window.hyperAPI = HyperAPI(p, player);
    p.loadDatasources(project.dataSources || []);
    p.backgroundColor = project.backgroundColor;
    p.loader.reset();
    // p.loader.onProgress.add(() => {
    //   p.loadedAssets++;

    //   // this.ws.send({
    //   //   status: parseInt((p.loadedAssets / p.assets.length) * 100) + "%",
    //   //   engine: this.version,
    //   //   name: window.groupID,
    //   //   type: this.preview ? "PREVIEW" : "OUTPUT",
    //   // });
    //   setState({
    //     ...newState,
    //     loaded: "100%",
    //   });
    // }); // called once per loaded/errored file

    p.loadProject(project.user || user, () => {
      // player.project = p;

      if (player.project.scenes.length > 0) {
        //player.selectedScene = player.project.scenes[0];

        // player.selectedScene.activeTimeline =
        //   player.project.scenes[0].timelines[0];
        // player.project.scenes[0].scene.add(player.transformControl);
        selectItem(player.project.scenes[0].scene.children[0]);
      }

      // player.updateEffects();
      // player.outputEffects();

      setState({ ...newState, projectView: false, loading: false });
      if (engine === false) {
        // this.forceUpdate();
      }
    });

    setState({
      ...newState,
      projectView: true,
    });
  }
  let isthis =
    state?.imageView === true ||
    state?.videoView === true ||
    state?.configView === true ||
    player?.project === null;

  let stageClass = isthis ? "Hidden" : "";
  let imageViewerClass = state.imageView === false ? "Hidden" : "";
  let videoViewerClass = state.videoView === false ? "Hidden" : "";
  let configClass = state.configView === false ? "Hidden" : "";
  let mainClass =
    state.configView === true ||
    state.projectView === true ||
    state.exportView === true
      ? "Hidden"
      : "";
  let projectViewerClass =
    state.projectView === false && player?.project !== null ? "Hidden" : "";

  let exportViewerClass = state.exportView === false ? "Hidden" : "";

  let public_url = process.env.PUBLIC_URL;

  return (
    <MonitorProvider>
      <Router basename={public_url}>
        <Switch>
          <Route path={"/player_man_city"}>
            <PlayerManCity />
          </Route>
          <Route path={"/player"}>
            <Player />
          </Route>
          <Route path="/">
            <div className="App">
              {engine === false && player && (
                <TopBar app={{ player }} selectExportView={selectExportView} />
              )}

              {engine === false && state.loading && (
                <div className={"Loading"}>
                  LOADING {state.loaded}
                  <div className={"Bar"}>
                    <div
                      id="progress"
                      className={"Progress"}
                      style={{ width: state.loaded }}
                    />
                  </div>
                </div>
              )}
              {engine && player && <Canvas player={player} engine={engine} />}
              {engine === false && state.loading === false && (
                <div>
                  <div className={configClass}>
                    <Config selectEditView={() => selectEditView()} />
                  </div>
                  <div className={exportViewerClass}>
                    <div className={exportViewerClass}>
                      <ExportViewer
                        app={{ player }}
                        selectEditView={selectEditView}
                      />
                    </div>
                  </div>
                  <div className={projectViewerClass}>
                    <div className={projectViewerClass}>
                      <ProjectViewer
                        selectEditView={() => selectEditView()}
                        callback={(project) => {
                          selectProject(project);
                          setState({
                            ...state,
                            projectView: false,
                          });
                        }}
                      />
                    </div>
                  </div>
                  {player.project && (
                    <div className={`MainApp ${mainClass}`}>
                      <Objects
                        player={player}
                        added={(item, cb) => added(item, cb)}
                        selectConfigView={() => selectConfigView()}
                      />
                      <Sidebar
                        app={{ player, state }}
                        selectItem={(item) => selectItem(item)}
                        selectScene={(scene) => selectScene(scene)}
                        selectedItem={state.selectedItem}
                        selectProjectView={() => selectProjectView()}
                        state={state}
                        setState={setState}
                        refresh={() => {
                          //this.forceUpdate();
                        }}
                      />

                      {(user?.properties?.props_side === "left" ||
                        user?.username === "sam@hyperstudios.co.uk" ||
                        user?.username === "fifa") &&
                        !state.selectedKeyframe && (
                          <div style={{ marginRight: "2px" }}>
                            <div className="PropertyMenu">
                              {state.sidebarView === 0 && (
                                <div
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      propertyPanel: 0,
                                    });
                                  }}
                                >
                                  Scene Properties
                                </div>
                              )}
                              {state.sidebarView === 0 && (
                                <div
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      propertyPanel: 6,
                                    });
                                  }}
                                >
                                  Project Properties
                                </div>
                              )}
                              {state.sidebarView > 0 && (
                                <>
                                  <div
                                    onClick={() => {
                                      setState({
                                        ...state,
                                        propertyPanel: 0,
                                      });
                                    }}
                                  >
                                    Properties
                                  </div>
                                  <div
                                    onClick={() => {
                                      setState({
                                        ...state,
                                        propertyPanel: 1,
                                      });
                                    }}
                                  >
                                    Timeline
                                  </div>
                                  <div
                                    onClick={() => {
                                      setState({
                                        ...state,
                                        propertyPanel: 2,
                                      });
                                    }}
                                  >
                                    Code
                                  </div>
                                  <div
                                    onClick={() => {
                                      setState({
                                        ...state,
                                        propertyPanel: 3,
                                      });
                                    }}
                                  >
                                    Control
                                  </div>
                                  <div
                                    onClick={() => {
                                      setState({
                                        ...state,
                                        propertyPanel: 4,
                                      });
                                    }}
                                  >
                                    Folder
                                  </div>
                                  <div
                                    onClick={() => {
                                      setState({
                                        ...state,
                                        propertyPanel: 5,
                                      });
                                    }}
                                  >
                                    Data
                                  </div>
                                </>
                              )}
                            </div>
                            {state.propertyPanel === 0 &&
                              state.sidebarView === 0 && (
                                <SceneProperties
                                  state={state}
                                  project={player.project}
                                  scene={player.selectedScene}
                                  player={player}
                                />
                              )}
                            {state.propertyPanel === 6 &&
                              state.sidebarView === 0 && (
                                <ProjectProperties
                                  state={state}
                                  project={player.project}
                                  scene={player.selectedScene}
                                />
                              )}
                            {state.propertyPanel === 0 &&
                              state.sidebarView > 0 && (
                                <Properties
                                  state={state}
                                  project={player.project}
                                  item={state.selectedItem}
                                  scene={
                                    player.selectedScene
                                      ? player.selectedScene.scene
                                      : null
                                  }
                                  toggleImageView={(cb) => toggleImageView(cb)}
                                  toggleVideoView={(cb) => toggleVideoView(cb)}
                                  addComponent={() => {
                                    setState({
                                      ...state,
                                      animationView: false,
                                      componentView: true,
                                    });
                                  }}
                                />
                              )}
                            {state.propertyPanel === 1 && (
                              <TimelineProperties
                                timelines={player.selectedScene.timelines}
                                timeline={player.selectedScene.activeTimeline}
                                scene={player.selectedScene}
                              />
                            )}
                            {state.propertyPanel === 2 && (
                              <CodeEditor item={state.selectedItem} />
                            )}
                            {state.propertyPanel === 3 && (
                              <ControlEditor scene={player.selectedScene} />
                            )}
                            {state.propertyPanel === 4 && (
                              <TagEditor
                                scene={player.selectedScene}
                                app={{ setState, state }}
                              />
                            )}
                            {state.propertyPanel === 5 && (
                              <Data
                                scene={player.selectedScene}
                                app={{ setState, state }}
                              />
                            )}
                          </div>
                        )}
                      {(user?.properties?.props_side === "left" ||
                        user?.username === "sam@hyperstudios.co.uk" ||
                        user?.username === "fifa") &&
                        state.selectedKeyframe && (
                          <KeyProperties
                            keyframe={state.selectedKeyframe}
                            app={{ setState, state }}
                          ></KeyProperties>
                        )}

                      <div className="Centre">
                        <div className={stageClass}>
                          {player && (
                            <Canvas
                              player={player}
                              engine={engine}
                              engines={engines}
                              dataView={state.dataView}
                            />
                          )}

                          {state.componentView !== true &&
                            state.dataView !== true && (
                              <Timeline
                                showErrors={state.showErrors}
                                scene={
                                  player.selectedScene
                                    ? player.selectedScene.scene
                                    : null
                                }
                                app={{ player, state, setState }}
                                errors={errors}
                                selectItem={(item) => selectItem(item)}
                                selectedItem={state.selectedItem}
                                selectTimeline={(timeline) => {
                                  setState({ ...state, showErrors: false });
                                  selectTimeline(timeline);
                                }}
                                onShowErrors={() => {
                                  setState({ ...state, showErrors: true });
                                }}
                              />
                            )}
                          {state.showErrors && <Errors errors={errors} />}
                          {state.dataView && (
                            <DataView project={player.project} />
                          )}
                          {/* {state.componentView && <Components />} */}
                          {state.componentView && (
                            <CodeEditor item={state.selectedItem} />
                          )}
                        </div>

                        <div
                          className={imageViewerClass}
                          style={{ height: "100%", overflow: "hidden" }}
                        >
                          {player.project && (
                            <ImageViewer
                              style={{
                                display: state.imageView ? "block" : "none",
                                zIndex: state.imageView ? "100" : "-100",
                              }}
                              dnd={!state.imageView}
                              project={player.project}
                              callback={(image) => {
                                state.imageCallback(image);

                                setState({
                                  ...state,
                                  imageView: false,
                                });
                              }}
                            />
                          )}
                        </div>
                        <div className={videoViewerClass}>
                          {player.project && (
                            <VideoViewer
                              style={{
                                display: state.videoView ? "block" : "none",
                              }}
                              project={player.project}
                              callback={(video) => {
                                state.videoCallback(video);
                                setState({
                                  ...state,
                                  videoView: false,
                                });
                              }}
                            />
                          )}

                          {/* {player.project && (
                    <SequenceViewer
                      project={player.project}
                      callback={(video) => {
                        state.sequenceCallback(video);
                        this.setState({
                          videoView: false,
                        });
                      }}
                    />
                  )} */}
                        </div>
                      </div>
                      {((user?.username !== "sam@hyperstudios.co.uk" &&
                        user?.username !== "fifa") ||
                        user?.properties?.props_side === "right") &&
                        !state.selectedKeyframe && (
                          <div>
                            <div className="PropertyMenu">
                              {state.sidebarView === 0 && (
                                <div
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      propertyPanel: 0,
                                    });
                                  }}
                                >
                                  Scene Properties
                                </div>
                              )}
                              {state.sidebarView === 0 && (
                                <div
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      propertyPanel: 6,
                                    });
                                  }}
                                >
                                  Project Properties
                                </div>
                              )}
                              {state.sidebarView > 0 && (
                                <>
                                  <div
                                    onClick={() => {
                                      setState({
                                        ...state,
                                        propertyPanel: 0,
                                      });
                                    }}
                                  >
                                    Properties
                                  </div>
                                  <div
                                    onClick={() => {
                                      setState({
                                        ...state,
                                        propertyPanel: 1,
                                      });
                                    }}
                                  >
                                    Timeline
                                  </div>
                                  <div
                                    onClick={() => {
                                      setState({
                                        ...state,
                                        propertyPanel: 2,
                                      });
                                    }}
                                  >
                                    Code
                                  </div>
                                  <div
                                    onClick={() => {
                                      setState({
                                        ...state,
                                        propertyPanel: 3,
                                      });
                                    }}
                                  >
                                    Control
                                  </div>
                                  <div
                                    onClick={() => {
                                      setState({
                                        ...state,
                                        propertyPanel: 4,
                                      });
                                    }}
                                  >
                                    Folder
                                  </div>
                                  <div
                                    onClick={() => {
                                      setState({
                                        ...state,
                                        propertyPanel: 5,
                                      });
                                    }}
                                  >
                                    Data
                                  </div>
                                </>
                              )}
                            </div>
                            {state.propertyPanel === 0 &&
                              state.sidebarView === 0 && (
                                <SceneProperties
                                  state={state}
                                  project={player.project}
                                  scene={player.selectedScene}
                                  player={player}
                                />
                              )}
                            {state.propertyPanel === 6 &&
                              state.sidebarView === 0 && (
                                <ProjectProperties
                                  state={state}
                                  project={player.project}
                                  scene={player.selectedScene}
                                />
                              )}
                            {state.propertyPanel === 0 &&
                              state.sidebarView > 0 && (
                                <Properties
                                  state={state}
                                  project={player.project}
                                  item={state.selectedItem}
                                  scene={
                                    player.selectedScene
                                      ? player.selectedScene.scene
                                      : null
                                  }
                                  toggleImageView={(cb) => toggleImageView(cb)}
                                  toggleVideoView={(cb) => toggleVideoView(cb)}
                                  addComponent={() => {
                                    setState({
                                      ...state,
                                      animationView: false,
                                      componentView: true,
                                    });
                                  }}
                                />
                              )}
                            {state.propertyPanel === 1 && (
                              <TimelineProperties
                                timelines={player.selectedScene.timelines}
                                timeline={player.selectedScene.activeTimeline}
                                scene={player.selectedScene}
                              />
                            )}
                            {state.propertyPanel === 2 && (
                              <div class="CodeEditor">
                                <CodeEditor item={state.selectedItem} />
                              </div>
                            )}
                            {state.propertyPanel === 3 && (
                              <ControlEditor scene={player.selectedScene} />
                            )}
                            {state.propertyPanel === 4 && (
                              <TagEditor
                                scene={player.selectedScene}
                                app={{ setState, state }}
                              />
                            )}
                            {state.propertyPanel === 5 && (
                              <Data
                                scene={player.selectedScene}
                                app={{ setState, state }}
                              />
                            )}
                          </div>
                        )}
                      {((user?.username !== "sam@hyperstudios.co.uk" &&
                        user?.username !== "fifa") ||
                        user?.properties?.props_side === "right") &&
                        state.selectedKeyframe && (
                          <KeyProperties
                            keyframe={state.selectedKeyframe}
                            app={{ setState, state }}
                          ></KeyProperties>
                        )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </Route>
        </Switch>
        <canvas
          id="input_1"
          height="1080"
          width="1920"
          style={{ display: "none" }}
        />
      </Router>
    </MonitorProvider>
  );
}
