import React, { Component } from "react";
import styles from "./Properties.module.css";
import Material from "./Material";
import Text from "./Text";
import TextStroke from "./TextStroke";
import Transform from "./Tranform";
import Mask from "./Mask";
import AutoFollow from "./AutoFollow";
import Layout from "./Layout";
import Code from "./Code";
import Control from "./Control";
import Clock from "./Clock";
import SizeToFit from "./SizeToFit";
import DropShadow from "./DropShadow";
import Gradient from "./Gradient";

export default class Properties extends Component {
  constructor(props) {
    super(props);
    this.update();
  }
  updateProperty(property, value) {
    if (isNaN(value) === false) {
      this.setState({ [property]: value });
      this.props.item.position[property] = value;
    }
  }

  drag(e, property, value) {
    e.dataTransfer.setData("property", property);
    e.dataTransfer.setData("value", value);
    console.log(e);
  }

  update() {
    requestAnimationFrame(() => this.update());
    this.forceUpdate();
  }

  render() {
    if (this.props.item === null || this.props.item === undefined) {
      return <div />;
    }

    return (
      <div className={styles.Properties}>
        <div className={styles.ItemName}>{this.props.item.name}</div>
        <Transform item={this.props.item} />
        <Material
          project={this.props.project}
          item={this.props.item}
          toggleImageView={this.props.toggleImageView}
          toggleVideoView={this.props.toggleVideoView}
        />
        {this.props.item.type === "TEXT" && (
          <Text item={this.props.item} project={this.props.project} />
        )}
        {this.props.item.type === "TEXT" && (
          <TextStroke item={this.props.item} />
        )}
        {this.props.item.type === "CLOCK" && <Clock item={this.props.item} />}
        <Mask item={this.props.item} scene={this.props.scene} />
        <AutoFollow item={this.props.item} scene={this.props.scene} />
        {this.props.item.type === "GROUP" && (
          <Layout item={this.props.item} scene={this.props.scene} />
        )}
        {/* <Code item={this.props.item} scene={this.props.scene} /> */}
        <Control item={this.props.item} scene={this.props.scene} />
        {/* <Interaction item={this.props.item} />
        <div
          className={styles.Button}
          onClick={() => this.props.addComponent()}
        >
          +
        </div> */}
        {(this.props.item.type === "RECTANGLE" ||
          this.props.item.type === "IMAGE") && (
          <SizeToFit
            item={this.props.item}
            scene={this.props.scene}
          ></SizeToFit>
        )}
        {
          <DropShadow
            item={this.props.item}
            scene={this.props.scene}
          ></DropShadow>
        }
        {(this.props.item.type === "RECTANGLE" ||
          this.props.item.type === "TEXT" ||
          this.props.item.type === "CLOCK" ||
          this.props.item.type === "CIRCLE") && (
          <Gradient item={this.props.item} scene={this.props.scene} />
        )}
      </div>
    );
  }
}
