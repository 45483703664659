import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../Theme/Hyper";
import textStyles from "./Text.module.css";
import NewItem from "../Dialog/NewItem";
import ClipLoader from "react-spinners/ClipLoader";
import DragInput from "../DragInput/DragInput";
const Main = styled.div`
  padding: 0.5em;
  width: 440px;
  height: calc(100vh - 85px);
  background-color: #181d25;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const Group = styled.div`
  display: flex;
  padding: 0.5em;
  flex-direction: column;
  margin-bottom: 2em;
  background-color: #1f252f;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
`;

const Title = styled.div`
  font-size: 1.1em;
  background-color: #282f3c;
  padding: 0.3em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #161a21;
`;

const Input = styled.input`
  background-color: rgb(24 29 37);
  border: none;
  height: 20 px;
  color: #d7d7d7;
  outline: none;
  font-size: 15px;
  margin: 5px;
  padding: 5px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  display: flex;
  margin-right: 10px;
  min-width: 120px;
`;

const Select = styled.select`
  background-color: #181d25;
  color: #fff;
  padding: 4px;
  border: none;
  margin: 4px;
  border-radius: 4px;
`;

const Save = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #282f3c;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  margin: 1px;
  :hover {
    color: #fff;
    background-color: #db0a41;
  }
`;
export default function SceneProperties({ scene, project, player }) {
  const [name, setName] = useState();
  const [zIndex, setZIndex] = useState();
  const [archived, setArchived] = useState();
  const [backgroundScene, setBackgroundScene] = useState();
  const [transitionLayer, setTransitionLayer] = useState();
  const [canvasWidth, setCanvasWidth] = useState();
  const [canvasHeight, setCanvasHeight] = useState();
  const [sceneFolder, setSceneFolder] = useState();
  const [liveEnabled, setLiveEnabled] = useState();
  const [folders, setFolders] = useState([]);
  const [saving, setSaving] = useState([]);
  const [showNewFolder, setShowNewFolder] = useState(false);
  useEffect(() => {
    if (scene) {
      setName(scene.name);
      setZIndex(scene.order);
      setBackgroundScene(scene.background_scene);
      setTransitionLayer(scene.transition_layer);
      setLiveEnabled(scene.live_enabled);
      setSceneFolder(scene.folder);
      setCanvasWidth(scene.canvas_width || 1920);
      setCanvasHeight(scene.canvas_height || 1080);

      setArchived(scene.archived);
    }
  }, [scene]);

  useEffect(() => {
    if (project) {
      let new_folders = [];
      project.scenes.map((scene) => {
        new_folders.push(scene.folder);
      });

      setFolders([...new Set(new_folders)]);
    }
  }, [project, sceneFolder]);

  async function save() {
    setSaving(true);
    await project.save(scene.name);
    setSaving(false);
  }

  return (
    <Main>
      <Group>
        <Title>{name}</Title>
        <Row>
          <Label>Folder</Label>
          <Select
            onChange={(event) => {
              scene.folder = event.target.value;
              setSceneFolder(scene.folder);
            }}
            value={sceneFolder}
          >
            {folders.map((folder) => {
              return <option value={folder}>{folder}</option>;
            })}
          </Select>
          <Button
            onClick={() => {
              setShowNewFolder(true);
            }}
          >
            + New Folder
          </Button>
        </Row>
        {/* <Row>
          <Label>Name</Label>
          <Input
            value={name}
            onChange={(e) => {
              setName(e.currentTarget.value);
              scene.name = e.currentTarget.value;
            }}
          />
        </Row> */}
        <Row>
          <Label>Order</Label>
          <Input
            value={zIndex || "0"}
            onChange={(e) => {
              setZIndex(e.currentTarget.value);
              scene.order = e.currentTarget.value;
              scene.scene.zIndex = e.currentTarget.value;
            }}
          />
        </Row>
        <Row>
          <Label>Background Scene</Label>
          <Select
            value={backgroundScene}
            onChange={(e) => {
              setBackgroundScene(e.currentTarget.value);
              scene.background_scene = e.currentTarget.value;
            }}
          >
            <option value="">No Background Scene</option>;
            {project.scenes
              .filter((s) => s.name !== scene?.name)
              .map((scene, index) => {
                return <option value={scene.name}>{scene.name}</option>;
              })}
          </Select>
        </Row>
        <Row>
          <Label>Layer</Label>
          <Input
            value={transitionLayer || ""}
            onChange={(e) => {
              setTransitionLayer(e.currentTarget.value);
              scene.transition_layer = e.currentTarget.value;
            }}
          />
        </Row>
        <Row>
          <label className={textStyles.CheckboxContainer}>
            Studio Enabled
            <input
              type="checkbox"
              checked={liveEnabled}
              onChange={(e) => {
                setLiveEnabled(e.currentTarget.checked);
                scene.live_enabled = e.currentTarget.checked;
              }}
            />
            <span className={textStyles.Checkmark} />
          </label>
        </Row>
        <Row>
          <Label>Canvas Width</Label>
          <DragInput
            value={canvasWidth || "1920"}
            onChange={(value) => {
              setCanvasWidth(value);
              scene.canvas_width = parseInt(value);
              player.renderer.resize(
                scene.canvas_width || window.engine_output_size?.width || 1920,
                scene.canvas_height || window.engine_output_size?.height || 1080
              );
              document.querySelector("canvas").style.width =
                (scene.canvas_width ||
                  window.engine_output_size?.width ||
                  1920) /
                  2 +
                "px";
              document.querySelector("canvas").style.height =
                (scene.canvas_height ||
                  window.engine_output_size?.height ||
                  1080) /
                  2 +
                "px";
            }}
          />
        </Row>
        <Row>
          <Label>Canvas Height</Label>
          <DragInput
            value={canvasHeight || "1080"}
            onChange={(value) => {
              setCanvasHeight(value);
              scene.canvas_height = parseInt(value);
              player.renderer.resize(
                scene.canvas_width || window.engine_output_size?.width || 1920,
                scene.canvas_height || window.engine_output_size?.height || 1080
              );
              document.querySelector("canvas").style.width =
                (scene.canvas_width ||
                  window.engine_output_size?.width ||
                  1920) /
                  2 +
                "px";
              document.querySelector("canvas").style.height =
                (scene.canvas_height ||
                  window.engine_output_size?.height ||
                  1080) /
                  2 +
                "px";
            }}
          />
        </Row>
        <Row>
          <label className={textStyles.CheckboxContainer}>
            Archived
            <input
              type="checkbox"
              checked={archived}
              onChange={(e) => {
                setArchived(e.currentTarget.checked);
                scene.archived = e.currentTarget.checked;
              }}
            />
            <span className={textStyles.Checkmark} />
          </label>
        </Row>
        <Save onClick={() => save()}>
          {saving === true ? "Saving.." : "Save Scene"}
          <ClipLoader color={"#ffffff"} loading={saving === true} size={15} />
        </Save>
      </Group>
      {showNewFolder && (
        <NewItem
          title={"Create Folder"}
          placeholder="Folder Name..."
          onSave={(value, oldValue) => {
            scene.folder = value;
            setSceneFolder(scene.folder);
            setShowNewFolder(false);
          }}
          onCancel={() => {
            setShowNewFolder(false);
          }}
        />
      )}
    </Main>
  );
}
