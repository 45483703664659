import React, { Component, useEffect, useState } from "react";
import styles from "./ProjectViewer.module.css";
import styled from "styled-components";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router";
import NewItem from "../Dialog/NewItem";

const PROJECTS = gql`
  query Projects($user: String!) {
    projectsByUser(userId: $user) {
      name
      colour
      _id
      use_project_folder
      engine_version
      user {
        _id
        username
      }
    }
  }
`;

const ME = gql`
  query Me {
    me {
      project_limit
      _id
    }
  }
`;

const CREATE_PROJECT = gql`
  mutation CreateNewProject($name: String!) {
    addProject(name: $name) {
      name
    }
  }
`;

const Input = styled.input`
  height: 20px;
  color: #8a8c8e;
  outline: none;
  border: 1px solid #232531;
  padding-left: 0.3em;
  font-size: 0.9em;
  background-color: #12161d;
  border-radius: 3px;
`;

const Button = styled.div`
  background-color: ${(props) => (props.green ? "#05ff97" : "#353a47")};
  color: ${(props) => (props.green ? "black" : "")};
  border-radius: 4px;
  padding: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-left: 0.5em;
  :hover {
    color: #fff;
    background-color: ${(props) => (props.green ? "#05ff97" : "#db0a41")};
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
  font-size: 1.5em;
`;
const Row = styled.div`
  display: flex;
  margin-top: 1em;
  align-self: flex-end;
`;

function ProjectViewer({ callback }) {
  const { loading, error, data } = useQuery(PROJECTS, {
    variables: { user: localStorage.getItem("user-id") },
  });
  const [addProject] = useMutation(CREATE_PROJECT, {
    refetchQueries: ["Projects"],
  });

  const { data: user } = useQuery(ME);
  const [showNewProject, setShowNewProject] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");
  const history = useHistory();

  let projects_sorted = [...(data?.projectsByUser || [])];
  projects_sorted =
    projects_sorted.sort((a, b) => {
      return a.user?._id - b.user?._id;
    }) || [];

  return (
    <div className={styles.ProjectViewer}>
      {/* <ViewBar selectEditView={this.props.selectEditView} /> */}
      <div>
        <h2>Your Projects..</h2>
      </div>
      <div className={styles.Projects}>
        {projects_sorted?.map((project, index) => {
          let url = "/" + project?._id;
          return (
            <>
              {index === 0 ||
                (projects_sorted?.[index - 1]?.user?._id !==
                  project.user?._id && <div>{project.user?.username}</div>)}
              <div
                key={index}
                className={styles.Project}
                onClick={() => {
                  history.push(url);
                  callback(project);
                }}
                style={{ backgroundColor: project.colour }}
              >
                {project.name}
                <div className={styles.Engine_Version}>
                  Engine: {project?.engine_version}
                </div>
              </div>
            </>
          );
        })}
        {user?.me?.project_limit > data?.projectsByUser?.length && (
          <div
            className={`${styles.Project} ${styles.New}`}
            onClick={() => {
              setShowNewProject(true);
            }}
          >
            <div>+</div>
          </div>
        )}
      </div>
      {showNewProject && (
        <NewItem
          title={"Create Project"}
          placeholder="Project Name..."
          onSave={(value, oldValue) => {
            addProject({ variables: { name: value } });
            setShowNewProject(false);
          }}
          onCancel={() => {
            setShowNewProject(false);
          }}
        />
      )}
    </div>
  );
}

export default ProjectViewer;
