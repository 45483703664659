import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BasketballData from "./Data/Basketball";
import ClockData from "./Data/Clock";
import FootballData from "./Data/Football";
import GAAData from "./Data/GAA";
import NeverNoData from "./Data/NeverNo";
import NeverNoOptions from "./Data/NeverNoOptions";
import RugbyData from "./Data/Rugby";
import ClimbingData from "./Data/Climbing";

const Main = styled.div`
  padding: 0.5em;
  width: 440px;
  height: calc(100vh - 85px);
  background-color: #181d25;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  background-color: #1f252f;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
`;

const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.div`
  display: flex;
  padding: 0.5em;
  background-color: ${({ selected }) => (selected ? "#0adb93" : "#202731")};
  border-radius: 4px;
  margin: 0.5em;
  cursor: pointer;
  color: ${({ selected }) => (selected ? "#000" : "#fff")};
  align-items: center;
  :hover {
    background-color: #0adb93;
    color: #000;
  }
`;

export default function Data({ scene, app }) {
  const [view, setView] = useState(0);
  return (
    <Main>
      <Tabs>
        <Tab
          selected={view === 0}
          onClick={() => {
            setView(0);
          }}
        >
          Football
        </Tab>
        <Tab
          selected={view === 2}
          onClick={() => {
            setView(2);
          }}
        >
          Rugby
        </Tab>
        <Tab
          selected={view === 4}
          onClick={() => {
            setView(4);
          }}
        >
          Basketball
        </Tab>
        <Tab
          selected={view === 5}
          onClick={() => {
            setView(5);
          }}
        >
          GAA
        </Tab>
        <Tab
          selected={view === 6}
          onClick={() => {
            setView(6);
          }}
        >
          Climbing
        </Tab>
        <Tab
          selected={view === 1}
          onClick={() => {
            setView(1);
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "/Images/Dizplai-PRIMARY-White.png"}
            height="20"
            alt="NeverNo Logo"
          />
        </Tab>
        <Tab
          selected={view === 3}
          onClick={() => {
            setView(3);
          }}
        >
          Stadium Clock
        </Tab>
      </Tabs>
      <Group>
        {view === 0 && <FootballData scene={scene} app={app} />}
        {view === 1 && <NeverNoOptions scene={scene} app={app} />}
        {view === 2 && <RugbyData scene={scene} app={app} />}
        {view === 3 && <ClockData scene={scene} app={app} />}
        {view === 4 && <BasketballData scene={scene} app={app} />}
        {view === 5 && <GAAData scene={scene} app={app} />}
        {view === 6 && <ClimbingData scene={scene} app={app} />}
      </Group>
    </Main>
  );
}
