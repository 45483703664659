import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { GradientPickerPopover } from "react-linear-gradient-picker";
import "react-linear-gradient-picker/dist/index.css";
import { SketchPicker } from "react-color";
import { gradient, redrawCircle } from "../../Scripts/PropertyUtils";
import "./ColorPicker.css";
import styled from "styled-components";
import TriangleDown from "../../SVG/TriangleDown";
import TriangleRight from "../../SVG/TriangleRight";
import "./Gradient.css";
import styles from "./DropShadow.module.css";
import textStyles from "./Text.module.css";
import {
  setProperty as SET_PROPERTY,
  redrawRectangle,
} from "../../Scripts/PropertyUtils";

const PIXI = window.PIXI;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  background-color: #1f252f;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
  padding: 5px;
`;

const GroupTitle = styled.div`
  cursor: pointer;
`;

const Open = styled.div`
  svg {
    width: 10px;
    float: left;
    margin-left: 10px;
    margin-top: 6px;
  }
`;

const Title = styled.div`
  font-size: 1.1em;
  background-color: #282f3c;
  padding: 0.3em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #161a21;
`;

export default function Gradient({ item }) {
  const [open, setOpen] = useState(false);
  const [openPicker, setOpenPicker] = useState(false);
  const [palette, setPalette] = useState([]);
  const [angle, setAngle] = useState(0);
  const [type, setType] = useState("linear");

  useEffect(() => {
    let p = item.gradient?.palette || [
      { offset: "0.00", color: "#fff" },
      { offset: "0.49", color: "#f00" },
      { offset: "1.00", color: "#00f" },
    ];
    setAngle(item?.gradient?.angle);
    setPalette(p);
  }, [item]);

  const update = useCallback(
    ({ palette, angle, type }) => {
      if (!item.gradient) {
        item.gradient = {};
      }
      item.gradient.palette = palette;
      item.gradient.type = type;
      item.gradient.angle = angle;
      if (item.type === "RECTANGLE") {
        if (item.gradient.palette && item.gradient.palette?.length > 0) {
          redrawRectangle({ item });
        }
      }
      if (item.type === "CIRCLE") {
        if (item.gradient.palette && item.gradient.palette?.length > 0) {
          redrawCircle({ item });
        }
      }
      if (item.type === "TEXT" || item.type === "CLOCK") {
        if (item.gradient.enabled && item.gradient?.palette?.length > 0) {
          if (item.gradient.horizontal) {
            item._style.fillGradientType = 0;
          } else {
            item._style.fillGradientType = 1;
          }

          item.style.fill = item.gradient.palette?.map((i) => i.color);
          item.style.fillGradientStops = item.gradient.palette?.map((i) =>
            parseFloat(i.offset)
          );
        } else {
          item.style.fill = item.colour;
        }
      }
    },
    [item]
  );

  useEffect(() => {
    update({ palette, angle, type });
  }, [palette, angle, update, type]);

  return (
    <Group>
      <GroupTitle onClick={() => setOpen(!open)}>
        <Open>
          {open && <TriangleDown />}
          {!open && <TriangleRight />}
        </Open>
        <Title>Gradient</Title>
      </GroupTitle>

      {open && (
        <>
          <div className={styles.lists}>
            <div className={textStyles.Property}>
              <label className={textStyles.CheckboxContainer}>
                Enabled
                <input
                  type="checkbox"
                  checked={item?.gradient?.enabled ? true : false}
                  onChange={(e) => {
                    SET_PROPERTY(
                      item,
                      "gradient.enabled",
                      e.currentTarget.checked
                    );
                    update({ palette, angle, type });
                  }}
                />
                <span className={textStyles.Checkmark} />
              </label>
            </div>
          </div>

          <GradientPickerPopover
            {...{
              open: openPicker,
              setOpen: setOpenPicker,
              angle,
              setAngle,
              width: 220,
              maxStops: 10,
              paletteHeight: 32,
              palette,
              gradientType: type,
              showGradientTypePicker: item.type !== "TEXT",
              showAnglePicker: item.type !== "TEXT",
              onPaletteChange: (new_palette) => {
                setPalette(new_palette);
              },
              setGradientType: (type) => {
                setType(type);
              },
            }}
          >
            <WrappedColorPicker />
          </GradientPickerPopover>
          {(item.type === "TEXT" || item.type === "CLOCK") && (
            <div className={styles.lists}>
              <div
                className="Button"
                style={{
                  backgroundColor: !item.gradient.horizontal ? "#db0741" : "",
                }}
                onClick={() => {
                  SET_PROPERTY(item, "gradient.horizontal", false);
                  update({ palette, angle, type });
                }}
              >
                Horizontal
              </div>
              <div
                className="Button"
                style={{
                  backgroundColor: item.gradient.horizontal ? "#db0741" : "",
                }}
                onClick={() => {
                  SET_PROPERTY(item, "gradient.horizontal", true);
                  update({ palette, angle, type });
                }}
              >
                Vertical
              </div>
            </div>
          )}
        </>
      )}
    </Group>
  );
}

const WrappedColorPicker = ({ onSelect, ...rest }) => (
  <div className="GradientPicker">
    <SketchPicker
      {...rest}
      styles={{
        picker: {
          backgroundColor: "#373a4a",
          padding: "10px 10px 0px",
          borderRadius: "4px",
          boxShadow:
            "rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px",
        },
      }}
      onChange={(c) => {
        onSelect(
          "rgba(" +
            c.rgb.r +
            "," +
            c.rgb.g +
            "," +
            c.rgb.b +
            "," +
            c.rgb.a +
            ")",
          c.rgb.a
        );
      }}
    />
  </div>
);
