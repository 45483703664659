import React from "react";
import styled from "styled-components";

const Main = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${(props) => (props.show ? "3000" : "-100")};
  overflow: hidden;
  outline: 0;
  opacity: ${(props) => (props.show ? "1" : "0")};
  transition: opacity 0.15s linear;
  text-align: center;
  padding: none;
  :before {
    content: "";
    display: inline-block;
    height: 20%;
    vertical-align: top;
    margin-right: -4px;
  }
`;
const ModalInner = styled.div`
  transform: ${(props) =>
    props.show ? "translate(0, 0%)" : "translate(0, -25%)"};
  transition: transform 0.3s ease-out;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  position: relative;
  width: auto;
  max-width: 80vw;
  background-color: #414752;
  padding: 1em;
  border-radius: 4px;
`;

const Backdrop = styled.div`
  opacity: ${(props) => (props.show ? "0.5" : "0")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background-color: #000;
  transition: opacity 0.15s linear;
  pointer-events: ${(props) => (props.show ? "auto" : "none")};
`;

export default function Dialog({ show, onCancel, children }) {
  return (
    <>
      <Backdrop show={show} onClick={onCancel}></Backdrop>

      <Main show={show} onClick={onCancel}>
        <ModalInner show={show} onClick={(e) => e.stopPropagation()}>
          {children}
        </ModalInner>
      </Main>
    </>
  );
}
