import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../../Theme/Hyper";
const Select = styled.select`
  background-color: #181d25;
  color: #fff;
  padding: 4px;
  border: none;
  margin: 4px;
  border-radius: 4px;
  max-width: 100px;
`;
const Main = styled.div`
  padding: 0.5em;
  width: 440px;
  height: calc(100vh - 85px);
  background-color: #181d25;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  background-color: #1f252f;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
`;

const Title = styled.div`
  font-size: 1.1em;
  background-color: #282f3c;
  padding: 0.3em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #161a21;
`;

const Row = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
`;

const VariablesTable = styled.div`
  margin-top: 10px;
`;
const Column = styled.div`
  display: flex;
  width: 25%;
`;
const Input = styled.input`
  border: 1px solid #232531;
  padding: 4px;
  font-size: 0.9em;
  background-color: #12161d;
  border-radius: 3px;
  color: #fff;
  max-width: 100px;
`;

const home_team = [
  { name: "Home Team Code" },
  { name: "Home Team Name" },
  { name: "Home Team Short Name" },
  { name: "Home Team Badge" },
  { name: "Home Team Secondary Badge" },
  { name: "Home Team Video Badge" },
  { name: "Home Team Kit BG Colour", type: "colour" },
  { name: "Home Team Kit Text Colour", type: "colour" },
];
const away_team = [
  { name: "Away Team Code" },
  { name: "Away Team Name" },
  { name: "Away Team Short Name" },
  { name: "Away Team Badge" },
  { name: "Away Team Secondary Badge" },
  { name: "Away Team Video Badge" },
  { name: "Away Team Kit BG Colour", type: "colour" },
  { name: "Away Team Kit Text Colour", type: "colour" },
];
const competition = [
  { name: "Competition" },
  { name: "Competition Badge" },
  { name: "Competition Colour" },
];
const match = [
  { name: "Date" },
  { name: "Stadium" },
  { name: "Kick-Off" },
  { name: "Home Score" },
  { name: "Away Score" },
  { name: "Home Agg Score" },
  { name: "Away Agg Score" },
  { name: "Leg" },
  { name: "Period" },
  { name: "Group" },
  { name: "Game Clock" },
  { name: "Additional Time" },
  { name: "Home Red Cards" },
  { name: "Away Red Cards" },
  { name: "Attendance" },
];

const team = (index) => {
  if (index) {
    return [
      { name: "Team Code " + index },
      { name: "Team Name " + index },
      { name: "Team Short Name " + index },
      { name: "Team Badge " + index },
      { name: "Team Secondary Badge " + index },
      { name: "Team Video Badge " + index },
      { name: "Team Kit BG Colour " + index, type: "colour" },
      { name: "Team Kit Text Colour " + index, type: "colour" },
    ];
  } else {
    return [
      { name: "Team Code" },
      { name: "Team Name" },
      { name: "Team Short Name" },
      { name: "Team Badge" },
      { name: "Team Secondary Badge" },
      { name: "Team Video Badge" },
      { name: "Team Kit BG Colour", type: "colour" },
      { name: "Team Kit Text Colour", type: "colour" },
    ];
  }
};
const commentator = (index) => {
  if (index) {
    return [
      { name: "Commentator First Name " + index },
      { name: "Commentator Last Name " + index },
      { name: "Commentator First Name Uppercase " + index },
      { name: "Commentator Last Name Uppercase " + index },
      { name: "Commentator Full Name " + index },
      { name: "Commentator Full Name Uppercase " + index },
    ];
  } else {
    return [
      { name: "Commentator First Name" },
      { name: "Commentator Last Name" },
      { name: "Commentator First Name Uppercase" },
      { name: "Commentator Last Name Uppercase" },
      { name: "Commentator Full Name" },
      { name: "Commentator Full Name Uppercase" },
    ];
  }
};
const team_standings = (index) => {
  return [
    ...team(index),
    { name: "Team Games Played " + index },
    { name: "Team Games Won " + index },
    { name: "Team Games Lost " + index },
    { name: "Team Games Drawn " + index },
    { name: "Team Points " + index },
    { name: "Team Goal Difference " + index },
    { name: "Team Goals For " + index },
    { name: "Team Goals Against " + index },
    { name: "Team Rank " + index },
    { name: "Team Rank Status " + index },
  ];
};
const Manager = [
  { name: "Manager First Name" },
  { name: "Manager Last Name" },
  { name: "Manager First Name Uppercase" },
  { name: "Manager Last Name Uppercase" },
  { name: "Manager Full Name" },
  { name: "Manager Full Name Uppercase" },
  { name: "Manager Nationality" },
  { name: "Manager Title" },
];
const player = (index) => {
  if (index) {
    return [
      { name: "First Name " + index },
      { name: "Last Name " + index },
      { name: "First Name Uppercase " + index },
      { name: "Last Name Uppercase " + index },
      { name: "Full Name " + index },
      { name: "Full Name Uppercase " + index },
      { name: "Nationality " + index },
      { name: "Position " + index },
      { name: "Shirt Number " + index },
      { name: "Captain " + index },
      { name: "X/Y " + index },
      { name: "Subbed " + index },
      { name: "Card " + index },
      { name: "Goals " + index },
    ];
  } else {
    return [
      { name: "Title" },
      { name: "First Name" },
      { name: "Last Name" },
      { name: "First Name Uppercase" },
      { name: "Last Name Uppercase" },
      { name: "Full Name" },
      { name: "Full Name Uppercase" },
      { name: "Nationality" },
      { name: "Position" },
      { name: "Shirt Number" },
      { name: "Captain " },
      { name: "Subbed" },
      { name: "Card" },
      { name: "Goals" },
    ];
  }
};
const team_stats = (team) => {
  return [
    { name: team + " Possession" },
    { name: team + " Total Shots" },
    { name: team + " On Target" },
    { name: team + " Clear Cut Chances" },
    { name: team + " Successful Crosses" },
    { name: team + " Successful Dribbles" },
    { name: team + " Corners" },
    { name: team + " Offsides" },
    { name: team + " Yellow Cards" },
    { name: team + " Tackles Won" },
    { name: team + " Interceptions" },
    { name: team + " Blocks" },
    { name: team + " Clearances" },
    { name: team + " Headed Clearances" },
    { name: team + " Aerial Duels Won" },
    { name: team + " Blocked Crosses" },
    { name: team + " Passing Accuracy" },
    { name: team + " Attacking 3rd" },
    { name: team + " Key Passes" },
    { name: team + " Fouls Committed" },
    { name: team + " Fouls Won" },
    { name: team + " Yellow Cards" },
    { name: team + " Red Cards" },
    { name: team + " Touches In Opp Box" },
  ];
};

const penalty = (team, index) => {
  return { name: team + " Penalty " + index };
};
const commentators = (index) => {
  return [
    { name: "Comm First Name " + index },
    { name: "Comm Last Name " + index },
    { name: "Comm Full Name " + index },
  ];
};

const getScorer = (team, index) => {
  return [
    { name: team + " Scorer " + index + " Shirt Number" },
    { name: team + " Scorer " + index + " First Name Uppercase" },
    { name: team + " Scorer " + index + " Last Name Uppercase" },
    { name: team + " Scorer " + index + " Goal Time" },
  ];
};

const game = (index) => {
  return [
    { name: "Game " + index + " Home Team Name" },
    { name: "Game " + index + " Home Team Short Name" },
    { name: "Game " + index + " Home Team Badge" },
    { name: "Game " + index + " Home Team Score" },
    { name: "Game " + index + " Away Team Name" },
    { name: "Game " + index + " Away Team Short Name" },
    { name: "Game " + index + " Away Team Badge" },
    { name: "Game " + index + " Away Team Score" },
    { name: "Game " + index + " Status" },
    { name: "Game " + index + " Date" },
    { name: "Game " + index + " Kick Off" },
    { name: "Game " + index + " Attendance" },
  ];
};
const zone = (i) => {
  return [
    { name: "Zone Value " + i },
    { name: "Zone Colour " + i },
    { name: "Zone Team " + i },
  ];
};

const home_scorers = [
  { name: "Home Scorers Line 1" },
  { name: "Home Scorers Line 2" },
  { name: "Home Scorers Line 3" },
];

const away_scorers = [
  { name: "Away Scorers Line 1" },
  { name: "Away Scorers Line 2" },
  { name: "Away Scorers Line 3" },
];

const graphics = [
  {
    name: "Match Delayed",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      ...commentators(1),
      ...commentators(2),
      ...commentators(3),
    ],
  },
  {
    name: "Match Postponed",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      ...commentators(1),
      ...commentators(2),
      ...commentators(3),
    ],
  },
  {
    name: "Match Abandoned",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      ...commentators(1),
      ...commentators(2),
      ...commentators(3),
    ],
  },
  {
    name: "Versus",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      ...commentators(1),
      ...commentators(2),
      ...commentators(3),
      { name: "Group" },
    ],
  },
  {
    name: "MatchID",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Show Background" },
    ],
  },
  {
    name: "FFCustomSlate",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Caption" },
    ],
  },
  {
    name: "FFWeatherSlate",
    variables: [...home_team, ...away_team, ...competition, ...match],
  },
  {
    name: "FFTechnicalSlate",
    variables: [...home_team, ...away_team, ...competition, ...match],
  },
  {
    name: "FFPostponedSlate",
    variables: [...home_team, ...away_team, ...competition, ...match],
  },
  {
    name: "FFCancelledSlate",
    variables: [...home_team, ...away_team, ...competition, ...match],
  },
  {
    name: "MatchID Highlights",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Show Background" },
    ],
  },
  {
    name: "Score Update",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Last Goal Player First Name Uppercase" },
      { name: "Last Goal Player Last Name Uppercase" },
      { name: "Last Goal Player Full Name" },
      { name: "Last Goal Player Full Name Uppercase" },
      { name: "Last Goal Team" },
      { name: "Last Goal Penalty" },
      { name: "Last Goal OG" },
      { name: "Last Goal Time" },
      { name: "ET Home Score" },
      { name: "ET Away Score" },
      { name: "Group" },
      ...home_scorers,
      ...away_scorers,
    ],
  },
  {
    name: "HT Score",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Half Text" },
      { name: "Group" },
      ...home_scorers,
      ...away_scorers,
    ],
  },
  {
    name: "FT Score",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Half Text" },
      { name: "Group" },
      ...getScorer("Home", 1),
      ...getScorer("Home", 2),
      ...getScorer("Home", 3),
      ...getScorer("Home", 4),
      ...getScorer("Home", 5),
      ...getScorer("Home", 6),
      ...getScorer("Home", 7),
      ...getScorer("Home", 8),
      ...getScorer("Home", 9),
      ...getScorer("Home", 10),
      ...getScorer("Away", 1),
      ...getScorer("Away", 2),
      ...getScorer("Away", 3),
      ...getScorer("Away", 4),
      ...getScorer("Away", 5),
      ...getScorer("Away", 6),
      ...getScorer("Away", 7),
      ...getScorer("Away", 8),
      ...getScorer("Away", 9),
      ...getScorer("Away", 10),
      ...home_scorers,
      ...away_scorers,
    ],
  },
  {
    name: "FT Score Highlights",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Half Text" },
      { name: "Group" },
      ...getScorer("Home", 1),
      ...getScorer("Home", 2),
      ...getScorer("Home", 3),
      ...getScorer("Home", 4),
      ...getScorer("Home", 5),
      ...getScorer("Home", 6),
      ...getScorer("Home", 7),
      ...getScorer("Home", 8),
      ...getScorer("Home", 9),
      ...getScorer("Home", 10),
      ...getScorer("Away", 1),
      ...getScorer("Away", 2),
      ...getScorer("Away", 3),
      ...getScorer("Away", 4),
      ...getScorer("Away", 5),
      ...getScorer("Away", 6),
      ...getScorer("Away", 7),
      ...getScorer("Away", 8),
      ...getScorer("Away", 9),
      ...getScorer("Away", 10),
      ...home_scorers,
      ...away_scorers,
    ],
  },
  {
    name: "ET HT Score",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Half Text" },
      { name: "ET Home Score" },
      { name: "ET Away Score" },
      { name: "Group" },
      ...home_scorers,
      ...away_scorers,
    ],
  },
  {
    name: "ET FT Score",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Half Text" },
      { name: "ET Home Score" },
      { name: "ET Away Score" },
      { name: "Group" },
      ...home_scorers,
      ...away_scorers,
    ],
  },
  {
    name: "Penalties Score",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Half Text" },
      { name: "Group" },
      ...home_scorers,
      ...away_scorers,
    ],
  },
  {
    name: "Lineup",
    variables: [
      ...competition,
      ...team(),
      ...Manager,
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      ...player(9),
      ...player(10),
      ...player(11),
      ...player(12),
      ...player(13),
      ...player(14),
      ...player(15),
      ...player(16),
      ...player(17),
      ...player(18),
      ...player(19),
      ...player(20),
      ...player(21),
      ...player(22),
      ...player(23),
      ...player(25),
      ...player(26),
      ...player(27),
      ...player(28),
      ...player(29),
      ...player(30),
      { name: "Title" },
      { name: "Home Team Badge" },
      { name: "Home Team Secondary Badge" },
      { name: "Away Team Badge" },
      { name: "Away Team Secondary Badge" },
      { name: "Sponsor Image" },
    ],
  },
  {
    name: "Lineup Subs",
    variables: [
      ...team(),
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      ...player(9),
      ...player(10),
      ...player(11),
      { name: "Title" },
    ],
  },
  {
    name: "Clock",
    variables: [...home_team, ...away_team, ...competition, ...match],
  },
  {
    name: "HT/FT Bug",
    variables: [...home_team, ...away_team, ...competition, ...match],
  },
  {
    name: "Name",
    variables: [
      ...competition,
      ...player(),
      ...team(),
      { name: "Subtitle" },
      { name: "Goal Time" },
    ],
  },
  {
    name: "FFSponsor",
    variables: [
      ...competition,
      { name: "Home Team Name" },
      { name: "Away Team Name" },
      { name: "Home Team Sponsor Image" },
      { name: "Away Team Sponsor Image" },
    ],
  },
  {
    name: "Referee",
    variables: [
      ...competition,
      { name: "First Name" },
      { name: "Last Name" },
      { name: "Full Name" },
      { name: "Subtitle" },
    ],
  },
  {
    name: "Player Of The Match",
    variables: [
      ...competition,
      { name: "First Name" },
      { name: "Last Name" },
      { name: "First Name Uppercase" },
      { name: "Last Name Uppercase" },
      { name: "Full Name" },
      { name: "Shirt Number" },
      ...team(),
      { name: "Subtitle" },
    ],
  },
  {
    name: "Yellow Card",
    variables: [...competition, ...player(), ...team()],
  },
  {
    name: "Double Yellow Card",
    variables: [...competition, ...player(), ...team()],
  },
  {
    name: "Red Card",
    variables: [...competition, ...player(), ...team()],
  },
  {
    name: "Clock Yellow Card",
    variables: [...competition, ...player(), ...team()],
  },
  {
    name: "Clock Double Yellow Card",
    variables: [...competition, ...player(), ...team()],
  },
  {
    name: "Clock Red Card",
    variables: [...competition, ...player(), ...team()],
  },
  {
    name: "Player Scored",
    variables: [
      ...competition,
      ...player(),
      ...team(),
      { name: "Goal Time" },
      { name: "Subtitle" },
    ],
  },
  {
    name: "Substitution",
    variables: [...competition, ...player(1), ...player(2), ...team()],
  },
  {
    name: "Two Substitution",
    variables: [
      ...competition,
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...team(),
    ],
  },
  {
    name: "Three Substitution",
    variables: [
      ...competition,
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...team(),
    ],
  },
  {
    name: "Clock Substitution",
    variables: [...competition, ...player(1), ...player(2), ...team()],
  },
  {
    name: "Clock Two Substitution",
    variables: [
      ...competition,
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...team(),
    ],
  },
  {
    name: "Clock Three Substitution",
    variables: [
      ...competition,
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...team(),
    ],
  },
  {
    name: "Penalties",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      ...new Array(20).fill(1).map((_, index) => penalty("Home", index + 1)),
      ...new Array(20).fill(1).map((_, index) => penalty("Away", index + 1)),
    ],
  },
  {
    name: "Formation",
    variables: [
      { name: "Team Type" },
      ...team(),
      ...Manager,
      ...competition,
      ...match,
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      ...player(9),
      ...player(10),
      ...player(11),
      ...player(12),
      ...player(13),
      ...player(14),
      ...player(15),
      ...player(16),
      ...player(17),
      ...player(18),
      ...player(19),
      ...player(20),
      ...player(21),
      ...player(22),
      ...player(23),
      ...player(24),
      ...player(25),
      ...player(26),
      ...player(27),
      ...player(28),
      ...player(29),
      ...player(30),
      { name: "Sponsor Image" },
    ],
  },
  {
    name: "OTS Formation",
    variables: [
      { name: "Team Type" },
      ...team(),
      ...Manager,
      ...competition,
      ...match,
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      ...player(6),
      ...player(7),
      ...player(8),
      ...player(9),
      ...player(10),
      ...player(11),
      ...player(12),
      ...player(13),
      ...player(14),
      ...player(15),
      ...player(16),
      ...player(17),
      ...player(18),
      ...player(19),
      ...player(20),
      ...player(21),
      ...player(22),
      ...player(23),
      ...player(24),
      ...player(25),
      ...player(26),
      ...player(27),
      ...player(28),
      ...player(29),
      ...player(30),
    ],
  },
  {
    name: "Team Ticker",
    variables: [
      ...team(),
      ...player(1),
      ...player(2),
      ...player(3),
      ...player(4),
      ...player(5),
      { name: "Title" },
    ],
  },
  {
    name: "FF Stats",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      ...team_stats("Home"),
      ...team_stats("Away"),
    ],
  },
  {
    name: "LT Match Stats",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      ...team_stats("Home"),
      ...team_stats("Away"),
    ],
  },
  {
    name: "LT Action Areas",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      { name: "Pitch Left" },
      { name: "Pitch Centre" },
      { name: "Pitch Right" },
    ],
  },
  {
    name: "LT Zones of Control",
    variables: [
      ...home_team,
      ...away_team,
      ...competition,
      ...match,
      ...zone(1),
      ...zone(2),
      ...zone(3),
      ...zone(4),
      ...zone(5),
      ...zone(6),
      ...zone(7),
      ...zone(8),
      ...zone(9),
      ...zone(10),
      ...zone(11),
      ...zone(12),
      ...zone(13),
      ...zone(14),
      ...zone(15),
      ...zone(16),
      ...zone(17),
      ...zone(18),
      ...zone(19),
      ...zone(20),
      ...zone(21),
      ...zone(22),
      ...zone(23),
      ...zone(24),
      ...zone(25),
      ...zone(26),
      ...zone(27),
      ...zone(28),
      ...zone(29),
      ...zone(30),
    ],
  },
  {
    name: "Team Stat",
    variables: [
      ...competition,
      ...home_team,
      ...away_team,
      { name: "Category" },
      { name: "Home Team Value" },
      { name: "Away Team Value" },
    ],
  },
  {
    name: "Group Table",
    variables: [
      ...team(1),
      { name: "Team Played 1" },
      { name: "Team Points 1" },
      { name: "Team Won 1" },
      { name: "Team Lost 1" },
      ...team(2),
      { name: "Team Played 2" },
      { name: "Team Points 2" },
      { name: "Team Won 2" },
      { name: "Team Lost 2" },
      ...team(3),
      { name: "Team Played 3" },
      { name: "Team Points 3" },
      { name: "Team Won 3" },
      { name: "Team Lost 3" },
      ...team(4),
      { name: "Team Played 4" },
      { name: "Team Points 4" },
      { name: "Team Won 4" },
      { name: "Team Lost 4" },
      ...team(5),
      { name: "Team Played 5" },
      { name: "Team Points 5" },
      { name: "Team Won 5" },
      { name: "Team Lost 5" },
      ...team(6),
      { name: "Team Played 6" },
      { name: "Team Points 6" },
      { name: "Team Won 6" },
      { name: "Team Lost 6" },
      ...competition,
      { name: "Title" },
    ],
  },
  {
    name: "League Table",
    variables: [
      { name: "Page" },
      ...team_standings(1),
      ...team_standings(2),
      ...team_standings(3),
      ...team_standings(4),
      ...team_standings(5),
      ...team_standings(6),
      ...team_standings(7),
      ...team_standings(8),
      ...team_standings(9),
      ...team_standings(10),
      ...team_standings(11),
      ...team_standings(12),
      ...team_standings(13),
      ...team_standings(14),
      ...team_standings(15),
      ...team_standings(16),
      ...team_standings(17),
      ...team_standings(18),
      ...team_standings(19),
      ...team_standings(20),
      ...team_standings(21),
      ...team_standings(22),
      ...team_standings(23),
      ...team_standings(24),
      ...team_standings(25),
      ...team_standings(26),
      ...team_standings(27),
      ...team_standings(28),
      ...team_standings(29),
      ...team_standings(30),
      ...team_standings(31),
      ...team_standings(32),
      ...team_standings(33),
      ...team_standings(34),
      ...team_standings(35),
      ...team_standings(36),
      ...competition,
      { name: "Title" },
    ],
  },
  {
    name: "Mini League Table Top",
    variables: [
      { name: "Page" },
      ...team_standings(1),
      ...team_standings(2),
      ...team_standings(3),
      ...team_standings(4),
      ...team_standings(5),
      ...team_standings(6),
      ...team_standings(7),
      ...team_standings(8),
      ...team_standings(9),
      ...team_standings(10),
      ...team_standings(11),
      ...team_standings(12),
      ...team_standings(13),
      ...team_standings(14),
      ...team_standings(15),
      ...team_standings(16),
      ...team_standings(17),
      ...team_standings(18),
      ...team_standings(19),
      ...team_standings(20),
      ...team_standings(21),
      ...team_standings(22),
      ...team_standings(23),
      ...team_standings(24),
      ...team_standings(25),
      ...team_standings(26),
      ...team_standings(27),
      ...team_standings(28),
      ...team_standings(29),
      ...team_standings(30),
      ...team_standings(31),
      ...team_standings(32),
      ...team_standings(33),
      ...team_standings(34),
      ...team_standings(35),
      ...team_standings(36),
      ...competition,
      { name: "Title" },
    ],
  },
  {
    name: "Mini League Table Bottom",
    variables: [
      { name: "Page" },
      ...team_standings(1),
      ...team_standings(2),
      ...team_standings(3),
      ...team_standings(4),
      ...team_standings(5),
      ...team_standings(6),
      ...team_standings(7),
      ...team_standings(8),
      ...team_standings(9),
      ...team_standings(10),
      ...team_standings(11),
      ...team_standings(12),
      ...team_standings(13),
      ...team_standings(14),
      ...team_standings(15),
      ...team_standings(16),
      ...team_standings(17),
      ...team_standings(18),
      ...team_standings(19),
      ...team_standings(20),
      ...team_standings(21),
      ...team_standings(22),
      ...team_standings(23),
      ...team_standings(24),
      ...team_standings(25),
      ...team_standings(26),
      ...team_standings(27),
      ...team_standings(28),
      ...team_standings(29),
      ...team_standings(30),
      ...team_standings(31),
      ...team_standings(32),
      ...team_standings(33),
      ...team_standings(34),
      ...team_standings(35),
      ...team_standings(36),
      ...competition,
      { name: "Title" },
    ],
  },
  {
    name: "Fixtures",
    variables: [
      { name: "Page" },
      ...competition,
      ...game(1),
      ...game(2),
      ...game(3),
      ...game(4),
      ...game(5),
      ...game(6),
      ...game(7),
      ...game(8),
      ...game(9),
      ...game(10),
      ...game(11),
      ...game(12),
      ...game(13),
      ...game(14),
      ...game(15),
      ...game(16),
      ...game(17),
      ...game(18),
      ...game(19),
      ...game(20),
      ...game(21),
      ...game(22),
      ...game(23),
      ...game(24),
      ...game(25),
      ...game(26),
      ...game(27),
      ...game(28),
      ...game(29),
      ...game(30),
      ...game(31),
      ...game(32),
      ...game(33),
      ...game(34),
      ...game(35),
      ...game(36),
    ],
  },
  {
    name: "Team Sponsor",
    variables: [
      { name: "Team Type" },
      ...team(),
      ...competition,
      ...match,
      { name: "Sponsor Image" },
    ],
  },
  {
    name: "Attendance",
    variables: [...competition, ...match],
  },
  {
    name: "Commentators",
    variables: [
      ...competition,
      ...match,
      ...commentator(1),
      ...commentator(2),
      ...commentator(3),
    ],
  },
  {
    name: "Goal Update",
    variables: [
      ...home_team,
      { name: "Home Scorer Name" },
      ...away_team,
      { name: "Away Scorer Name" },
      ...competition,
      ...match,
      { name: "Sponsor Image" },
    ],
  },
  {
    name: "Player Touch Map",
    variables: [
      ...team(),
      ...competition,
      ...match,
      { name: "Touches" },
      ...player(),
    ],
  },
  {
    name: "Average Positions",
    variables: [
      ...team(),
      ...competition,
      ...match,
      { name: "Average positions" },
      ...player(),
    ],
  },
];

export default function FootballData({ scene, app }) {
  const [update, setUpdate] = useState();
  return (
    <Main>
      <Group>
        <Title>Graphic Template</Title>
        {new Array(scene.football?.templates?.length || 0)
          .fill(1)
          .map((_, index) => {
            let template = scene.football.templates?.[index] || graphics[0];

            return (
              <>
                <Button
                  style={{ fontSize: "15px" }}
                  onClick={() => {
                    scene.football.templates = scene.football.templates.filter(
                      (t) => {
                        return t.name !== template.name;
                      }
                    );
                    setUpdate(Date.now());
                  }}
                >
                  Remove Template
                </Button>
                <TemplateEditor
                  template={template}
                  scene={scene}
                  app={app}
                  template_index={index}
                  setUpdate={setUpdate}
                  update={update}
                />
              </>
            );
          })}
      </Group>
      <Group
        onClick={() => {
          if (!scene.football?.templates?.length) {
            scene.football.templates = [];
          }
          scene.football.templates.push({ name: graphics[0].name });
          setUpdate(Date.now());
        }}
      >
        <Title>+ Add Template</Title>
      </Group>
    </Main>
  );
}

function TemplateEditor({
  template,
  scene,
  app,
  template_index,
  setUpdate,
  update,
}) {
  const [selectedTemplate, setSelectedTemplate] = useState(
    graphics.find((g) => g.name === template?.name)
  );
  const [nodes, setNodes] = useState([]);
  const [showInput, setShowInput] = useState(false);
  useEffect(() => {
    setNodes(
      getControls({ children: scene.scene.children, control_array: [] })
    );

    setSelectedTemplate(graphics.find((g) => g.name === template.name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene, template]);

  function getControls({ children, control_array }) {
    let newChildren = children.filter(
      (c) => c.control && c.control.name !== ""
    );
    control_array = [...control_array, ...newChildren];
    let childs = children.filter((c) => c.children && c.children.length > 0);
    for (let i = 0; i < childs.length; i++) {
      control_array = getControls({
        children: childs[i].children,
        control_array,
      });
    }

    return control_array;
  }

  return (
    <>
      <Select
        style={{ maxWidth: "200px" }}
        onChange={(event) => {
          if (!scene.football) {
            scene.football = {};
          }

          template = event.currentTarget.value;
          scene.football.templates[template_index] = {
            ...scene.football.templates[template_index],
            name: template,
          };
          app.setState(app.state);

          setSelectedTemplate(
            graphics.find((graphic) => graphic.name === template)
          );
          setUpdate(Date.now());
        }}
        value={selectedTemplate?.name}
      >
        {graphics.map((graphic, index) => {
          return <option value={graphic.name}>{graphic.name}</option>;
        })}
      </Select>
      {selectedTemplate && (
        <VariablesTable>
          <Row>
            <Column>Node</Column>
            <Column>Data variable</Column>
            <Column>Default value</Column>
            <Column></Column>
          </Row>
          {nodes?.map((node) => {
            return (
              <Row>
                <Column>{node?.control?.name || ""}</Column>
                <Column>
                  {showInput && (
                    <Input
                      value={
                        node?.control?.data?.variables?.find(
                          (v) => v.template === selectedTemplate.name
                        )?.field || ""
                      }
                      onChange={(ev) => {
                        let item = scene.getItemByUUID(
                          node.uuid,
                          scene.scene.children
                        );

                        if (!item.control?.data?.variables) {
                          item.control.data = { variables: [] };
                        }

                        let control = item.control.data.variables.find(
                          (v) => v.template === selectedTemplate.name
                        );

                        item.control.data.variables =
                          item.control.data.variables.filter(
                            (v) => v.template !== selectedTemplate.name
                          );

                        item.control.data.variables.push({
                          ...control,
                          template: selectedTemplate.name,
                          field: ev.currentTarget.value,
                        });

                        setUpdate(Date.now());
                      }}
                    />
                  )}
                  {!showInput && (
                    <Select
                      onChange={(ev) => {
                        let item = scene.getItemByUUID(
                          node.uuid,
                          scene.scene.children
                        );

                        if (!item.control?.data?.variables) {
                          item.control.data = { variables: [] };
                        }

                        let control = item.control.data.variables.find(
                          (v) => v.template === selectedTemplate.name
                        );

                        item.control.data.variables =
                          item.control.data.variables.filter(
                            (v) => v.template !== selectedTemplate.name
                          );

                        item.control.data.variables.push({
                          ...control,
                          template: selectedTemplate.name,
                          field: ev.currentTarget.value,
                        });

                        setUpdate(Date.now());
                      }}
                      value={
                        node?.control?.data?.variables?.find(
                          (v) => v.template === selectedTemplate.name
                        )?.field || ""
                      }
                    >
                      <option value="">---</option>
                      {selectedTemplate?.variables?.map((variable, index) => {
                        return (
                          <option value={variable.name}>{variable.name}</option>
                        );
                      })}
                    </Select>
                  )}
                </Column>
                <Column>
                  <Input
                    value={
                      node?.control?.data?.variables?.find(
                        (v) => v.template === selectedTemplate.name
                      )?.default
                    }
                    onChange={(e) => {
                      let item = scene.getItemByUUID(
                        node.uuid,
                        scene.scene.children
                      );

                      if (!item.control?.data?.variables) {
                        item.control.data = { variables: [] };
                      }
                      let index = item.control.data.variables.findIndex(
                        (v) => v.template === selectedTemplate.name
                      );
                      if (index === -1) {
                        item.control.data.variables.push({
                          template: selectedTemplate.name,
                          default: e.currentTarget.value,
                        });
                      } else {
                        item.control.data.variables[index].default =
                          e.currentTarget.value;
                      }

                      setUpdate(Date.now());
                    }}
                  />
                </Column>
                <Column>
                  <Input
                    type="checkbox"
                    checked={
                      node?.control?.data?.variables?.find(
                        (v) => v.template === selectedTemplate.name
                      )?.hide
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      let item = scene.getItemByUUID(
                        node.uuid,
                        scene.scene.children
                      );

                      if (!item.control?.data?.variables) {
                        item.control.data = { variables: [] };
                      }
                      let index = item.control.data.variables.findIndex(
                        (v) => v.template === selectedTemplate.name
                      );
                      if (index === -1) {
                        item.control.data.variables.push({
                          template: selectedTemplate.name,
                          hide: e.currentTarget.checked,
                        });
                      } else {
                        item.control.data.variables[index].hide =
                          e.currentTarget.checked;
                      }

                      setUpdate(Date.now());
                    }}
                  />
                </Column>
              </Row>
            );
          })}
        </VariablesTable>
      )}
    </>
  );
}
