import React from "react";
import styled from "styled-components";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
  font-size: 1.5em;
`;
const Row = styled.div`
  display: flex;
  margin-top: 1em;
  align-self: flex-end;
`;

const Button = styled.div`
  background-color: ${(props) => (props.green ? "#05ff97" : "#353a47")};
  color: ${(props) => (props.green ? "black" : "")};
  border-radius: 4px;
  padding: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-left: 0.5em;
  :hover {
    color: #fff;
    background-color: ${(props) => (props.green ? "#05ff97" : "#db0a41")};
  }
`;
export default function Confirm({ message, onCancel, onConfirm }) {
  return (
    <Main>
      {message}
      <Row>
        <Button onClick={() => onCancel()}>Cancel</Button>
        <Button green onClick={() => onConfirm()}>
          Yes
        </Button>
      </Row>
    </Main>
  );
}
