import React, { Component } from "react";
import styles from "./Timeline.module.css";
import Scrubber from "./Srubber";
import TriangleDown from "../../SVG/TriangleDown";
import TriangleRight from "../../SVG/TriangleRight";
import Forward from "../../SVG/Forward";
import Play from "../../SVG/Play";
import Reverse from "../../SVG/Reverse";
import Repeat from "../../SVG/Repeat";
import cloneDeep from "lodash/cloneDeep";

export default class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animations: {},
      showContext: false,
      showAnimationContext: false,
      expandedItem: null,
      showHover: false,
    };
    this.i = 0;
  }
  componentDidMount() {
    // this.controlsProgressEl = document.getElementById("progress");
    // this.controlsProgressEl.addEventListener("input", this.seek.bind(this));
  }

  // seek() {
  //   console.log(
  //     Object.values(this.state.animations)[0].animation.duration *
  //       (this.controlsProgressEl.value / 100)
  //   );
  //   Object.values(this.state.animations)[0].animation.seek(
  //     parseInt(
  //       Object.values(this.state.animations)[0].animation.duration *
  //         (this.controlsProgressEl.value / 100)
  //     )
  //   );
  // }

  drag(e, animation, keyframe) {
    this.draggingSource = animation;
    e.dataTransfer.setData("keyframe", keyframe);
  }

  drop(e, item, frame) {
    if (e.dataTransfer.getData("keyframe")) {
      if (e.ctrlKey) {
        let diff = frame - parseInt(e.dataTransfer.getData("keyframe"));
        let keys =
          this.props.app.player.selectedScene.activeTimeline.getKeyframesByItem(
            item
          );
        keys.forEach((kf) => {
          kf.frame = kf.frame + diff;
        });
      } else {
        this.props.app.player.selectedScene.activeTimeline.moveKeyframe(
          frame,
          parseInt(e.dataTransfer.getData("keyframe")),
          this.draggingSource
        );
      }
      this.draggingSource = null;
      this.forceUpdate();
    } else {
      var property = e.dataTransfer.getData("property");

      var value = e.dataTransfer.getData("value");
      e.currentTarget.classList.remove(styles.DropOver);
      e.currentTarget.classList.add(styles.Active);

      this.props.app.player.selectedScene.activeTimeline.addKeyframe({
        frame,
        property,
        value,
        item,
      });
    }
  }

  allowDrop(e) {
    e.preventDefault();
  }

  enterDrop(e) {
    e.currentTarget.classList.add(styles.DropOver);
  }

  exitDrop(e) {
    e.currentTarget.classList.remove(styles.DropOver);
  }

  play() {
    // this.props.app.player.selectedScene.preload();
    if (this.props.app.player.selectedScene.activeTimeline?.reverse_in) {
      this.props.app.player.selectedScene.timelines
        ?.find((tl) => tl.name === "IN")
        ?.reverse();
    } else {
      this.props.app.player.selectedScene.activeTimeline.togglePlay();
    }
  }

  reverse() {
    this.props.app.player.selectedScene.activeTimeline.reverse();
  }

  createTimeline() {
    this.props.app.player.selectedScene.createTimeline();
    this.forceUpdate();
  }

  handleMouseClick(e) {
    if (!this.mouseMove) {
      var rect = document
        .getElementById("AnimationHeader")
        .getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      var y = e.clientY - rect.top; //y position within the element.
      let frames = Math.floor(x / 13);

      if (frames >= 0) {
        this.props.app.player.selectedScene.scene.visible = true;
        this.props.app.player.selectedScene.scene.renderable = true;
        this.props.app.player.selectedScene.activeTimeline.seek(frames);
      }
    }
    this.mouseMove = false;
    this.mouseDown = false;
  }

  handleMouseDown(e) {
    this.mouseDown = true;
  }

  handleMouseMove(e) {
    if (this.mouseDown) {
      this.mouseMove = true;
    }
  }

  handleContext(e, keyframe, animation) {
    e.preventDefault();

    this.setState({
      showContext: true,
      showAnimationContext: false,
      showHover: false,
      mouseX: e.clientX,
      mouseY: e.clientY,
      keyframe: keyframe,
      animation: animation,
    });
    window.addEventListener("click", this.handleClick.bind(this));
  }

  handleClick(e) {
    this.setState({
      showContext: false,
      showAnimationContext: false,
      keyframe: null,
      animation: null,
    });
    window.removeEventListener("click", this.handleClick.bind(this));
  }

  handleContextDelete() {
    this.props.app.player.selectedScene.activeTimeline.removeKeyframe(
      this.state.animation,
      this.state.keyframe
    );
    this.setState({
      keyframe: null,
      showContext: false,
      animation: null,
    });
  }

  handleScroll(e) {
    document.getElementById("timelines").scrollTop = e.currentTarget.scrollTop;
  }

  handleAnimationContext(e, item) {
    e.preventDefault();
    this.props.selectItem(item);
    this.setState({
      showContext: false,
      showAnimationContext: true,
      showHover: false,
      mouseX: e.clientX,
      mouseY: e.clientY,
      selectedItem: item,
    });
    window.addEventListener("click", this.handleClick.bind(this));
  }

  handleContextCopyAnimation() {
    this.setState({
      animation: null,
      showContext: false,
      copyAnimation:
        this.props.app.player.selectedScene.activeTimeline.getAnimationsByItem(
          this.state.selectedItem
        ),
    });
  }

  handleContextPasteAnimation() {
    let track = this.props.app.player.selectedScene.activeTimeline.addTrack(
      this.state.selectedItem
    );

    track.animations = cloneDeep(this.state.copyAnimation);

    this.setState({
      animation: null,
      showContext: false,
    });
  }

  handleContextOffsetAnimation() {
    let tracks =
      this.props.app.player.selectedScene.activeTimeline.tracks.filter(
        (o) => o.object?.uuid === this.state.selectedItem.uuid
      );

    tracks.forEach((tl) => {
      tl.animations.forEach((anim) => {
        anim.keyframes.forEach((kf) => {
          kf.frame = kf.frame + 5;
        });
      });
    });
  }
  renderTrack(item, level) {
    return (
      <div
        key={(item.uuid || item.name) + "-" + level}
        onContextMenu={(e) => {
          this.handleAnimationContext(e, item);
        }}
      >
        <div>
          <div
            className={`${styles.Track} ${styles.TrackName}  ${
              item === this.props.selectedItem ? styles.Active : ""
            }`}
            onClick={() => {
              if (item.name !== "Events") {
                this.props.selectItem(item);
              }
            }}
          >
            {this.state.expandedItem === item && item.name !== "Events" && (
              <div
                className={styles.Expander}
                onClick={() =>
                  this.setState({
                    expandedItem: null,
                  })
                }
              >
                <TriangleDown />
              </div>
            )}
            {this.state.expandedItem !== item && item.name !== "Events" && (
              <div
                className={styles.Expander}
                onClick={() =>
                  this.setState({
                    expandedItem: item,
                  })
                }
              >
                <TriangleRight />
              </div>
            )}
            {item.name}
          </div>
          {this.state.expandedItem === item &&
            this.props.app.player.selectedScene.activeTimeline
              .getAnimationsByItem(item)
              .map((animation, index) => {
                return (
                  <div
                    className={`${styles.Track} ${styles.Property} ${
                      item === this.props.selectedItem ? styles.Active : ""
                    }`}
                    key={index}
                  >
                    {animation.property}
                  </div>
                );
              })}
        </div>
        {/* {item.children.map(i => {
          level++;
          return this.renderTrack(i, level);
        })} */}
      </div>
    );
  }

  handleKfHover(e, keyframe) {
    if (keyframe) {
      this.setState({
        hoverItem: keyframe,
        showHover: false,
        mouseX: e.clientX + 15,
        mouseY: e.clientY - 10,
      });
    } else {
      this.setState({ showHover: false });
    }
  }

  renderKeyframes(item) {
    let keyframes =
      this.props.app.player.selectedScene.activeTimeline.getKeyframesByItem(
        item
      );
    return (
      <div>
        <div>
          <div
            className={`${styles.Animation} ${
              item === this.props.selectedItem ? styles.Active : ""
            }`}
          >
            {new Array(
              parseInt(
                this.props.app?.player?.selectedScene?.activeTimeline
                  ?.duration || 0
              ) + 25
            )
              .fill(1)
              .map((keyframe, index) => {
                let kf = keyframes.find((kf) => kf.frame === index + 1);

                return (
                  <div className={styles.KeyFrameContainer} key={index + 1}>
                    <div
                      className={`${styles.KeyFrame} ${
                        kf ? styles.Active : ""
                      }`}
                      onDrop={(event) => this.drop(event, item, index + 1)}
                      onDragOver={(event) => this.allowDrop(event)}
                      onDragEnter={(event) => this.enterDrop(event)}
                      onDragLeave={(event) => this.exitDrop(event)}
                      onContextMenu={(e) => {
                        this.handleContext(e, index + 1);
                      }}
                    >
                      {kf?.inherit === true ? "*" : ""}
                    </div>
                  </div>
                );
              })}
          </div>

          {this.state.expandedItem === item &&
            this.props.app.player.selectedScene.activeTimeline
              .getAnimationsByItem(item)
              .map((animation) => {
                return (
                  <div
                    key={item.uuid}
                    className={`${styles.Animation} ${
                      item === this.props.selectedItem ? styles.Active : ""
                    }`}
                  >
                    {animation.keyframes.map((kf, index) => {
                      if (index === 0 || kf.type === "none") {
                        return;
                      }
                      let prevKf = animation.keyframes[index - 1];
                      let width = (kf.frame - (prevKf ? prevKf.frame : 0)) * 13;
                      return (
                        <div
                          style={{
                            position: "Absolute",
                            width: width + "px",
                            height: "4px",
                            top: "13px",
                            left: prevKf.frame * 13 - 11 + "px",
                            backgroundColor: "#8d1237",
                            pointerEvents: "none",
                            zIndex: 0,
                          }}
                          key={index}
                        ></div>
                      );
                    })}

                    {new Array(
                      parseInt(
                        this.props.app?.player?.selectedScene?.activeTimeline
                          ?.duration || 0
                      ) + 25
                    )
                      .fill(1)
                      .map((keyframe, index) => {
                        let kf = animation.keyframes.find(
                          (kf) => kf.frame === index + 1
                        );
                        return (
                          <div
                            className={`${styles.KeyFrameContainer} ${
                              kf ? styles.Selected : ""
                            }`}
                            key={index + 1}
                          >
                            <div
                              className={`${styles.KeyFrame} ${
                                kf
                                  ? this.props.app.state.selectedKeyframe === kf
                                    ? styles.Selected
                                    : styles.Active
                                  : ""
                              }`}
                              onClick={() =>
                                this.props.app.setState({
                                  ...this.props.app.state,
                                  selectedKeyframe: kf,
                                })
                              }
                              onDrop={(event) =>
                                this.drop(event, item, index + 1)
                              }
                              draggable={true}
                              onDragStart={(event) =>
                                this.drag(event, animation, index + 1)
                              }
                              onDragOver={(event) => this.allowDrop(event)}
                              onDragEnter={(event) => this.enterDrop(event)}
                              onDragLeave={(event) => this.exitDrop(event)}
                              onMouseOver={(event) =>
                                this.handleKfHover(
                                  event,
                                  keyframes.find((kf) => kf.frame === index + 1)
                                )
                              }
                              onContextMenu={(e) => {
                                this.handleContext(e, index + 1, animation);
                              }}
                            >
                              {kf?.inherit === true ? "*" : ""}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                );
              })}
        </div>
        {/* {item.children.map(i => {
          return this.renderKeyframes(i);
        })} */}
      </div>
    );
  }

  render() {
    if (!this.props.scene) {
      return <div />;
    }
    return (
      <div className={styles.Timeline}>
        <div className={styles.VideoControls}>
          {/* <div className={`${styles.Repeat} ${styles.Off}`}>
            <Repeat />
          </div>*/}
          <div className={styles.Reverse} onClick={() => this.reverse()}>
            <Reverse />
          </div>
          <div className={styles.Play} onClick={() => this.play()}>
            <Play />
          </div>
          {/* <div className={styles.Forward}>
            <Forward />
          </div> */}
          {/* <div
            onClick={() => {
              this.props.app.player.autoKeyframe = !this.props.app.player
                .autoKeyframe;
            }}
          >
            KF
          </div> */}
          {/* <div className="Rate Off">x0.5</div>
          <div className="Rate On">x1</div>
          <div className="Rate Off">x2</div> */}
        </div>
        <div className={styles.Tabs}>
          {!this.props.app.player.selectedScene.archived &&
            this.props.app.player.selectedScene.timelines.map(
              (timeline, index) => {
                return (
                  <div
                    key={index}
                    className={`${styles.Tab} ${
                      timeline ===
                        this.props.app.player.selectedScene.activeTimeline &&
                      !this.props.showErrors
                        ? styles.Active
                        : ""
                    }`}
                    onClick={() => this.props.selectTimeline(timeline)}
                    onContextMenu={(event) => {
                      event.preventDefault();
                      return false;
                    }}
                    onMouseDown={(e) => {
                      if (e.button == 2) {
                        this.props.selectTimeline(timeline);
                        this.setState({
                          ...this.state,
                          renameTimeline: timeline.id,
                        });
                      }
                    }}
                  >
                    {(!this.state.renameTimeline ||
                      this.state.renameTimeline !== timeline.id) &&
                      timeline.name}
                    {this.state.renameTimeline &&
                      this.state.renameTimeline === timeline.id && (
                        <input
                          value={timeline.name}
                          onKeyDown={(ev) => {
                            if (ev.keyCode === 13) {
                              this.setState({
                                ...this.state,
                                renameTimeline: null,
                              });
                            }
                          }}
                          onChange={(ev) => {
                            timeline.name = ev.currentTarget.value;
                            this.forceUpdate();
                          }}
                        />
                      )}
                  </div>
                );
              }
            )}

          {!this.props.app.player.selectedScene.archived && (
            <div className={styles.Tab} onClick={() => this.createTimeline()}>
              +
            </div>
          )}
          {this.props.errors && this.props.errors.length > 0 && (
            <div
              className={`${styles.Tab} ${
                this.props.showErrors ? styles.Active : ""
              }`}
              style={{ marginLeft: "auto", backgroundColor: "#eb3d47" }}
              onClick={() => {
                this.props.onShowErrors();
              }}
            >
              Errors
            </div>
          )}
        </div>
        {!this.props.showErrors &&
          !this.props.app.player.selectedScene.archived && (
            <div className={styles.Animations}>
              <div className={styles.Tracks}>
                <div className={styles.Track}>
                  <input id="Frame" value="Frame 0" onChange={() => {}} />
                  Duration
                  <input
                    value={parseInt(
                      this.props.app?.player?.selectedScene?.activeTimeline
                        ?.duration || 0
                    )}
                    onChange={(e) => {
                      if (isNaN(parseInt(e.currentTarget.value)) === false) {
                        this.props.app.player.selectedScene.activeTimeline.duration =
                          parseInt(e.currentTarget.value);
                      }
                      this.forceUpdate();
                    }}
                  />
                </div>
                <div
                  className={styles.TrackContainer}
                  onScroll={(e) => this.handleScroll(e)}
                >
                  {this.props.app.player.selectedScene.activeTimeline.tracks.map(
                    (item) => {
                      return this.renderTrack(item.object, 0);
                    }
                  )}
                </div>
              </div>
              <div
                className={styles.Timelines}
                onWheel={(e) => {
                  e.currentTarget.scrollLeft += e.deltaY;
                }}
                id="timelines"
              >
                <div
                  id="AnimationHeader"
                  className={styles.AnimationHeader}
                  onClick={(e) => this.handleMouseClick(e)}
                  onMouseMove={(e) => this.handleMouseMove(e)}
                  onMouseDown={(e) => this.handleMouseDown(e)}
                >
                  <Scrubber app={this.props.app} />
                  {new Array(
                    parseInt(
                      this.props.app?.player?.selectedScene?.activeTimeline
                        ?.duration || 0
                    ) + 25
                  )
                    .fill(1)
                    .map((keyframe, index) => {
                      return (
                        <div
                          className={`${styles.KeyFrameContainer} ${
                            parseInt(
                              this.props.app?.player?.selectedScene
                                ?.activeTimeline?.duration || 0
                            ) === index
                              ? styles.DurationMarker
                              : ""
                          }`}
                          key={index + 1}
                        >
                          <div
                            className={`${styles.Time} ${
                              (index + 1) % 25 === 0 ? styles.Second : ""
                            }
                      `}
                            onClick={(e) => this.handleMouseClick(e)}
                          >
                            {index + 1}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div>
                  {this.props.app.player.selectedScene.activeTimeline.tracks
                    .filter((item) => item.object.type !== "HELPER")
                    .map((item) => {
                      return this.renderKeyframes(item.object);
                    })}
                </div>
              </div>
            </div>
          )}
        {this.state.showContext && (
          <div
            className={styles.Context}
            style={{
              left: this.state.mouseX + "px",
              top: this.state.mouseY + "px",
            }}
          >
            <div
              className={styles.ContextItem}
              onClick={() => this.handleContextDelete()}
            >
              Delete
            </div>
          </div>
        )}
        {this.state.showAnimationContext && (
          <div
            className={styles.Context}
            style={{
              left: this.state.mouseX + "px",
              top: this.state.mouseY + "px",
            }}
          >
            <div
              className={styles.ContextItem}
              onClick={() => this.handleContextCopyAnimation()}
            >
              Copy Animation
            </div>
            <div
              className={styles.ContextItem}
              onClick={() => this.handleContextPasteAnimation()}
            >
              Paste Animation
            </div>
            <div
              className={styles.ContextItem}
              onClick={() => this.handleContextOffsetAnimation()}
            >
              Offset Animation
            </div>
          </div>
        )}
        {this.state.showHover && (
          <div
            className={styles.HoverValue}
            style={{
              left: this.state.mouseX + "px",
              top: this.state.mouseY + "px",
            }}
          >
            <div className={styles.HoverItem}>{this.state.hoverItem.value}</div>
          </div>
        )}
      </div>
    );
  }
}
