import React, { Component } from "react";
import propertiesStyle from "./Properties.module.css";
import styles from "./Layout.module.css";
import TriangleDown from "../../SVG/TriangleDown";
import TriangleRight from "../../SVG/TriangleRight";
import { setProperty as SET_PROPERTY } from "../../Scripts/PropertyUtils";
import CodeEditor from "../CodeEditor/CodeEditor";

export default class Code extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      code: "Add Code Here",
    };
  }

  updateProperty(property, value) {
    SET_PROPERTY(this.props.item, property, value);
    this.setState({ update: true });
  }

  handleMouseDown(e, property, startValue) {
    this.property = property;
    this.mouseDown = true;
    var rect = document.querySelector("body").getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    this.startX = x;

    this.startValue = startValue;
    document.addEventListener("mousemove", this.handleMouseMove.bind(this));
    document.addEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  handleMouseMove(e) {
    if (this.mouseDown) {
      var rect = document.querySelector("body").getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      x = x - this.startX;
      let y = this.props.item.children[0].y;
    }
  }

  handleMouseUp(e) {
    this.mouseDown = false;
    document.removeEventListener("mousemove", this.handleMouseMove.bind(this));
    document.removeEventListener("mouseup", this.handleMouseUp.bind(this));
  }

  executeCode(code) {
    try {
      var theInstructions = code;

      var F = new Function("item", theInstructions);

      F(this.props.item);
    } catch (e) {}
  }

  render() {
    return (
      <div className={propertiesStyle.Group}>
        <div
          className={propertiesStyle.GroupTitle}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <div className={propertiesStyle.Open}>
            {this.state.open && <TriangleDown />}
            {!this.state.open && <TriangleRight />}
          </div>
          <div className={propertiesStyle.Title}>Code</div>
        </div>
        {this.state.open && (
          <div className={styles.Group}>
            <div className={styles.Property}>
              <CodeEditor item={this.props.item} />
              {/* <textarea
                value={this.state.code}
                onChange={e => this.setState({ code: e.currentTarget.value })}
              /> */}
              <div
                className={styles.Button}
                onClick={() => this.executeCode(this.state.code)}
              >
                Execute
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
