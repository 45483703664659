import React, { useContext, useEffect } from "react";
import { UserContext, UserProvider } from "./Contexts/UserContext";
import Login from "./Login/Login";
import App from "./App";
import { MsalProvider } from "@azure/msal-react";
import { ThemeProvider } from "styled-components";
import Hyper from "./Theme/Hyper";

function Main() {
  const { loggedIn } = useContext(UserContext);

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  var pathname = window.location.pathname
    ?.replace(process.env.PUBLIC_URL, "")
    ?.split("/");

  let isEngine =
    getParameterByName("engine") === "true" ||
    pathname[1]?.toLowerCase() === "preview" ||
    pathname[1]?.toLowerCase() === "live"
      ? true
      : false;

  return (
    <>
      <ThemeProvider theme={Hyper}>
        {!isEngine && !loggedIn && <Login />}
        {(isEngine || loggedIn) && <App />}
      </ThemeProvider>
    </>
  );
}

export default function Startup({ msalInstance }) {
  return (
    <MSALChecker msalInstance={msalInstance}>
      <UserProvider>
        <Main />
      </UserProvider>
    </MSALChecker>
  );
}

function MSALChecker({ msalInstance, children }) {
  if (msalInstance) {
    return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
  } else {
    return <>{children}</>;
  }
}
