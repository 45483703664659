import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import "./loadThree";
import * as serviceWorker from "./serviceWorker";
import Startup from "./Startup";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";

import { setChonkyDefaults } from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import { PublicClientApplication, EventType } from "@azure/msal-browser";

// Somewhere in your `index.ts`:
setChonkyDefaults({ iconComponent: ChonkyIconFA });
// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     graphQLErrors.forEach(({ message, locations, path, extensions }) => {
//       if (extensions.code === "UNAUTHENTICATED" && path[0] !== "me") {
//         window.location.reload();
//       }
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//       );
//     });

//   if (networkError) console.log(`[Network error]: ${networkError}`);
// });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri:
    (window.ENV?.REACT_APP_PROJECT_SERVER ||
      process.env.REACT_APP_PROJECT_SERVER) + "/graphql",
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authLink, httpLink]),
});

const msalConfig = {
  auth: {
    clientId:
      window.ENV?.REACT_APP_MICROSOFT_CLIENT_ID ||
      process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    authority:
      window.ENV?.REACT_APP_MICROSOFT_AUTHORITY ||
      process.env.REACT_APP_MICROSOFT_AUTHORITY,
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPII) => {
        console.log(message);
      },
      logLevel: "verbose",
    },
  },
};
let pca = null;
if (
  window.ENV?.REACT_APP_MICROSOFT_CLIENT_ID ||
  process.env.REACT_APP_MICROSOFT_CLIENT_ID
) {
  pca = new PublicClientApplication(msalConfig);

  pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      pca.setActiveAccount(event.payload.account);
    }
  });
  // Handle the redirect flows
  pca
    .handleRedirectPromise()
    .then((tokenResponse) => {
      // Handle redirect response
    })
    .catch((error) => {
      // Handle redirect error
    });
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <Startup msalInstance={pca} />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
