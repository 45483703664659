import React, { Component } from "react";
import styles from "./SequenceViewer.module.css";

export default class SequenceViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videos: [],
      index: 0,
      selectedVideo: null,
    };
  }

  componentDidMount() {
    this.getSequences();
  }

  mouseOver(e, video) {
    this.setState({
      index: 0,
      selectedVideo: video,
    });
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      if (this.state.selectedVideo.images.length > this.state.index + 1) {
        this.setState({
          index: this.state.index + 1,
        });
      } else {
        this.setState({
          index: 0,
        });
      }
    }, 40);
  }

  mouseLeave() {
    clearInterval(this.interval);
    this.setState({
      index: 0,
      selectedVideo: null,
    });
  }

  getSequences() {
    fetch(
      `${window.projectServerURL}assets/sequence/${this.props.project.name}`
    )
      .then((response) => response.json())
      .then((data) => {
        let videos = data.map((item) => {
          return {
            name: item,
            images: [],
          };
        });
        this.setState({
          videos: videos,
        });
        this.getImages(videos);
      });
  }

  getImages(videos) {
    for (let i = 0; i < videos.length; i++) {
      fetch(
        `${window.projectServerURL}assets/sequence/${this.props.project.name}/${videos[i].name}`
      )
        .then((response) => response.json())
        .then((data) => {
          let obj = Object.assign({}, this.state);

          obj.videos.find((item) => item.name === videos[i].name).images = data;

          this.setState({
            videos: obj.videos,
          });
        });
    }
  }

  upload(e) {
    const files = Array.from(e.target.files);
    this.setState({ uploading: true });

    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append("uploaded_file", file, file.name);
    });

    fetch(`${window.projectServerURL}video-upload/${this.props.project.name}`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.getSequences();
      });
  }

  render() {
    return (
      <div className={styles.ImageViewer}>
        <div
          className={styles.AddContainer}
          onClick={() => document.getElementById("file-input").click()}
        >
          +
        </div>
        <input
          id="file-input"
          type="file"
          name="video"
          style={{ display: "none" }}
          onChange={(e) => {
            this.upload(e);
          }}
        />
        <div className={styles.Images}>
          {this.state.videos.map((video, index) => {
            return (
              <div key={index} className={styles.ImageContainer}>
                <div
                  className={styles.Image}
                  onMouseOver={(e) => {
                    this.mouseOver(e, video);
                  }}
                  onMouseLeave={(e) => {
                    this.mouseLeave();
                  }}
                  onClick={() => this.props.callback(video)}
                >
                  {video.images.length > 0 && (
                    <img
                      src={`${window.projectServerURL}assets/sequence/${
                        this.props.project.name
                      }/${video.name}/${
                        video.images[
                          this.state.selectedVideo === video
                            ? this.state.index
                            : 0
                        ]
                      }`}
                      width="100%"
                      height="auto"
                    />
                  )}
                </div>
                <div className={styles.Name}>{video.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
