import React from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
`;
const Title = styled.div`
  display: flex;
  flex-direction: column;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  padding: 5px;
  background-color: ${(props) => (props.index % 2 ? "#232935" : "")};
`;

const Scene = styled.div`
  min-width: 300px;
  max-width: 300px;
  margin-right: 10px;
  overflow: hidden;
`;

const Error = styled.div``;
function Errors({ errors }) {
  return (
    <Main>
      <Table>
        {errors
          ?.sort((a, b) => {
            return a.scene - b.scene;
          })
          ?.map((err, index) => {
            return (
              <Row index={index}>
                <Scene>{err.scene}</Scene>
                <Scene>{err.node}</Scene>
                <Error>{err.error}</Error>
              </Row>
            );
          })}
      </Table>
    </Main>
  );
}

export default Errors;
